import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import DateSelection from "./DateSelection";
import { motion, AnimatePresence } from "framer-motion";
import MapRoundedIcon from "@mui/icons-material/MapRounded";
import GroupIcon from "@mui/icons-material/Group";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import {
  useGetAllCalendarNotes,
  useFetchTourGroupIdsBySpecificDate,
  useGetUserOfficeDutyStatus,
  useGetAllOfficeDutyUsers,
} from "../../../../reactQueryHooks";
import { Tooltip } from "@mui/material";
import { useAxios } from "../../../../axiosProvider";
import io from "socket.io-client";
const socketBookings = io("https://getaways-bookings.herokuapp.com", {
  transports: ["websocket"],
});

//--------------------------------------------------------------

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: whitesmoke;
  flex: 1;
  z-index: 9999;
`;

const HeaderBarButtonContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  .fa-arrows-rotate {
    font-size: 17px;
    //spin it half a turn on hover
    transform: rotate(0deg);
    transition: transform 0.13s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: rotate(150deg);
    }
  }
  .header-button {
    color: #464646;
    font-size: 17px;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const closeIconAndDateVariants = {
  visible_icon: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
  hidden_icon: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.0,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.0,
    },
  },
};

const RoutingContainer = styled.div`
  margin-left: 10px;
  @media (max-width: 700px) {
    display: none;
  }
`;
const NonHideableButtonContainer = styled.div`
  cursor: pointer;
  &:hover {
    filter: brightness(0.6);
  }
`;

const HideableButtonContainer = styled.div`
  //less than 1200px display none
  @media (max-width: 900px) {
    display: none;
  }
  cursor: pointer;
  &:hover {
    filter: brightness(0.6);
  }
  position: relative;
`;

const NotesNumberIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: indianred;
  color: white;
  border-radius: 50%;
  padding: 2px;
  width: 13px;
  height: 13px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  user-select: none;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.5);
`;

const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const OnDutyBlinkingIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ isOnDuty }) => (isOnDuty ? "#3b943b" : "#B95151")};
  color: white;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  user-select: none;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.5);

  ${({ isOnDuty }) =>
    isOnDuty &&
    css`
      animation: ${blink} 1.2s linear infinite;
    `}
`;

//--------------------------------------------------------------

const DayPlannerTourGroupsBarContent = ({
  isModalOpen,
  date,
  setDate,
  handleDateChange,
  isNotesShown,
  setIsNotesShown,
  isFleetMapShown,
  setIsFleetMapShown,
  isAvailabilitiesShown,
  setIsAvailabilitiesShown,
  isScheduleShown,
  setIsScheduleShown,
  isBookingsMinimized,
  setIsBookingsMinimized,
  showTourGroupsWithTimeline,
  setShowTourGroupsWithTimeline,
}) => {
  const navigate = useNavigate();
  const { user } = useAxios();
  const { data: notes } = useGetAllCalendarNotes({
    date: date,
    user_id: user._id,
  });

  const { data: onOfficeDuty, refetch: refetchOfficeDutyStatus } =
    useGetUserOfficeDutyStatus(user?._id);

  const { data: officeDutyUsers, refetch: refetchOfficeDutyUsers } =
    useGetAllOfficeDutyUsers();

  const { refetch: refetchTourGroups } =
    useFetchTourGroupIdsBySpecificDate(date);

  useEffect(() => {
    if (notes?.data > 0) {
      setIsNotesShown(true);
    } else {
      setIsNotesShown(false);
    }
  }, [notes?.data, setIsNotesShown]);

  useEffect(() => {
    // subscribe socket listener on mount
    socketBookings.on(`getaways_suite/refresh_users_on_duty`, async (data) => {
      await refetchOfficeDutyStatus();
      await refetchOfficeDutyUsers();
      //toast.success("Someone is on duty now! 🎉");
    });

    // unsubscribe socket on unmount (cleanup)
    return () => {
      socketBookings.off(`getaways_suite/refresh_users_on_duty`);
    };
  }, [refetchOfficeDutyUsers, refetchOfficeDutyStatus]);

  //--------------------------------------------------------------

  return (
    <Container isModalOpen={isModalOpen}>
      <AnimatePresence>
        {!isModalOpen && (
          <>
            <HeaderBarButtonContainer
              variants={closeIconAndDateVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              onClick={() => navigate("/home")}
            >
              <i className="fa-solid fa-home header-button"></i>
              <RoutingContainer>
                <i
                  style={{
                    fontSize: "8px",
                  }}
                  className="fa-solid fa-angle-right"
                ></i>
                <span
                  style={{
                    fontSize: "10px",
                    marginLeft: "5px",
                  }}
                >
                  Day Planner
                </span>
              </RoutingContainer>
            </HeaderBarButtonContainer>
          </>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isModalOpen && (
          <motion.div
            variants={closeIconAndDateVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <DateSelection
              date={date}
              setDate={setDate}
              handleDateChange={handleDateChange}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {!isModalOpen && (
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <NonHideableButtonContainer>
              <Tooltip
                title={isBookingsMinimized ? "Show bookings" : "Hide bookings"}
                placement="top"
              >
                {isBookingsMinimized ? (
                  <OpenInFullIcon
                    onClick={() => {
                      setIsBookingsMinimized(false);
                    }}
                    color="primary"
                    sx={{ fontSize: 20 }}
                  />
                ) : (
                  <CloseFullscreenIcon
                    onClick={() => {
                      setIsBookingsMinimized(true);
                    }}
                    color="primary"
                    sx={{ fontSize: 20 }}
                  />
                )}
              </Tooltip>
            </NonHideableButtonContainer>

            <NonHideableButtonContainer>
              <Tooltip
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      padding: "4px 5px",
                    }}
                  >
                    <span
                      style={{
                        //backgroundColor: "white",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {onOfficeDuty?.data
                        ? "You are on Duty ✅"
                        : "You are off Duty ⛔"}
                    </span>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        //backgroundColor: "white",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        gap: "5px",
                        padding: "5px 7px",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10px",
                        }}
                      >
                        USERS ON DUTY
                      </span>
                      {officeDutyUsers?.data?.length > 0 &&
                        officeDutyUsers.data.map((user) => (
                          <span
                            key={user._id}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            {user.name}
                          </span>
                        ))}
                      {!officeDutyUsers?.data?.length && (
                        <span style={{ color: "rgb(235 154 154)" }}>
                          No users on duty
                        </span>
                      )}
                    </span>
                  </div>
                }
              >
                <div
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <SupportAgentIcon color="primary" sx={{ fontSize: 24 }} />
                  <OnDutyBlinkingIcon isOnDuty={onOfficeDuty?.data} />
                </div>
              </Tooltip>
            </NonHideableButtonContainer>

            <HideableButtonContainer>
              <Tooltip
                title={
                  showTourGroupsWithTimeline ? "Hide timeline" : "Show timeline"
                }
                placement="top"
              >
                {showTourGroupsWithTimeline ? (
                  <CloseIcon
                    onClick={() => {
                      setShowTourGroupsWithTimeline(false);
                      localStorage.setItem(
                        "show_tour_groups_with_timeline",
                        "false"
                      );
                    }}
                    color="warning"
                  />
                ) : (
                  <ViewTimelineIcon
                    onClick={() => {
                      setShowTourGroupsWithTimeline(true);
                      localStorage.setItem(
                        "show_tour_groups_with_timeline",
                        "true"
                      );
                    }}
                    color="primary"
                    sx={{ fontSize: 24 }}
                  />
                )}
              </Tooltip>
            </HideableButtonContainer>

            <HideableButtonContainer>
              <Tooltip
                title={
                  isNotesShown ? "Hide calendar notes" : "Show calendar notes"
                }
                placement="top"
              >
                {isNotesShown ? (
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <CloseIcon
                      onClick={() => {
                        setIsNotesShown(false);
                      }}
                      color="warning"
                    />
                  </span>
                ) : (
                  <div
                    onClick={() => {
                      setIsNotesShown(true);
                      setIsAvailabilitiesShown(false);
                      setIsFleetMapShown(false);
                      setIsScheduleShown(false);
                    }}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <StickyNote2Icon color="primary" sx={{ fontSize: 24 }} />
                    {notes?.data > 0 && !isNotesShown ? (
                      <NotesNumberIndicator>{notes?.data}</NotesNumberIndicator>
                    ) : null}
                  </div>
                )}
              </Tooltip>
            </HideableButtonContainer>

            <HideableButtonContainer>
              <Tooltip
                title={
                  isAvailabilitiesShown
                    ? "Hide availabilities"
                    : "Show availabilities"
                }
                placement="top"
              >
                {isAvailabilitiesShown ? (
                  <CloseIcon
                    onClick={() => {
                      setIsAvailabilitiesShown(false);
                    }}
                    color="warning"
                  />
                ) : (
                  <CalendarMonthIcon
                    onClick={() => {
                      setIsAvailabilitiesShown(true);
                      setIsScheduleShown(false);
                      setIsFleetMapShown(false);
                      setIsNotesShown(false);
                    }}
                    color="primary"
                    sx={{ fontSize: 24 }}
                  />
                )}
              </Tooltip>
            </HideableButtonContainer>

            <HideableButtonContainer>
              <Tooltip
                title={
                  isScheduleShown ? "Hide schedule" : "Show staff schedule"
                }
                placement="top"
              >
                {isScheduleShown ? (
                  <CloseIcon
                    onClick={() => {
                      setIsScheduleShown(false);
                    }}
                    color="warning"
                  />
                ) : (
                  <GroupIcon
                    onClick={() => {
                      setIsScheduleShown(true);
                      setIsAvailabilitiesShown(false);
                      setIsFleetMapShown(false);
                      setIsNotesShown(false);
                    }}
                    color="primary"
                    sx={{ fontSize: 28 }}
                  />
                )}
              </Tooltip>
            </HideableButtonContainer>
            <HideableButtonContainer>
              <Tooltip
                title={isFleetMapShown ? "Hide map" : "Show map"}
                placement="top"
              >
                {isFleetMapShown ? (
                  <CloseIcon
                    onClick={() => {
                      setIsFleetMapShown(false);
                    }}
                    color="warning"
                  />
                ) : (
                  <MapRoundedIcon
                    onClick={() => {
                      setIsFleetMapShown(true);
                      setIsAvailabilitiesShown(false);
                      setIsScheduleShown(false);
                      setIsNotesShown(false);
                    }}
                    color="primary"
                  />
                )}
              </Tooltip>
            </HideableButtonContainer>
            <HeaderBarButtonContainer
              variants={closeIconAndDateVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              onClick={() => {
                refetchTourGroups();
              }}
            >
              <i className="fa-solid fa-arrows-rotate header-button"></i>
            </HeaderBarButtonContainer>
          </div>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default DayPlannerTourGroupsBarContent;
