// src/components/AddUsersModal/sections/PasswordSection.tsx
import React from "react";
import { useFormikContext } from "formik";
import { UserFormValues } from "../types";
import { Section, SectionTitle, FormRow, FormGroup } from "../styles";
import { validationFieldStyle } from "../styles/textFieldStyles";

// MUI Components
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface PasswordSectionProps {
  isEditing: boolean;
  generatePassword: () => void;
}

const PasswordSection = ({
  isEditing,
  generatePassword,
}: PasswordSectionProps) => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<UserFormValues>();
  // Set showPassword to true by default to make text visible
  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isValid = touched.password && !errors.password;

  return (
    <Section>
      <SectionTitle>Password</SectionTitle>
      <FormRow>
        <FormGroup flex={1}>
          <TextField
            id="password"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"} // Default to text type
            placeholder={
              isEditing
                ? "Enter new password (leave blank to keep current)"
                : "Enter password"
            }
            value={values.password}
            fullWidth
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    size="small"
                    sx={{ mr: 0.5 }}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                  <IconButton
                    aria-label="generate password"
                    onClick={generatePassword}
                    edge="end"
                    size="small"
                  >
                    <KeyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={validationFieldStyle(isValid || !touched.password)}
          />
        </FormGroup>
      </FormRow>
    </Section>
  );
};

export default PasswordSection;
