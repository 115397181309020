import { useContext } from "react";
import { Context } from "./SchedulePlanner";
import styled from "styled-components";
import { useFetchTourGroupsBySpecificDateForSchedule } from "@/reactQueryHooks";
import TourGroupList from "./tour_groups_list/TourGroupList";
import TourGroupLoader from "./TourGroupLoader";
import "react-loading-skeleton/dist/skeleton.css";

const ContentContainer = styled.div`
  overflow: hidden;
  //hide in less than 900px
  @media (max-width: 900px) {
    display: none;
  }
  min-width: 300px;
  max-width: 300px;
`;

//------------------------------------------------------------------------------------

const TourGroupsListWrapper = () => {
  const { tourGroupsDate } = useContext(Context);
  const { data: tourGroupsBySpecificDate, isFetching } =
    useFetchTourGroupsBySpecificDateForSchedule(tourGroupsDate);

  return (
    <ContentContainer>
      {isFetching && <TourGroupLoader />}
      {!isFetching && <TourGroupList tourGroups={tourGroupsBySpecificDate} />}
    </ContentContainer>
  );
};

export default TourGroupsListWrapper;
