import styled from "styled-components";
import { useState, useEffect } from "react";
import { useFetchEntities } from "@/reactQueryHooks";

//----------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  max-width: 340px;
  align-self: center;
  height: fit-content;
  gap: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", sans-serif;
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SearchIcon = styled.span`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 8px 8px 30px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 12px;
  transition: all 0.2s ease;
  background-color: #f9fafb;

  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 1px rgba(59, 130, 246, 0.2);
    background-color: #ffffff;
  }

  &::placeholder {
    color: #9ca3af;
  }
`;

const StaffList = styled.div`
  flex: 1;
  max-height: 300px;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 8px;
  }
`;

const StaffItem = styled.div<{ isSelected: boolean }>`
  padding: 6px 8px;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? "#ebf5ff" : "white")};
  border: 1px solid
    ${({ isSelected }) => (isSelected ? "#bfdeff" : "transparent")};
  transition: all 0.15s ease;

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? "#deeeff" : "#f9fafb"};
  }

  display: flex;
  align-items: center;
`;

const GroupDivider = styled.div`
  padding: 4px 6px;
  font-size: 10px;
  font-weight: 600;
  color: #6b7280;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background-color: #f9fafb;
  border-radius: 4px;
  position: sticky;
  top: 0;
  margin-bottom: 1px;
`;

const Checkbox = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: ${({ checked }) => (checked ? "#3b82f6" : "white")};
  border: 1px solid ${({ checked }) => (checked ? "#3b82f6" : "#d1d5db")};
  transition: all 0.15s ease;

  &:after {
    content: "";
    display: ${({ checked }) => (checked ? "block" : "none")};
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    margin-bottom: 1px;
  }
`;

const UserName = styled.span`
  font-size: 12px;
  color: #374151;
  font-weight: 500;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 2px;
`;

const RoleButton = styled.button<{ isActive: boolean }>`
  padding: 4px 8px;
  background-color: ${({ isActive }) => (isActive ? "#ebf5ff" : "#f3f4f6")};
  border: 1px solid ${({ isActive }) => (isActive ? "#3b82f6" : "transparent")};
  border-radius: 4px;
  cursor: pointer;
  font-size: 10px;
  font-weight: ${({ isActive }) => (isActive ? "600" : "500")};
  color: ${({ isActive }) => (isActive ? "#3b82f6" : "#4b5563")};
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? "#deeeff" : "#e5e7eb")};
  }
`;

const ActionButton = styled.button<{ primary?: boolean }>`
  padding: 6px 12px;
  background-color: ${({ primary }) => (primary ? "#3b82f6" : "white")};
  color: ${({ primary }) => (primary ? "white" : "#4b5563")};
  border: 1px solid ${({ primary }) => (primary ? "#3b82f6" : "#e5e7eb")};
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ primary }) => (primary ? "#2563eb" : "#f9fafb")};
    box-shadow: ${({ primary }) =>
      primary
        ? "0 1px 3px rgba(37, 99, 235, 0.3)"
        : "0 1px 2px rgba(0, 0, 0, 0.05)"};
  }
`;

const EmptyState = styled.div`
  padding: 12px;
  text-align: center;
  color: #6b7280;
  font-size: 11px;
  border: 1px dashed #e5e7eb;
  border-radius: 4px;
  margin: 6px 0;
`;

const CountBadge = styled.span`
  background-color: #e5e7eb;
  color: #4b5563;
  font-size: 9px;
  font-weight: 600;
  padding: 1px 5px;
  border-radius: 9999px;
  margin-left: 4px;
`;

interface Role {
  _id: string;
  title: string;
}

interface User {
  _id: string;
  name: string;
  email: string;
  phone: string;
  roles: Role[];
  isActive: boolean;
}

interface UserInSelectionList {
  value: string;
  label: string;
}

//------------------------------------------------------------------------

interface StaffSelectProps {
  user_ids: string[];
  setUser_ids: (user_ids: string[]) => void;
}

const StaffSelect = ({ user_ids, setUser_ids }: StaffSelectProps) => {
  const [localUserIds, setLocalUserIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeRoles, setActiveRoles] = useState<string[]>([]);
  const { data: entities } = useFetchEntities() as any;

  const roles = entities.data.roles;

  const filteredStaff = entities.data.staff.filter((user: User) => {
    return user.isActive === true;
  });

  const staffIdsByRole = roles.reduce((acc: any, role: Role) => {
    const roleTitle = role.title;

    const roleUsers = filteredStaff.filter((user: User) =>
      user.roles.some((userRole: Role) => userRole._id === role._id)
    );

    acc[roleTitle] = roleUsers.map((user: User) => user._id);

    return acc;
  }, {});

  // Convert staff to selection format and sort alphabetically
  const staffOptions = filteredStaff
    .map((user: User) => {
      return {
        value: user._id,
        label: user.name,
      };
    })
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  // Filter staff by search term
  const filteredOptions = staffOptions.filter((option: UserInSelectionList) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort list to show selected users at top
  const sortedOptions = [...filteredOptions].sort((a, b) => {
    const aSelected = localUserIds.includes(a.value);
    const bSelected = localUserIds.includes(b.value);

    if (aSelected && !bSelected) return -1;
    if (!aSelected && bSelected) return 1;
    return a.label.localeCompare(b.label);
  });

  // Separate selected and non-selected users
  const selectedOptions = sortedOptions.filter((option) =>
    localUserIds.includes(option.value)
  );

  const nonSelectedOptions = sortedOptions.filter(
    (option) => !localUserIds.includes(option.value)
  );

  useEffect(() => {
    // Initialize localUserIds with user_ids when component mounts
    setLocalUserIds(user_ids);
  }, [user_ids]);

  useEffect(() => {
    const local_user_ids = localStorage.getItem("user_ids");
    if (local_user_ids) {
      setUser_ids(JSON.parse(local_user_ids));
    }
  }, [setUser_ids]);

  const toggleUserSelection = (userId: string) => {
    setLocalUserIds((prev) => {
      if (prev.includes(userId)) {
        return prev.filter((id) => id !== userId);
      } else {
        return [...prev, userId];
      }
    });
  };

  const toggleRoleSelection = (roleTitle: string) => {
    const roleIds = staffIdsByRole[roleTitle] || [];

    // Check if role is already active
    if (activeRoles.includes(roleTitle)) {
      // Remove this role from active roles
      const newActiveRoles = activeRoles.filter((role) => role !== roleTitle);
      setActiveRoles(newActiveRoles);

      // Only remove user IDs that aren't part of any other active role
      setLocalUserIds((prev) => {
        return prev.filter((id) => {
          // If this ID is in the current role
          if (roleIds.includes(id)) {
            // Check if this ID exists in any other active role
            return newActiveRoles.some((otherRole) => {
              const otherRoleIds = staffIdsByRole[otherRole] || [];
              return otherRoleIds.includes(id);
            });
          }
          // Keep IDs that aren't in the deselected role
          return true;
        });
      });
    } else {
      // Add this role to active roles
      setActiveRoles((prev) => [...prev, roleTitle]);

      // Add all user IDs from this role that aren't already selected
      setLocalUserIds((prev) => {
        const uniqueIds = new Set([...prev]);
        roleIds.forEach((id: string) => uniqueIds.add(id));
        return Array.from(uniqueIds);
      });
    }
  };

  const clearAllSelections = () => {
    setLocalUserIds([]);
    setActiveRoles([]);
  };

  const applySelections = () => {
    setUser_ids(localUserIds);
    localStorage.setItem("user_ids", JSON.stringify(localUserIds));
  };

  return (
    <Container>
      <SearchContainer>
        <SearchIcon>
          <svg
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </SearchIcon>
        <SearchInput
          type="text"
          placeholder="Search staff by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={(e) => e.stopPropagation()}
        />
      </SearchContainer>

      <ButtonsContainer>
        {Object.keys(staffIdsByRole).map((roleTitle) => (
          <RoleButton
            key={roleTitle}
            isActive={activeRoles.includes(roleTitle)}
            onClick={(e) => {
              e.stopPropagation();
              toggleRoleSelection(roleTitle);
            }}
          >
            {roleTitle}
            <CountBadge>{staffIdsByRole[roleTitle].length}</CountBadge>
          </RoleButton>
        ))}
      </ButtonsContainer>

      <StaffList
        style={{
          height:
            filteredOptions.length === 0
              ? "auto"
              : filteredOptions.length < 5
              ? "auto"
              : "180px",
        }}
      >
        {selectedOptions.length > 0 && (
          <>
            <GroupDivider>
              Selected Staff ({selectedOptions.length})
            </GroupDivider>
            {selectedOptions.map((option: UserInSelectionList) => (
              <StaffItem
                key={option.value}
                isSelected={true}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleUserSelection(option.value);
                }}
              >
                <Checkbox checked={true} onClick={(e) => e.stopPropagation()} />
                <UserName>{option.label}</UserName>
              </StaffItem>
            ))}
          </>
        )}

        {nonSelectedOptions.length > 0 && (
          <>
            {selectedOptions.length > 0 && (
              <GroupDivider>
                Available Staff ({nonSelectedOptions.length})
              </GroupDivider>
            )}

            {nonSelectedOptions.map((option: UserInSelectionList) => (
              <StaffItem
                key={option.value}
                isSelected={false}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleUserSelection(option.value);
                }}
              >
                <Checkbox
                  checked={false}
                  onClick={(e) => e.stopPropagation()}
                />
                <UserName>{option.label}</UserName>
              </StaffItem>
            ))}
          </>
        )}

        {filteredOptions.length === 0 && (
          <EmptyState>
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ margin: "0 auto 4px", display: "block", opacity: "0.5" }}
            >
              <path
                d="M10 6H6C4.89543 6 4 6.89543 4 8V18C4 19.1046 4.89543 20 6 20H16C17.1046 20 18 19.1046 18 18V14M14 4H20M20 4V10M20 4L10 14"
                stroke="#6B7280"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            No staff members found
          </EmptyState>
        )}
      </StaffList>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "4px",
        }}
      >
        <ActionButton onClick={clearAllSelections}>Clear All</ActionButton>
        <ActionButton primary onClick={applySelections}>
          Apply Selection
        </ActionButton>
      </div>
    </Container>
  );
};

export default StaffSelect;
