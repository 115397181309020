import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import { v4 as uuidv4 } from "uuid";
import {
  SectionContainer,
  SectionTitle,
  EmptyMessage,
  Divider,
  colors,
} from "../styles";

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CommentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  border-radius: 8px;
  //background: ${colors.comment};
  transition: all 0.2s ease;

  &:hover {
    background: ${colors.commentHover};
  }

  i {
    color: ${colors.primary};
  }

  .delete-button {
    opacity: 0;
    color: ${colors.danger};
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      color: ${colors.dayOff};
    }
  }

  &:hover .delete-button {
    opacity: 1;
  }
`;

const CommentText = styled.span`
  flex: 1;
  font-size: 13px;
  color: ${colors.textPrimary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CommentInputContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 5px;
`;

const CommentInput = styled.input`
  flex: 1;
  padding: 12px 16px;
  border: 1px solid ${colors.border};
  border-radius: 10px;
  //background: ${colors.light};
  outline: none;
  font-size: 14px;
  transition: all 0.2s ease;

  &:focus {
    border-color: ${colors.primary};
    box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.15);
  }

  &::placeholder {
    color: ${colors.gray};
  }
`;

const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 10px;
  border: none;
  background: ${colors.primary};
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${colors.secondary};
  }

  &:active {
    transform: translateY(0);
  }

  i {
    font-size: 16px;
  }
`;

const CommentsSection = ({
  commentInput,
  setCommentInput,
  scheduleData,
  entities,
  hasComments,
  onAddComment,
  onDeleteComment,
}) => {
  return (
    <SectionContainer>
      <SectionTitle>
        <i className="fas fa-comments"></i>
        Comments
      </SectionTitle>

      <CommentInputContainer>
        <CommentInput
          placeholder="Add a comment..."
          value={commentInput}
          onChange={(e) => setCommentInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && onAddComment()}
        />
        <SendButton onClick={onAddComment}>
          <i className="fa-solid fa-paper-plane"></i>
        </SendButton>
      </CommentInputContainer>

      {hasComments ? (
        <CommentsContainer>
          {scheduleData?.comments.map((comment) => {
            const author = entities?.staff?.find(
              (user) => user._id === comment?.author_id
            );

            return (
              <Tooltip
                key={uuidv4()}
                title={
                  <div style={{ padding: "5px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <i
                        className="fa-solid fa-user"
                        style={{ fontSize: "10px" }}
                      ></i>
                      <span>{author?.name || "UNKNOWN"}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <i
                        className="fa-solid fa-calendar"
                        style={{ fontSize: "10px" }}
                      ></i>
                      <span>{comment.date}</span>
                    </div>
                    <Divider />
                    <div style={{ fontStyle: "italic", marginTop: "5px" }}>
                      "{comment.text}"
                    </div>
                  </div>
                }
                arrow
              >
                <CommentContainer>
                  <i className="fa-regular fa-comment"></i>
                  <CommentText>{comment.text}</CommentText>
                  <i
                    className="fa-solid fa-trash delete-button"
                    onClick={() => onDeleteComment(comment._id)}
                  ></i>
                </CommentContainer>
              </Tooltip>
            );
          })}
        </CommentsContainer>
      ) : (
        <EmptyMessage
          style={{
            background: "rgba(67, 97, 238, 0.05)",
            color: colors.primary,
          }}
        >
          No comments yet
        </EmptyMessage>
      )}
    </SectionContainer>
  );
};

export default CommentsSection;
