import { useState } from "react";
import { FieldWrapper, SectionWrapper, SectionContainer } from "../styled";
import { useFormikContext } from "formik";
import { useFetchEntities } from "../../../../reactQueryHooks";
import TextField from "@mui/material/TextField";
import MapIcon from "@mui/icons-material/Map";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Fab from "@mui/material/Fab";
import PickupsListSelectionModal from "../PickupsListSelectionModal";
import { Button } from "@mui/material";

//---------------------------------------------------

const PickupLocationSection = () => {
  const [showPickupSelectionListModal, setShowPickupSelectionListModal] =
    useState(false);
  const { data: entities } = useFetchEntities();
  const { values, setFieldValue, handleChange } = useFormikContext();
  const meetingPoints = entities?.data?.meetingPoints;

  //---------------------------------------------------

  return (
    <>
      <SectionWrapper>
        <SectionContainer>
          <span className="form-header">Pickup location</span>
        </SectionContainer>
        <SectionContainer>
          <FieldWrapper>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                backgroundColor: "white",
                flex: 1,
                cursor: "pointer",
              }}
              title="Select pickup from list"
              onClick={() => setShowPickupSelectionListModal(true)}
            >
              <ArrowDropDownIcon
                style={{
                  opacity: 0,
                }}
              />
              <span>
                {values.pickup_location.name
                  ? values.pickup_location.name
                  : "Select pickup from list"}
              </span>
              <ArrowDropDownIcon
                style={{
                  color: "grey",
                }}
              />
            </div> */}

            <TextField
              style={{ flex: 1 }}
              size="small"
              label="Pickup location"
              variant="filled"
              value={values.pickup_location.name}
              autoComplete="off"
              spellCheck="false"
              sx={{
                backgroundColor: "white",
                flex: 1,
                cursor: "pointer",
              }}
              slotProps={{
                htmlInput: {
                  style: {
                    backgroundColor: "white",
                    fontSize: "13px",
                    cursor: "pointer",
                  },
                },
                inputLabel: {
                  shrink: true,
                },
                input: {
                  endAdornment: (
                    <ArrowDropDownIcon
                      sx={{
                        color: "grey",
                      }}
                    />
                  ),
                  sx: {
                    backgroundColor: "white",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  },
                },
              }}
              onClick={() => setShowPickupSelectionListModal(true)}
            />

            <TextField
              style={{ flex: 1 }}
              size="small"
              label="Address"
              variant="filled"
              name="pickup_location.address"
              onChange={handleChange}
              value={values.pickup_location.address}
              autoComplete="off"
              spellCheck="false"
              sx={{
                backgroundColor: "white",
                flex: 1,
              }}
              slotProps={{
                htmlInput: {
                  style: {
                    backgroundColor: "white",
                    fontSize: "13px",
                  },
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </FieldWrapper>

          <FieldWrapper>
            <TextField
              size="small"
              label="Pickup location"
              variant="filled"
              name="pickup_location.name"
              onChange={handleChange}
              value={values.pickup_location.name}
              autoComplete="off"
              spellCheck="false"
              sx={{
                backgroundColor: "white",
                flex: 1,
              }}
              slotProps={{
                htmlInput: {
                  style: {
                    backgroundColor: "white",
                    fontSize: "13px",
                  },
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            />

            <TextField
              type="time"
              size="small"
              label="Pickup time"
              variant="filled"
              name="pickup_time"
              onChange={handleChange}
              value={values.pickup_time}
              autoFocus
              autoComplete="off"
              spellCheck="false"
              sx={{
                backgroundColor: "white",
              }}
              slotProps={{
                htmlInput: {
                  style: {
                    backgroundColor: "white",
                    fontSize: "13px",
                  },
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            />

            <Fab
              title="Open in Google Maps"
              onClick={() => {
                if (values.client_location) {
                  window.open(
                    `https://www.google.com.sa/maps/place/${values.pickup_location.latitude},${values.pickup_location.longitude}?hl=en`
                  );
                } else {
                  alert("Address is empty");
                }
              }}
              size="small"
              sx={{
                backgroundColor: "white",
                zIndex: 0,
              }}
            >
              <MapIcon fontSize="small" />
            </Fab>
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              size="small"
              label="Latitude"
              variant="filled"
              name="pickup_location.latitude"
              onChange={async (e) => {
                await setFieldValue("pickup_location.latitude", e.target.value);
                await setFieldValue(
                  "pickup_location.google_maps_url",
                  `https://www.google.com/maps/place/${e.target.value},${values.pickup_location.longitude}`
                );
              }}
              value={values.pickup_location.latitude}
              autoComplete="off"
              spellCheck="false"
              sx={{
                backgroundColor: "white",
                flex: 1,
              }}
              slotProps={{
                htmlInput: {
                  style: { backgroundColor: "white", fontSize: "13px" },
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            />

            <TextField
              size="small"
              label="Longitude"
              variant="filled"
              name="pickup_location.longitude"
              onChange={async (e) => {
                await setFieldValue(
                  "pickup_location.longitude",
                  e.target.value
                );
                await setFieldValue(
                  "pickup_location.google_maps_url",
                  `https://www.google.com/maps/place/${values.pickup_location.latitude},${e.target.value}`
                );
              }}
              value={values.pickup_location.longitude}
              autoComplete="off"
              spellCheck="false"
              sx={{
                backgroundColor: "white",
                flex: 1,
              }}
              slotProps={{
                htmlInput: {
                  style: { backgroundColor: "white", fontSize: "13px" },
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              size="small"
              label="Map url"
              variant="filled"
              name="pickup_location.google_maps_url"
              onChange={handleChange}
              value={values.pickup_location.google_maps_url}
              autoComplete="off"
              spellCheck="false"
              sx={{
                backgroundColor: "white",
                flex: 1,
              }}
              slotProps={{
                htmlInput: {
                  style: { backgroundColor: "white", fontSize: "13px" },
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField
              size="small"
              label="Image url"
              variant="filled"
              name="pickup_location.img_url"
              onChange={handleChange}
              value={values.pickup_location.img_url}
              autoComplete="off"
              spellCheck="false"
              sx={{
                backgroundColor: "white",
                flex: 1,
              }}
              slotProps={{
                htmlInput: {
                  style: { backgroundColor: "white", fontSize: "13px" },
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              size="small"
              label="Instructions"
              variant="filled"
              name="pickup_location.instructions"
              onChange={handleChange}
              value={values.pickup_location.instructions}
              autoComplete="off"
              spellCheck="false"
              sx={{
                backgroundColor: "white",
                flex: 1,
              }}
              slotProps={{
                htmlInput: {
                  style: { backgroundColor: "white", fontSize: "13px" },
                },
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Button
              style={{
                fontSize: "10px",
              }}
              color="warning"
              size="small"
              variant="contained"
              onClick={async () => {
                await setFieldValue("pickup_time", "");
              }}
            >
              CLEAR PICKUP TIME
            </Button>
            <Button
              style={{
                fontSize: "10px",
              }}
              color="warning"
              size="small"
              variant="contained"
              onClick={async () => {
                await setFieldValue("pickup_location.name", "");
                await setFieldValue("pickup_time", "");
                await setFieldValue("pickup_location.latitude", "");
                await setFieldValue("pickup_location.longitude", "");
                await setFieldValue("pickup_location.google_maps_url", "");
                await setFieldValue("pickup_location.img_url", "");
                await setFieldValue("pickup_location.address", "");
                await setFieldValue("pickup_location.instructions", "");
              }}
            >
              CLEAR PICKUP LOCATION
            </Button>
          </FieldWrapper>
        </SectionContainer>
      </SectionWrapper>

      {showPickupSelectionListModal && (
        <PickupsListSelectionModal
          close={() => setShowPickupSelectionListModal(false)}
          meetingPoints={meetingPoints}
        />
      )}
    </>
  );
};

export default PickupLocationSection;
