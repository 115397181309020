// src/components/AddUsersModal/sections/DevicesSection.tsx
import React from "react";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { DeviceInfo } from "../types";
import {
  Section,
  SectionTitle,
  DevicesList,
  DeviceItem,
  EmptyState,
} from "../styles";

interface DevicesSectionProps {
  devices: DeviceInfo[];
  mobileLogStatus: boolean;
  onLogoutDevices: () => void;
  isLoggingOut: boolean;
}

const DevicesSection = ({
  devices,
  mobileLogStatus,
  onLogoutDevices,
  isLoggingOut,
}: DevicesSectionProps) => {
  return (
    <Section>
      <SectionTitle>Logged Devices</SectionTitle>

      {devices.length === 0 || !mobileLogStatus ? (
        <EmptyState>
          <PhoneAndroidIcon sx={{ marginRight: "8px", color: "#9e9e9e" }} />
          No logged devices
        </EmptyState>
      ) : (
        <>
          <DevicesList>
            {devices.map((device, index) => (
              <DeviceItem key={index}>
                <span>
                  <PhoneAndroidIcon
                    sx={{ marginRight: "8px", color: "#757575", fontSize: 18 }}
                  />
                  {device.device}
                </span>
              </DeviceItem>
            ))}
          </DevicesList>

          <div style={{ marginTop: "16px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onLogoutDevices}
              disabled={isLoggingOut}
              startIcon={
                isLoggingOut ? <CircularProgress size={20} /> : <LogoutIcon />
              }
              sx={{ maxWidth: "250px" }}
            >
              Logout from all devices
            </Button>
          </div>
        </>
      )}
    </Section>
  );
};

export default DevicesSection;
