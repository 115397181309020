import styled from "styled-components";
import { useEffect, useState, useCallback, useContext } from "react";
import CustomTr from "./CustomTr";
import { TourGuidesContentContext } from "./TourGuidesContent";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneOutlinedIcon from "@mui/icons-material/RemoveDoneOutlined";

//------------------------------------------------------------------------------------------

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.6);
  /* position: relative; */
`;

const Table = styled.table`
  border-collapse: collapse;
`;

const Header = styled.thead`
  height: 37px;
  position: sticky;
  top: 0;
  font-size: 11px;
  // background-color: rgba(220, 220, 220, 0.4);
  //background-color: hsl(0, 0%, 91%);
  background-color: white;
  /* backdrop-filter: blur(
    5px
  ); // adds height relative to viewport and messes with the dropdowns */
  z-index: 1;
  //a litle bit of shadow only bottom
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.4);
`;

const ThCell = styled.th`
  white-space: nowrap; /* Prevents the text from wrapping onto the next line */
  text-overflow: ellipsis; /* Displays an ellipsis when text is hidden */
  width: auto;
  text-align: center;
  i {
    margin-left: 10px;
    font-size: 11px;
  }
  user-select: none;
  .fa-angle-down {
    transform: ${({ isProductFilterShown }) =>
      isProductFilterShown ? "rotateX(180deg)" : "rotateX(0deg)"};
    color: ${({ isProductFilterShown }) =>
      isProductFilterShown ? "tomato" : "grey"};
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
    font-size: 12px;
  }
`;

const ExtendedThCell = styled(ThCell)`
  &:hover {
    text-decoration: underline;
  }
`;

const RemoveDoneOutlinedIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

//------------------------------------------------------------------------------------------

const TourGuidesTable = ({ data, hasNextPage, fetchNextPage }) => {
  const { IsListSelectable, setSelectedItems } = useContext(
    TourGuidesContentContext
  );
  const [selectAll, setSelectAll] = useState(false);

  const handleScroll = useCallback(
    (e) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 30; // 10 px before the bottom
      if (bottom && hasNextPage) {
        fetchNextPage();
      }
    },
    [hasNextPage, fetchNextPage]
  );

  useEffect(() => {
    const element = document.getElementById("tour-guides-table-container");
    element.addEventListener("scroll", handleScroll);

    return () => element.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, fetchNextPage, handleScroll]);

  //------------------------------------------------------------------------------------------

  return (
    <TableContainer id="tour-guides-table-container">
      <Table>
        <Header>
          <tr>
            <ThCell
              style={{
                width: "40px",
              }}
            >
              {IsListSelectable && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RemoveDoneOutlinedIconContainer
                    onClick={() => {
                      if (selectAll) {
                        setSelectedItems([]);
                        setSelectAll(false);
                      } else {
                        setSelectedItems(
                          data.map((item) => item.tour_group_id)
                        );
                        setSelectAll(true);
                      }
                    }}
                  >
                    {selectAll ? (
                      <RemoveDoneOutlinedIcon
                        color="error"
                        style={{
                          fontSize: "20px",
                        }}
                      />
                    ) : (
                      <DoneAllIcon
                        color="success"
                        style={{
                          fontSize: "20px",
                        }}
                      />
                    )}
                  </RemoveDoneOutlinedIconContainer>
                </div>
              )}
            </ThCell>
            <ThCell>Date</ThCell>
            <ThCell>Time</ThCell>
            <ThCell>Guide</ThCell>
            <ThCell>Proposed to</ThCell>
            <ThCell>Confirm</ThCell>
            <ThCell>Details</ThCell>
            <ExtendedThCell>Product</ExtendedThCell>
            <ThCell>Option</ThCell>
            <ThCell>Bookings</ThCell>
            <ThCell>Seats</ThCell>
            <ThCell>Pickup</ThCell>
          </tr>
        </Header>
        <tbody>
          {data?.length ? (
            data.map((row, index) => {
              return <CustomTr key={row.tour_group_id} row={row} />;
            })
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      {!hasNextPage && (
        <div
          style={{ color: "indianred", textAlign: "center", padding: "10px" }}
        >
          No more data to show
        </div>
      )}
    </TableContainer>
  );
};

export default TourGuidesTable;
