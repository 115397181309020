import styled from "styled-components";
import moment from "moment";
import { colors } from "../styles";
import { useFetchEntities } from "@/reactQueryHooks";

//----------------------------------

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  //background-color: ${colors.light};
  border-radius: 10px;
  //border-left: 4px solid ${colors.primary};
  transition: all 0.2s ease;
  position: sticky;
  top: 0;
  z-index: 10;
  //margin-bottom: 5px;
  //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  gap: 8px;
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.textPrimary};
  font-weight: 500;

  i {
    color: ${colors.primary};
    font-size: 14px;
  }
`;

const Header = ({ tourGroup, date }) => {
  const formattedDate = moment(new Date(date)).format("ddd, DD MMM YYYY");
  const { data: entities } = useFetchEntities();
  const product = entities?.data?.products.find(
    (product) => product._id === tourGroup.product_id
  );
  const option = product?.options.find(
    (option) => option._id === tourGroup.option_id
  );
  const startTime = product?.start_times.find(
    (time) => time._id === tourGroup.start_time_id
  );
  return (
    <HeaderContainer>
      <HeaderItem>
        <i className="fas fa-star"></i>
        <span>{`${product?.title} - ${option?.title} - Group #${tourGroup?.index}`}</span>
      </HeaderItem>
      <HeaderItem>
        <i className="fas fa-calendar-day"></i>
        <span>{formattedDate}</span>
      </HeaderItem>
      <HeaderItem>
        <i className="fas fa-clock"></i>
        <span>{startTime?.time_slot}</span>
      </HeaderItem>
    </HeaderContainer>
  );
};

export default Header;
