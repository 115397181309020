import React from "react";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import styled from "styled-components";
import SchedulePreferencesTooltip from "./SchedulePreferencesTooltip";

// Styled container for the info icon
const InfoContainer = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: help;
  position: absolute;
  top: 5px;
  right: 5px;
`;

// Custom styles for the MUI Tooltip
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: transparent;
    padding: 0;
    max-width: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    margin: 0;
  }

  & .MuiTooltip-arrow {
    color: white;
  }
`;

interface ScheduleInfoTooltipProps {
  userData: any; // Type according to your User interface
}

const ScheduleInfoTooltip: React.FC<ScheduleInfoTooltipProps> = ({
  userData,
}) => {
  return (
    <StyledTooltip
      title={<SchedulePreferencesTooltip user={userData} />}
      arrow
      placement="right"
      PopperProps={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altBoundary: true,
              tether: false,
              padding: 8,
            },
          },
        ],
      }}
    >
      <InfoContainer>
        <InfoIcon
          sx={{
            fontSize: "15px",
            color: "rgb(163 163 163)",
          }}
        />
      </InfoContainer>
    </StyledTooltip>
  );
};

export default ScheduleInfoTooltip;
