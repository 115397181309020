import React, { ReactNode } from "react";
import styled from "styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";

//-----------------------------------------------------------

// Define the types for the styled button
interface ButtonProps {
  children: ReactNode;
}

const Button = styled.button<ButtonProps>``;

interface NavButtonProps {
  title: string;
  children: ReactNode;
  onClick?: () => void; // Adding onClick prop
}

//-----------------------------------------------------------

const NavButtonWithCustomMenu = ({
  title,
  children,
  onClick,
}: NavButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Button
      ref={anchorRef}
      id="composition-button"
      aria-controls={open ? "composition-menu" : undefined}
      aria-expanded={open ? "true" : undefined}
      aria-haspopup="true"
      onClick={() => {
        handleToggle();
        if (onClick) {
          onClick();
        }
      }}
    >
      {title}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{
          zIndex: 9999,
        }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              marginTop: "8px",

              minWidth: "150px",
              transformOrigin: "center top",
              //borderRadius: "10px",
              boxShadow: "0px 0px 5px 1px rgba(177 177 177 / 0.75)",

              //color: "white",
              fontFamily: "Core Sans C",
              fontWeight: "600",
            }}
          >
            <Paper style={{ minWidth: anchorRef.current?.offsetWidth }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  style={{
                    padding: "0",
                  }}
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Button>
  );
};

export default NavButtonWithCustomMenu;
