import { ContentItem, ContentItemContent, ContentItemTitle } from "./styled";
import { useState } from "react";
import {
  useFetchEntities,
  useGetUserDaySchedulesThatIncludesTourGroup,
  useAddOrUpdateUserDaySchedule,
} from "../../../../reactQueryHooks";
import AddAssigneesModal from "./AddAssigneesModal";
import GroupAssignmentDrawerContent from "../../schedule_planner/content/GroupAssignmentDrawerContent";
import Drawer from "@mui/material/Drawer";
import { useAxios } from "../../../../axiosProvider";

//-------------------------------------------

const SortableTableInfoAssignees = ({ tourGroup, refetch }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { data: entities } = useFetchEntities();
  const { data: assignees, isFetching } =
    useGetUserDaySchedulesThatIncludesTourGroup(tourGroup._id);
  const { user } = useAxios();
  const userIsAdmin = user.isAdmin;
  const userIsPermittedToAssignSchedule =
    user.permissions.assignSchedule || userIsAdmin;

  //-------------------------------------------

  const { isLoading: isUpdating, mutateAsync: addOrUpdateUserDaySchedule } =
    useAddOrUpdateUserDaySchedule();

  //-------------------------------------------

  return (
    <>
      <ContentItem
        //title="Add assignees"
        //a fade in with a little left to right movement
        initial={{ opacity: 0, x: -5 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -5 }}
        transition={{ duration: 0.25, delay: 0.4 }}
        onClick={() => {
          if (userIsPermittedToAssignSchedule) {
            setOpenDrawer(true);
          } else {
            alert("You do not have permission to assign schedule");
          }
        }}
      >
        <ContentItemTitle>Assignees</ContentItemTitle>
        <ContentItemContent>
          {isFetching && (
            <span style={{ color: "dodgerblue" }}>Loading assignees...</span>
          )}
          {!isFetching && assignees?.length === 0 && (
            <span
              style={{
                color: "indianred",
                fontWeight: "bold",
              }}
            >
              No assignees
            </span>
          )}
          <div className="names">
            {assignees?.map((assignee) => {
              const assigneeData = entities.data.staff.find(
                (user) => user._id === assignee.id
              );
              const assigneeRole = entities.data.roles.find(
                (role) => role._id === assignee.role
              );
              return (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                  key={assignee.id}
                >
                  <i
                    style={{
                      fontSize: "8px",
                    }}
                    className="fas fa-user"
                  ></i>
                  <span
                    title={assignee?.projected ? "Projected assignment" : ""}
                    style={{
                      color: assignee?.projected
                        ? "rgb(233 110 110)"
                        : "rgb(41 121 41)",
                    }}
                  >{` ${assigneeData.name} (${assigneeRole.title})`}</span>
                </div>
              );
            })}
          </div>
          {!isFetching && assignees?.length > 0 && (
            <span className="names_hidden">
              <i
                style={{
                  fontSize: "8px",
                }}
                className="fas fa-user"
              ></i>
              <i className="see">see assignees</i>
            </span>
          )}
        </ContentItemContent>
        {/* {isResourcesModalShown && (
            <AddAssigneesModal
              close={() => setIsResourcesModalShown(false)}
              tourGroup={tourGroup}
              refetch={refetch}
            />
          )} */}
      </ContentItem>

      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <GroupAssignmentDrawerContent
          tourGroup={tourGroup}
          closeDrawer={() => setOpenDrawer(false)}
        />
      </Drawer>
    </>
  );
};

export default SortableTableInfoAssignees;
