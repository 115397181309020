import { useContext, useEffect } from "react";
import { Context } from "..";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useQueryClient } from "react-query";
import {
  SectionContainer,
  SectionTitle,
  EmptyMessage,
  Badge,
  colors,
} from "../styles";
import {
  useFetchEntities,
  useGetUserDaySchedulesThatIncludesTourGroup,
  useFinalizeUserDaySchedule,
  useRemoveProjectedAssignment,
} from "@/reactQueryHooks";

//-----------------------------------------------

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProductItemContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-radius: 8px;
  background: ${colors.assigned};
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  &:hover {
    background: ${colors.light};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);

    .item-overlay {
      display: flex;
    }
  }
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  span {
    color: ${colors.textPrimary};
  }
`;

const ProjectedBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 500;
  background: ${colors.warning};
  color: white;
  margin-left: 5px;
`;

const ItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  display: none;
  flex-direction: row;
  z-index: 2;
`;

const OverlayButton = styled.button`
  flex: 1;
  border: none;
  background: transparent;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &.confirm {
    color: ${colors.success};

    &:hover {
      background: rgba(76, 201, 240, 0.1);
      color: ${colors.primary};
    }
  }

  &.remove {
    color: ${colors.danger};

    &:hover {
      background: rgba(247, 37, 133, 0.1);
    }
  }

  i {
    margin-right: 5px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InfoIconButton = styled(IconButton)`
  padding: 4px !important;
  color: ${colors.gray} !important;

  &:hover {
    color: ${colors.primary} !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
`;

const tooltipText =
  "Preliminary assignments that are not shown to the user until added to assignments";

const ProjectedSchedule = ({ tourGroup }) => {
  const queryClient = useQueryClient();
  const { setIsUpdating } = useContext(Context);
  const { data: entities } = useFetchEntities();

  const { mutateAsync: finalizeUserDaySchedule, isLoading: isFinalizing } =
    useFinalizeUserDaySchedule();
  const { mutateAsync: removeProjectedAssignment, isLoading: isRemoving } =
    useRemoveProjectedAssignment();

  const { data: assignees } = useGetUserDaySchedulesThatIncludesTourGroup(
    tourGroup._id
  );

  const projectedSchedules = assignees?.filter(
    (schedule) => schedule?.projected === true
  );

  const hasProjectedSchedules =
    projectedSchedules && projectedSchedules.length > 0;

  //------------------------------------------------

  useEffect(() => {
    setIsUpdating(isFinalizing || isRemoving);
  }, [isFinalizing, isRemoving, setIsUpdating]);

  //-----------------------------------------------

  return (
    <SectionContainer>
      <SectionTitle>
        <TitleContainer>
          <i className="fas fa-clock"></i>
          <span>Projected Assignments</span>
          <Tooltip title={tooltipText} arrow placement="top">
            <InfoIconButton size="small">
              <InfoIcon fontSize="small" />
            </InfoIconButton>
          </Tooltip>
        </TitleContainer>
      </SectionTitle>
      {!hasProjectedSchedules && (
        <EmptyMessage>No projected assignments</EmptyMessage>
      )}

      {hasProjectedSchedules && (
        <ProductList>
          {projectedSchedules.map((schedule, index) => {
            const user = entities?.data?.staff?.find(
              (staff) => staff._id === schedule?.id || null
            );
            const role = entities?.data?.roles?.find(
              (crewRole) => crewRole._id === schedule?.role || null
            );

            return (
              <ProductItemContainer key={uuidv4()}>
                <ProductInfo>
                  <span>{user?.name}</span>
                  <Badge>{role?.title}</Badge>
                  <ProjectedBadge>Projected</ProjectedBadge>
                </ProductInfo>

                <ItemOverlay className="item-overlay">
                  <OverlayButton
                    className="confirm"
                    onClick={async () => {
                      if (window.confirm("Add to assignments?")) {
                        await finalizeUserDaySchedule({
                          schedule_id: schedule.schedule_id,
                          tour_group_id: tourGroup._id,
                        });

                        await queryClient.invalidateQueries({
                          queryKey: [
                            "USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP",
                            tourGroup._id,
                          ],
                        });
                      }
                    }}
                  >
                    <i className="fas fa-check-circle"></i>
                    Add to Assignments
                  </OverlayButton>
                  <OverlayButton
                    className="remove"
                    onClick={async () => {
                      if (window.confirm("Remove projected assignment?")) {
                        await removeProjectedAssignment({
                          schedule_id: schedule.schedule_id,
                          tour_group_id: tourGroup._id,
                        });

                        await queryClient.invalidateQueries({
                          queryKey: [
                            "USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP",
                            tourGroup._id,
                          ],
                        });
                      }
                    }}
                  >
                    <i className="fas fa-times-circle"></i>
                    Remove
                  </OverlayButton>
                </ItemOverlay>
              </ProductItemContainer>
            );
          })}
        </ProductList>
      )}
    </SectionContainer>
  );
};

export default ProjectedSchedule;
