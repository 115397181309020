// src/components/AddUsersModal/components/SearchableSelect.tsx
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { GroupOption } from "../types";

interface SearchableSelectProps {
  options: GroupOption[];
  selectedOptions: GroupOption[];
  onChange: (selected: GroupOption[]) => void;
  placeholder?: string;
  isMulti?: boolean;
}

// Styled components
const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SelectButton = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  padding: 8px 12px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border-color: #bdbdbd;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    border-color: #2196f3;
    box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
  `}
`;

const SelectedOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  flex: 1;
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  background-color: #e3f2fd;
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 12px;
  max-width: 100%;
`;

const ChipLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ChipRemove = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-left: 4px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  color: #757575;

  &:hover {
    color: #f44336;
  }
`;

const DropdownIcon = styled.span<{ isOpen: boolean }>`
  margin-left: 8px;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.2s;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  outline: none;
  font-size: 14px;
`;

const OptionsList = styled.div`
  max-height: 168px;
  overflow-y: auto;
`;

const Option = styled.div<{ isSelected: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${({ isSelected }) =>
    isSelected ? "#e3f2fd" : "transparent"};

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? "#bbdefb" : "#f5f5f5"};
  }
`;

const Placeholder = styled.span`
  color: #9e9e9e;
`;

const EmptyMessage = styled.div`
  padding: 8px 12px;
  color: #9e9e9e;
  text-align: center;
`;

const SearchableSelect = ({
  options,
  selectedOptions,
  onChange,
  placeholder = "Select options",
  isMulti = true,
}: SearchableSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = (option: GroupOption) => {
    if (isMulti) {
      const isSelected = selectedOptions.some(
        (selected) => selected.value === option.value
      );
      if (isSelected) {
        onChange(
          selectedOptions.filter((selected) => selected.value !== option.value)
        );
      } else {
        onChange([...selectedOptions, option]);
      }
    } else {
      onChange([option]);
      setIsOpen(false);
    }
  };

  const handleRemoveOption = (option: GroupOption, event: React.MouseEvent) => {
    event.stopPropagation();
    onChange(
      selectedOptions.filter((selected) => selected.value !== option.value)
    );
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SelectContainer ref={containerRef}>
      <SelectButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <SelectedOptions>
          {selectedOptions.length === 0 ? (
            <Placeholder>{placeholder}</Placeholder>
          ) : (
            selectedOptions.map((option) => (
              <Chip key={option.value}>
                <ChipLabel>{option.label}</ChipLabel>
                <ChipRemove onClick={(e) => handleRemoveOption(option, e)}>
                  ×
                </ChipRemove>
              </Chip>
            ))
          )}
        </SelectedOptions>
        <DropdownIcon isOpen={isOpen}>
          <i className="fas fa-angle-down" />
        </DropdownIcon>
      </SelectButton>

      {isOpen && (
        <DropdownMenu>
          <SearchInput
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search..."
            autoFocus
          />

          <OptionsList>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <Option
                  key={option.value}
                  isSelected={selectedOptions.some(
                    (selected) => selected.value === option.value
                  )}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </Option>
              ))
            ) : (
              <EmptyMessage>No options found</EmptyMessage>
            )}
          </OptionsList>
        </DropdownMenu>
      )}
    </SelectContainer>
  );
};

export default SearchableSelect;
