import styled from "styled-components";
import moment from "moment";
import { colors } from "../styles";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  //background-color: ${colors.light};
  // border-radius: 10px;
  //border-left: 4px solid ${colors.primary};
  transition: all 0.2s ease;
  position: sticky;
  top: 0;
  z-index: 10;
  //margin-bottom: 5px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.textPrimary};
  font-weight: 500;

  i {
    color: ${colors.primary};
    font-size: 14px;
  }
`;

const Header = ({ userName, date }) => {
  const formattedDate = moment(new Date(date)).format("ddd, DD MMM YYYY");

  return (
    <HeaderContainer>
      <HeaderItem>
        <i className="fas fa-user"></i>
        <span>{userName}</span>
      </HeaderItem>
      <HeaderItem>
        <i className="fas fa-calendar-day"></i>
        <span>{formattedDate}</span>
      </HeaderItem>
    </HeaderContainer>
  );
};

export default Header;
