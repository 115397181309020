// TourGroupItem.tsx
import styled from "styled-components";
import { TourGroup } from "./types";
import { useFetchEntities } from "@/reactQueryHooks";
import { v4 as uuidv4 } from "uuid";
import RoleItem from "./RoleItem";
import AssignedRoleBadge from "./AssignedRoleBadge";
import { useGetUserDaySchedulesThatIncludesTourGroup } from "@/reactQueryHooks";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";

interface TourGroupItemProps {
  tourGroup: TourGroup;
  users?: any[]; // Array of users for getting user names
}

const TourGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  //border-radius: 3px;
  background-color: #ffffff;
  position: relative;
`;

const CornerRibbon = styled.div<{
  allAssigned: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
  z-index: auto;
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 12px;
    background-color: ${(props) => (props.allAssigned ? "#40c057" : "#fa5252")};
    transform: rotate(-45deg) translateY(-15px) translateX(-15px);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
`;

const TourHeaderRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const TourTitle = styled.h3`
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  color: #212529;
`;

const Date = styled.div`
  font-size: 12px;
  color: #495057;
  background-color: #f1f3f5;
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const StartTime = styled.div`
  font-size: 12px;
  color: #495057;
  background-color: #f1f3f5;
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &::before {
    content: "🕒";
    margin-right: 4px;
    font-size: 10px;
  }
`;

const Index = styled.div`
  font-size: 12px;
  color: #495057;
  background-color: #f1f3f5;
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &::before {
    content: "#";
    margin-right: 4px;
    font-size: 10px;
  }
`;

const TourOption = styled.div`
  font-size: 12px;
  color: #2a6294;
  display: flex;
  align-items: center;

  &::before {
    content: "🏷️";
    margin-right: 6px;
    font-size: 12px;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #e9ecef;
`;

const RolesContainer = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

const AssigneesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const StatusIndicator = styled.div<{ allAssigned: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.allAssigned ? "#40c057" : "#fa5252")};
  box-shadow: 0 0 0 2px
    ${({ allAssigned }) =>
      allAssigned ? "rgba(64, 192, 87, 0.2)" : "rgba(250, 82, 82, 0.2)"};
  align-self: center;
`;

const TourGroupItem = ({ tourGroup }: TourGroupItemProps) => {
  const { product_id, option_id, start_time_id, date } = tourGroup;
  const { data: entities } = useFetchEntities() as any;
  const product = entities.data.products.find(
    (product: any) => product._id === product_id
  );
  const option = product?.options.find((o: any) => o._id === option_id);
  const optionRolesIds = option?.crewRoles;
  const startTime = product?.start_times.find(
    (st: any) => st._id === start_time_id
  );

  const { data: assignees } = useGetUserDaySchedulesThatIncludesTourGroup(
    tourGroup._id
  );

  const assignedRoles = assignees?.map((usd: any) => usd.role);

  const allRolesAssigned = optionRolesIds?.every((roleId: string) =>
    assignedRoles?.includes(roleId)
  );

  return (
    <TourGroupContainer>
      <Tooltip
        title={
          allRolesAssigned ? "All roles assigned" : "Not all roles assigned"
        }
      >
        <CornerRibbon allAssigned={allRolesAssigned} />
      </Tooltip>

      <TourHeaderRow
        style={{
          paddingLeft: "10px",
        }}
      >
        <TourTitle>{product?.title}</TourTitle>
      </TourHeaderRow>

      <TourHeaderRow>
        <Date>{moment(tourGroup?.date).format("DD MMM YYYY")}</Date>
        <StartTime>{startTime?.time_slot}</StartTime>
        <Index>Group {tourGroup?.index}</Index>
      </TourHeaderRow>

      <TourOption>{option?.title}</TourOption>

      <Divider />

      <RolesContainer>
        {optionRolesIds?.map((roleId: string) => (
          <RoleItem
            key={uuidv4()}
            date={tourGroup.date}
            roleId={roleId}
            tourGroupId={tourGroup._id}
            isAssigned={false}
          />
        ))}
      </RolesContainer>

      {assignees?.length > 0 && (
        <>
          <Divider />
          <AssigneesContainer>
            {assignees.map((usd: any) => {
              const user = entities.data.staff.find(
                (s: any) => s._id === usd.id
              );
              return (
                <AssignedRoleBadge
                  key={uuidv4()}
                  roleId={usd.role}
                  userName={user?.name}
                />
              );
            })}
          </AssigneesContainer>
        </>
      )}
    </TourGroupContainer>
  );
};

export default TourGroupItem;
