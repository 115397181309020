// src/components/AddUsersModal/styles.ts
import styled from "styled-components";
import { motion } from "framer-motion";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ModalContent = styled(motion.div)`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 650px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ModalHeader = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  background-color: #f9f9f9;

  h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
`;

export const ModalBody = styled.div`
  padding: 24px;
  overflow-y: auto;
  max-height: calc(90vh - 140px);
`;

export const ModalFooter = styled.div`
  padding: 16px 24px;
  border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: flex-end;
  background-color: #f9f9f9;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #666;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #333;
  }
`;

// Common shared styles
export const Section = styled.section`
  margin-bottom: 24px;
`;

export const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 16px 0;
  color: #333;
  padding-bottom: 8px;
  border-bottom: 1px solid #eaeaea;
`;

export const FormRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const FormGroup = styled.div<{ flex?: number }>`
  flex: ${(props) => props.flex || 1};
  min-width: ${(props) => (props.flex === 1 ? "180px" : "auto")};
  position: relative;
`;

export const FormLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #555;
`;

export const FormInput = styled.input<{ isValid?: boolean }>`
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid ${(props) => (props.isValid ? "#4caf50" : "#e0e0e0")};
  border-radius: 6px;
  background-color: ${(props) =>
    props.isValid ? "rgba(76, 175, 80, 0.05)" : "#fff"};
  transition: all 0.2s;

  &:focus {
    border-color: #2196f3;
    box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
    outline: none;
  }

  &:hover:not(:focus) {
    border-color: #bdbdbd;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;

  label {
    font-size: 14px;
    color: #555;
    cursor: pointer;
  }
`;

export const Button = styled.button<{
  variant?: "primary" | "secondary" | "danger";
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: ${(props) => {
    switch (props.variant) {
      case "primary":
        return "#4caf50";
      case "secondary":
        return "#9e9e9e";
      case "danger":
        return "#f44336";
      default:
        return "#e0e0e0";
    }
  }};
  color: ${(props) => (props.variant === "secondary" ? "#fff" : "#fff")};

  &:hover {
    filter: brightness(1.1);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const SmallButton = styled(Button)`
  height: 36px;
  padding: 0 12px;
  font-size: 13px;
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const ErrorMessage = styled.div`
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
`;

export const DevicesList = styled.div`
  margin-top: 12px;
`;

export const DeviceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  background-color: #f5f5f5;
  border-radius: 6px;
  margin-bottom: 8px;
  border: 1px solid #e0e0e0;

  span {
    font-size: 14px;
  }
`;

export const EmptyState = styled.div`
  padding: 16px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 6px;
  color: #666;
  font-size: 14px;
  margin-bottom: 16px;
`;

export const PermissionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 12px;
  margin-top: 16px;
`;
