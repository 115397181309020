// src/components/AddUsersModal/sections/SchedulePreferencesSection.tsx
import { useFormikContext } from "formik";
import { UserFormValues } from "../types";
import {
  Section,
  SectionTitle,
  FormRow,
  FormGroup,
  FormLabel,
} from "../styles";
import moment from "moment";

// MUI Components
import Switch from "@mui/material/Switch";
import Slider from "@mui/material/Slider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const DAYS_OF_WEEK = [
  { value: "MONDAY", label: "Monday" },
  { value: "TUESDAY", label: "Tuesday" },
  { value: "WEDNESDAY", label: "Wednesday" },
  { value: "THURSDAY", label: "Thursday" },
  { value: "FRIDAY", label: "Friday" },
  { value: "SATURDAY", label: "Saturday" },
  { value: "SUNDAY", label: "Sunday" },
];

// Generate time options in 30-minute intervals
const generateTimeOptions = () => {
  const options = [];
  const startTime = moment().startOf("day");
  const endTime = moment().endOf("day");

  while (startTime <= endTime) {
    options.push({
      value: startTime.format("HH:mm"),
      label: startTime.format("HH:mm"),
    });
    startTime.add(30, "minutes");
  }

  return options;
};

const TIME_OPTIONS = generateTimeOptions();

const SchedulePreferencesSection = () => {
  const { values, setFieldValue } = useFormikContext<UserFormValues>();

  const handleTogglePreferredDay = (day: string) => {
    const currentDays = values.preferredWorkDaysPerWeek || [];
    if (currentDays.includes(day)) {
      setFieldValue(
        "preferredWorkDaysPerWeek",
        currentDays.filter((d) => d !== day)
      );
    } else {
      setFieldValue("preferredWorkDaysPerWeek", [...currentDays, day]);
    }
  };

  const handleTogglePreferredDayOff = (day: string) => {
    const currentDays = values.preferredDayOffDaysPerWeek || [];
    if (currentDays.includes(day)) {
      setFieldValue(
        "preferredDayOffDaysPerWeek",
        currentDays.filter((d) => d !== day)
      );
    } else {
      setFieldValue("preferredDayOffDaysPerWeek", [...currentDays, day]);
    }
  };

  const handleTimeChange = (index: number, timeString: string) => {
    const newTimes = [...(values.availableBetweenTimes || ["00:00", "23:59"])];
    newTimes[index] = timeString;
    setFieldValue("availableBetweenTimes", newTimes);
  };

  return (
    <Section>
      <SectionTitle>Schedule Preferences</SectionTitle>

      {/* Work Days Per Week */}
      <FormRow>
        <FormGroup flex={2}>
          <FormLabel>Preferred Work Days Per Week</FormLabel>
          <Box sx={{ width: "100%" }}>
            <Slider
              value={values.amountOfWorkDaysPerWeek || 5}
              onChange={(_, value) =>
                setFieldValue("amountOfWorkDaysPerWeek", value)
              }
              step={1}
              marks
              min={1}
              max={7}
              valueLabelDisplay="auto"
            />
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ mt: 1, display: "block" }}
            >
              {values.amountOfWorkDaysPerWeek} day
              {values.amountOfWorkDaysPerWeek !== 1 ? "s" : ""} per week
            </Typography>
          </Box>
        </FormGroup>
        <FormGroup flex={1}>
          <FormControlLabel
            control={
              <Switch
                checked={values.canExceedAmountOfWorkDaysPerWeek || false}
                onChange={(e) =>
                  setFieldValue(
                    "canExceedAmountOfWorkDaysPerWeek",
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Can exceed preferred days"
            sx={{ marginTop: "24px" }}
          />
        </FormGroup>
      </FormRow>

      {/* Preferred Work Days */}
      <FormRow>
        <FormGroup>
          <FormLabel>Preferred Work Days</FormLabel>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
            {DAYS_OF_WEEK.map((day) => (
              <Chip
                key={day.value}
                label={day.label}
                onClick={() => handleTogglePreferredDay(day.value)}
                color={
                  (values.preferredWorkDaysPerWeek || []).includes(day.value)
                    ? "primary"
                    : "default"
                }
                variant={
                  (values.preferredWorkDaysPerWeek || []).includes(day.value)
                    ? "filled"
                    : "outlined"
                }
              />
            ))}
          </Box>
        </FormGroup>
      </FormRow>

      {/* Preferred Day-Off Days */}
      <FormRow>
        <FormGroup>
          <FormLabel>Preferred Day-Off Days</FormLabel>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
            {DAYS_OF_WEEK.map((day) => (
              <Chip
                key={day.value}
                label={day.label}
                onClick={() => handleTogglePreferredDayOff(day.value)}
                color={
                  (values.preferredDayOffDaysPerWeek || []).includes(day.value)
                    ? "primary"
                    : "default"
                }
                variant={
                  (values.preferredDayOffDaysPerWeek || []).includes(day.value)
                    ? "filled"
                    : "outlined"
                }
              />
            ))}
          </Box>
        </FormGroup>
      </FormRow>

      <FormRow>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={values.isFlexibleWithWorkDaysPerWeek || false}
                onChange={(e) =>
                  setFieldValue(
                    "isFlexibleWithWorkDaysPerWeek",
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Flexible with work days"
          />
        </FormGroup>
      </FormRow>

      {/* Multiple Shift Days */}
      <FormRow>
        <FormGroup flex={2}>
          <FormControlLabel
            control={
              <Switch
                checked={values.canWorkMultipleShiftDays || false}
                onChange={(e) =>
                  setFieldValue("canWorkMultipleShiftDays", e.target.checked)
                }
                color="primary"
              />
            }
            label="Can work multiple shifts per day"
          />
        </FormGroup>
        {values.canWorkMultipleShiftDays && (
          <FormGroup flex={2}>
            <FormLabel>Max multiple shift days per week</FormLabel>
            <Box sx={{ width: "100%" }}>
              <Slider
                value={values.maxMultipleShiftDaysPerWeek || 3}
                onChange={(_, value) =>
                  setFieldValue("maxMultipleShiftDaysPerWeek", value)
                }
                step={1}
                marks
                min={1}
                max={7}
                valueLabelDisplay="auto"
                disabled={!values.canWorkMultipleShiftDays}
              />
            </Box>
          </FormGroup>
        )}
      </FormRow>

      {/* Full Day Shifts */}
      <FormRow>
        <FormGroup flex={2}>
          <FormControlLabel
            control={
              <Switch
                checked={values.canWorkFullDayShifts || false}
                onChange={(e) =>
                  setFieldValue("canWorkFullDayShifts", e.target.checked)
                }
                color="primary"
              />
            }
            label="Can work full day shifts"
          />
        </FormGroup>
        {values.canWorkFullDayShifts && (
          <FormGroup flex={2}>
            <FormLabel>Max full day shifts per week</FormLabel>
            <Box sx={{ width: "100%" }}>
              <Slider
                value={values.maxFullDayShiftsPerWeek || 2}
                onChange={(_, value) =>
                  setFieldValue("maxFullDayShiftsPerWeek", value)
                }
                step={1}
                marks
                min={1}
                max={7}
                valueLabelDisplay="auto"
                disabled={!values.canWorkFullDayShifts}
              />
            </Box>
          </FormGroup>
        )}
      </FormRow>

      {/* Available Times */}
      <FormRow>
        <FormGroup>
          <FormLabel>Available Between Times</FormLabel>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1 }}>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="from-time-label">From</InputLabel>
              <Select
                labelId="from-time-label"
                id="from-time"
                value={values.availableBetweenTimes?.[0] || "00:00"}
                label="From"
                onChange={(e) => handleTimeChange(0, e.target.value)}
              >
                {TIME_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="body2">to</Typography>

            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="to-time-label">To</InputLabel>
              <Select
                labelId="to-time-label"
                id="to-time"
                value={values.availableBetweenTimes?.[1] || "23:59"}
                label="To"
                onChange={(e) => handleTimeChange(1, e.target.value)}
              >
                {TIME_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </FormGroup>
      </FormRow>

      <FormRow>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={values.isFlexibleWithTimes || false}
                onChange={(e) =>
                  setFieldValue("isFlexibleWithTimes", e.target.checked)
                }
                color="primary"
              />
            }
            label="Flexible with times"
          />
        </FormGroup>
      </FormRow>

      {/* Schedule Remarks */}
      <FormRow>
        <FormGroup>
          <FormLabel>Schedule Remarks</FormLabel>
          <TextField
            fullWidth
            id="scheduleRemarks"
            name="scheduleRemarks"
            value={values.scheduleRemarks || ""}
            onChange={(e) => setFieldValue("scheduleRemarks", e.target.value)}
            multiline
            rows={4}
            placeholder="Any additional schedule preferences or notes"
            variant="outlined"
            size="medium"
            margin="normal"
          />
        </FormGroup>
      </FormRow>
    </Section>
  );
};

export default SchedulePreferencesSection;
