import styled from "styled-components";
import { useState, useEffect, createContext } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  subDays,
  addDays,
  addMonths,
  subMonths,
  eachDayOfInterval,
} from "date-fns";
import { useQueryClient } from "react-query";
import SelectionBar from "../../../selection_bar/SelectionBar";
import SchedulePlannerBarContent from "../bar/SchedulePlannerBarContent";
import SchedulePlannerGrid from "./SchedulePlannerGrid";
import TourGroupsList from "./TourGroupsListWrapper";
import { useFetchScheduleByDateRange } from "@/reactQueryHooks";
import "./style.css";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow: hidden;
  padding: 5px;
`;

//------------------------------------------------------------------------

export const Context = createContext<{
  tourGroupsDate: string;
  setTourGroupsDate: (date: string) => void;
}>({
  tourGroupsDate: format(new Date(), "yyyy-MM-dd"),
  setTourGroupsDate: (date: string) => {},
});

const SchedulePlanner = () => {
  const queryClient = useQueryClient();
  const [tourGroupsDate, setTourGroupsDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [targetDate, setTargetDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [user_ids, setUser_ids] = useState<number[]>([]);

  const date_range: string[] = eachDayOfInterval({
    start: subDays(new Date(targetDate), 7),
    end: addDays(new Date(targetDate), 7),
  }).map((date) => format(date, "yyyy-MM-dd"));

  const { refetch: refetchSchedule } = useFetchScheduleByDateRange(
    date_range,
    user_ids
  );

  //-----------------------------------------------------------------------

  const handleInvalidateTourGroupsForSchedule = (): void => {
    queryClient.invalidateQueries("TOUR_GROUPS_BY_SPECIFIC_DATE_FOR_SCHEDULE");
  };

  const handleDateChange = (action: "next" | "prev"): void => {
    if (action === "next") {
      //add 1 day
      const newDate = addDays(new Date(targetDate), 1);
      setTargetDate(format(newDate, "yyyy-MM-dd"));
      setTourGroupsDate(format(newDate, "yyyy-MM-dd"));
      refetchSchedule();
      handleInvalidateTourGroupsForSchedule();
    } else {
      //subtract 1 day
      const newDate = subDays(new Date(targetDate), 1);
      setTargetDate(format(newDate, "yyyy-MM-dd"));
      setTourGroupsDate(format(newDate, "yyyy-MM-dd"));
      refetchSchedule();
      handleInvalidateTourGroupsForSchedule();
    }
  };

  //------------------------------------------------------------------------

  useEffect(() => {
    const local_user_ids = localStorage.getItem("user_ids");
    if (local_user_ids) {
      setUser_ids(JSON.parse(local_user_ids));
    }
  }, []);

  //------------------------------------------------------------------------

  return (
    <Context.Provider
      value={{
        tourGroupsDate,
        setTourGroupsDate,
      }}
    >
      <Container>
        <SelectionBar>
          <SchedulePlannerBarContent
            date={targetDate}
            setDate={(date: string) => {
              setTargetDate(format(startOfMonth(new Date(date)), "yyyy-MM-dd"));
            }}
            handleDateChange={handleDateChange}
            refetch={() => {
              refetchSchedule();
              handleInvalidateTourGroupsForSchedule();
            }}
          />
        </SelectionBar>
        <ContentContainer>
          <SchedulePlannerGrid targetDate={targetDate} />
          <TourGroupsList />
        </ContentContainer>
      </Container>
    </Context.Provider>
  );
};

export default SchedulePlanner;
