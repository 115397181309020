import styled from "styled-components"
import { useNavigate } from "react-router-dom";
import tour_guide_img from "./tour-guide.png";

//------------------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const FlexContainer = styled.div`
  flex: 1;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  padding: 10px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  @media (min-width: 500px) {
    flex-direction: row;
  }
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const ItemBottomLine = styled.div`
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: 0; // Initially set to 0
  height: 5px;
  background: #90a4ae;
  border-radius: 0 0 5px 5px;
  transition: width 0.3s, opacity 0.8s; // Add transition for width and opacity
`;

const Item = styled.div`
  user-select: none;
  flex: 1;
  height: 180px;
  min-height: 180px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  border-radius: 5px;
  background: #fafcfc;
  //box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.2);

  span {
    font-weight: 600;
    font-size: 15px;
    color: #393939;
  }
  &:hover {
    cursor: pointer;
    i {
      color: ${({ theme }) => theme.colors.blue};
    }

    img {
      filter: sepia(1) saturate(30) hue-rotate(80deg) hue-rotate(80deg)
        contrast(0.6);
    }

    ${ItemBottomLine} {
      opacity: 1;
      width: 100%; // Set width to 100% on hover
    }
  }
  i {
    color: #6d6d6d;
    font-size: 40px;
  }

  img {
    filter: brightness(0.43);
    width: 50px;
  }
`;

//------------------------------------------------------------------------

const HomeContent = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <FlexContainer>
        <ContentContainer>
          <Row>
            <Item
              onClick={() => {
                navigate("/bookings_manager");
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className="fa-solid fa-calendar"></i>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Bookings Manager</span>
              </div>
              <ItemBottomLine />
            </Item>
            <Item
              onClick={() => {
                navigate("/day_planner");
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className="fa-solid fa-calendar-check"></i>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Day Planner</span>
              </div>
              <ItemBottomLine />
            </Item>
          </Row>
          <Row>
            <Item
              onClick={() => {
                navigate("/tour_guides_planner");
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={tour_guide_img} alt="tour guide icon" />
                {/* <i className="fa-solid fa-person-hiking"></i> */}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Tour Guides Assignments</span>
              </div>
              <ItemBottomLine />
            </Item>
            <Item
              onClick={() => {
                navigate("/schedule_planner");
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className="fa-solid fa-user-group"></i>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Staff Schedule</span>
              </div>
              <ItemBottomLine />
            </Item>
          </Row>
          <Row>
            <Item
              onClick={() => {
                navigate("/fleet");
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className="fa-solid fa-van-shuttle"></i>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Fleet Management</span>
              </div>
              <ItemBottomLine />
            </Item>
            <Item
              onClick={() => {
                navigate("/announcements");
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className="fa-solid fa-bullhorn"></i>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Announcements</span>
              </div>
              <ItemBottomLine />
            </Item>
          </Row>
          <Row>
            <Item
              onClick={() => {
                //toast.dismiss();
                //toast.error("Temporarily unavailable");
                navigate("/booking_portal_logs");
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className="fa-solid fa-mobile-screen-button"></i>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Booking Portal Logs</span>
              </div>
              <ItemBottomLine />
            </Item>
            <Item
              onClick={() => {
                navigate("/settings");
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className="fa-solid fa-sliders"></i>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Settings</span>
              </div>
              <ItemBottomLine />
            </Item>
          </Row>
        </ContentContainer>
      </FlexContainer>
    </Container>
  );
};

export default HomeContent
