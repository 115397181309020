// src/components/AddUsersModal/components/ToggleField.tsx
import React from "react";
import { useField } from "formik";
import Switch from "@mui/material/Switch";
import { ToggleWrapper } from "../styles";

interface ToggleFieldProps {
  name: string;
  label: string;
  disabled?: boolean;
}

const ToggleField = ({ name, label, disabled = false }: ToggleFieldProps) => {
  const [field, , { setValue }] = useField(name);

  return (
    <ToggleWrapper>
      <Switch
        id={name}
        checked={field.value}
        onChange={(e) => setValue(e.target.checked)}
        disabled={disabled}
        size="small"
        color="success"
      />
      <label htmlFor={name}>{label}</label>
    </ToggleWrapper>
  );
};

export default ToggleField;
