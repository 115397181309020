import styled from "styled-components";
import { Formik } from "formik";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // Import the styles
import { useUpdateUserNotificationsPreferences } from "../../../../../reactQueryHooks";
import { useAxios } from "../../../../../axiosProvider";
import { useQueryClient } from "react-query";

//----------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex: 1 0 450px;
  max-height: 500px;
  flex-direction: column;
  gap: 7px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 7px;
  border-radius: 5px;
  user-select: none;
  width: 100%;
  height: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  //font-weight: bold;
  color: rgb(35, 139, 243);
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 7px;
  padding: 0 7px 0 0;
`;

const Switch = styled.div`
  padding: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: rgb(248 248 248);
  gap: 5px;
  font-size: 13px;
`;

//------------------------------------------------------------------------

const NotificationsOptions = () => {
  const { user, setUser } = useAxios();
  const {
    isLoading: isUpdating,
    mutateAsync: updateUserNotificationsPreferences,
  } = useUpdateUserNotificationsPreferences();

  //------------------------------------------------------------------------------------------
  const queryClient = useQueryClient();

  return (
    <Container>
      <TitleContainer>Notifications</TitleContainer>
      <Formik
        //enableReinitialize
        initialValues={{
          new_booking:
            user.web_app_user_preferences.notifications.shown.new_booking,
          booking_changed_date:
            user.web_app_user_preferences.notifications.shown
              .booking_changed_date,
          booking_cancelled:
            user.web_app_user_preferences.notifications.shown.booking_cancelled,
          client_confirmed:
            user.web_app_user_preferences.notifications.shown.client_confirmed,
          client_updated_location:
            user.web_app_user_preferences.notifications.shown
              .client_updated_location,
          booking_updated:
            user.web_app_user_preferences.notifications.shown.booking_updated,
          booking_updated_by_partner:
            user.web_app_user_preferences.notifications.shown
              .booking_updated_by_partner,
        }}
        onSubmit={async (values) => {
          try {
            const updatedUser = await updateUserNotificationsPreferences({
              user_id: user._id,
              values,
            });
            setUser(updatedUser.data);
            await queryClient.invalidateQueries("READ_NOTIFICATIONS");
            await queryClient.invalidateQueries("UNREAD_NOTIFICATIONS");
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
          setSubmitting,
          isSubmitting,
          validateForm,
        }) => {
          return (
            <ContentContainer>
              <Switch>
                New bookings
                <Toggle
                  checked={values.new_booking}
                  disabled={isUpdating}
                  onChange={(value) => {
                    setFieldValue("new_booking", value.target.checked);
                    handleSubmit();
                  }}
                />
              </Switch>
              <Switch>
                Booking updates
                <Toggle
                  checked={values.booking_changed_date}
                  disabled={isUpdating}
                  onChange={(value) => {
                    setFieldValue("booking_changed_date", value.target.checked);
                    handleSubmit();
                  }}
                />
              </Switch>
              <Switch>
                Booking cancellations
                <Toggle
                  checked={values.booking_cancelled}
                  disabled={isUpdating}
                  onChange={(value) => {
                    setFieldValue("booking_cancelled", value.target.checked);
                    handleSubmit();
                  }}
                />
              </Switch>
              <Switch>
                Client confirmations
                <Toggle
                  checked={values.client_confirmed}
                  disabled={isUpdating}
                  onChange={(value) => {
                    setFieldValue("client_confirmed", value.target.checked);
                    handleSubmit();
                  }}
                />
              </Switch>
              <Switch>
                Booking updates by reservation systems
                <Toggle
                  checked={values.booking_updated}
                  disabled={isUpdating}
                  onChange={(value) => {
                    setFieldValue("booking_updated", value.target.checked);
                    handleSubmit();
                  }}
                />
              </Switch>
              <Switch>
                Booking updates by partner portal
                <Toggle
                  checked={values.booking_updated_by_partner}
                  disabled={isUpdating}
                  onChange={(value) => {
                    setFieldValue(
                      "booking_updated_by_partner",
                      value.target.checked
                    );
                    handleSubmit();
                  }}
                />
              </Switch>
              <Switch>
                Client location updates
                <Toggle
                  checked={values.client_updated_location}
                  disabled={isUpdating}
                  onChange={(value) => {
                    setFieldValue(
                      "client_updated_location",
                      value.target.checked
                    );
                    handleSubmit();
                  }}
                />
              </Switch>
            </ContentContainer>
          );
        }}
      </Formik>
    </Container>
  );
};

export default NotificationsOptions;
