// RoleItem.tsx
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import PersonIcon from "@mui/icons-material/Person";
import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useFetchEntities } from "@/reactQueryHooks";

interface RoleItemProps {
  date: string;
  roleId: string;
  tourGroupId: string;
  isAssigned: boolean;
}

//----------------------------------

const DragHandle = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  opacity: 0.5;

  &:hover {
    opacity: 0.8;
  }

  svg {
    font-size: 16px;
  }
`;

const RoleItemContainer = styled.div<{ isAssigned: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border-radius: 4px;
  margin: 2px 0;
  font-size: 12px;
  background-color: ${(props) => (props.isAssigned ? "#E3FCEF" : "#FFF0E3")};
  border: 1px solid ${(props) => (props.isAssigned ? "#57D9A3" : "#FFB84D")};
  cursor: grab;
  user-select: none;

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    ${DragHandle} {
      //transform: scale(1.1);
      color: #007bff;
    }
  }

  &:active {
    cursor: grabbing;
    opacity: 0.8;
  }
`;



const RoleName = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AssignedIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
  color: #57d9a3;

  svg {
    font-size: 14px;
  }
`;

const RoleItem = ({ date, roleId, tourGroupId, isAssigned }: RoleItemProps) => {
  const { data: entities } = useFetchEntities() as any;
  const roles = entities.data.roles;
  const role = roles.find((role: any) => role._id === roleId);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    return draggable({
      element,
      getInitialData: () => ({ date, tourGroupId, roleId }),
    });
  }, [date, tourGroupId, roleId]);

  return (
    <RoleItemContainer isAssigned={isAssigned} ref={containerRef}>
      <DragHandle>
        <DragIndicatorIcon />
      </DragHandle>
      <RoleName>{role?.title}</RoleName>
      {isAssigned && (
        <AssignedIcon>
          <PersonIcon />
        </AssignedIcon>
      )}
    </RoleItemContainer>
  );
};

export default RoleItem;
