import { useContext, useEffect } from "react";
import { Context } from "..";
import styled from "styled-components";
import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import {
  SectionContainer,
  SectionTitle,
  EmptyMessage,
  Badge,
  colors,
} from "../styles";
import {
  useFetchEntities,
  useGetUserDaySchedulesThatIncludesTourGroup,
  useRemoveTourGroupAssignment,
} from "@/reactQueryHooks";

//-----------------------------------------------

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProductItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-radius: 8px;
  background: ${colors.assigned};
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  &:hover {
    background: ${colors.light};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  }
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  span {
    color: ${colors.textPrimary};
  }
`;

const RemoveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background: rgba(247, 37, 133, 0.1);
  margin-left: 8px;
  color: ${colors.danger};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(247, 37, 133, 0.2);
    transform: rotate(90deg);
  }
`;

const AssignedSchedule = ({ tourGroup }) => {
  const queryClient = useQueryClient();
  const { setIsUpdating } = useContext(Context);
  const { data: entities } = useFetchEntities();
  const { data: assignees } = useGetUserDaySchedulesThatIncludesTourGroup(
    tourGroup._id
  );

  const assignedSchedules = assignees?.filter(
    (schedule) => schedule?.projected === false
  );

  const hasAssignedSchedules =
    assignedSchedules && assignedSchedules.length > 0;

  const { mutateAsync: removeTourGroupAssignment, isLoading } =
    useRemoveTourGroupAssignment();

  //------------------------------------------------

  useEffect(() => {
    setIsUpdating(isLoading);
  }, [isLoading, setIsUpdating]);

  //------------------------------------------------

  return (
    <SectionContainer>
      <SectionTitle>
        <i className="fas fa-clipboard-list"></i>
        Assignments
      </SectionTitle>
      {!hasAssignedSchedules && (
        <EmptyMessage>No schedule assigned</EmptyMessage>
      )}
      {hasAssignedSchedules && (
        <ProductList>
          {assignedSchedules.map((schedule, index) => {
            const user = entities?.data?.staff?.find(
              (staff) => staff._id === schedule?.id || null
            );
            const role = entities?.data?.roles?.find(
              (crewRole) => crewRole._id === schedule?.role || null
            );

            return (
              <ProductItemContainer key={uuidv4()}>
                <ProductInfo>
                  <span>{user?.name}</span>
                  <Badge>{role?.title}</Badge>
                </ProductInfo>
                <RemoveButton
                  onClick={async () => {
                    if (window.confirm("Remove assignment?")) {
                      await removeTourGroupAssignment({
                        schedule_id: schedule.schedule_id,
                        tour_group_id: tourGroup._id,
                      });

                      await queryClient.invalidateQueries({
                        queryKey: [
                          "USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP",
                          tourGroup._id,
                        ],
                      });
                    }
                  }}
                >
                  <i className="fa-solid fa-times"></i>
                </RemoveButton>
              </ProductItemContainer>
            );
          })}
        </ProductList>
      )}
    </SectionContainer>
  );
};

export default AssignedSchedule;
