import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
import {
  TextField,
  Button,
  IconButton,
  MenuItem,
  Chip,
  CircularProgress,
  Typography,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SortIcon from "@mui/icons-material/Sort";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import {
  useFetchEntities,
  useAddTask,
  useEditTask,
  useDeleteTask,
  useTriggerRefreshTasksInTourGroup,
} from "../../../reactQueryHooks";
import PickupsList from "./PickupsList";
import { useAxios } from "../../../axiosProvider";

// Types
interface Author {
  _id: string;
  name: string;
  isModerator: boolean;
  isAdmin: boolean;
}

interface Vehicle {
  _id: string;
  plate: string;
}

interface MeetingPoint {
  _id: string;
  name: string;
  latitude: number;
  longitude: number;
}

interface Role {
  _id: string;
  title: string;
}

interface Staff {
  _id: string;
  name: string;
  roles: { _id: string }[];
  isModerator?: boolean; // Add these properties to match the filter
  isAdmin?: boolean; // Add these properties to match the filter
}

interface Option {
  _id: string;
  title: string;
}

interface Product {
  _id: string;
  title: string;
  options: Option[];
  crewRoles: string[];
}

interface EntitiesData {
  staff: Staff[];
  roles: Role[];
  products: Product[];
  vehicles: Vehicle[];
  meetingPoints: MeetingPoint[];
}

interface Entities {
  data: EntitiesData;
}

interface Assignee {
  user_id: string;
  role_id: string;
}

interface Guest {
  name: string;
  count: string | number;
}

interface Pickup {
  meeting_point: string;
  time: string;
  details: string;
  lat: number | null;
  lon: number | null;
  guests: Guest[];
}

interface TaskData {
  id?: string;
  date: string;
  product: string;
  option_id: string;
  assignees?: Assignee[];
  vehicle_id?: string | null;
  pickups?: Pickup[];
  details?: string | null;
  author_id?: string;
  tour_group_id: string;
}

interface TaskFormProps {
  isNew: boolean;
  data: TaskData;
  refetch: () => void;
  closeForm: () => void;
  instanceId: string;
  tourGroupId: string;
}

interface TaskFormValues {
  date: string;
  product: string;
  option_id: string;
  assignees: Assignee[];
  vehicle_id?: string | null;
  pickups: Pickup[];
  details?: string | null;
  silent: boolean;
  author_id: string;
  tour_group_id: string;
}

// Validation Schemas
const yupValidationSchema = Yup.object().shape({
  product: Yup.string().required("Product is required"),
  date: Yup.date().required("Date is required"),
  assignees: Yup.array().of(
    Yup.object().shape({
      user_id: Yup.string(),
      role_id: Yup.string(),
    })
  ),
  vehicle_id: Yup.string().nullable(),
  pickups: Yup.array().of(
    Yup.object().shape({
      meeting_point: Yup.string(),
      time: Yup.string(),
      details: Yup.string(),
      lat: Yup.number().typeError("Please validate all meeting points"),
      lon: Yup.number().typeError("Please validate all meeting points"),
      guests: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          count: Yup.number(),
        })
      ),
    })
  ),
  details: Yup.string().nullable(),
  author_id: Yup.string().required("Author is required"),
  silent: Yup.boolean(),
});

const formikValidationSchema = (values: any) => {
  const errors: Record<string, string> = {};

  const latLongAreEmpty =
    !values.pickup_location_lat && !values.pickup_location_lng;

  if (values.pickup_location_name && latLongAreEmpty) {
    errors.pickup_location_lat =
      "Pickup location is not validated, please search the location again";
    errors.pickup_location_lng =
      "Pickup location is not validated, please search the location again";
  }

  return errors;
};

// Helper function for error extraction
const recursivelyExtractErrorMessages = (errors: any): string[] => {
  let messages: string[] = [];

  for (const [_, value] of Object.entries(errors)) {
    if (typeof value === "object" && value !== null) {
      messages = messages.concat(recursivelyExtractErrorMessages(value));
    } else {
      messages.push(`${value}`);
    }
  }

  // Remove duplicate messages - use Array.from instead of spread to fix TS2802
  const messagesUnique = Array.from(new Set(messages));
  return messagesUnique;
};

// Styled Components
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  padding: 24px;
  max-width: 900px;
  max-height: 90vh;
  overflow: hidden;

  * {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`;

const Form = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 8px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const SectionTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #1976d2;
`;

const FieldRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 12px;
`;

const FieldColumn = styled.div<{ flex?: number }>`
  flex: ${(props) => props.flex || 1};
  min-width: 200px;

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 12px;
`;

const ButtonGroup = styled.div`
  display: flex;

  gap: 12px;
  flex: 1;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 80px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #1976d2;
    box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
  }
`;

const PickupsContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
`;

const PickupsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const PickupControls = styled.div`
  display: flex;
  gap: 8px;
`;

// Main component
const ScheduleTaskForm: React.FC<TaskFormProps> = ({
  isNew,
  data,
  refetch,
  closeForm,
  instanceId,
  tourGroupId,
}) => {
  const { mutateAsync: triggerRefreshTasksInTourGroup } =
    useTriggerRefreshTasksInTourGroup();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSortingPickups, setIsSortingPickups] = useState<boolean>(false);
  const { user } = useAxios();

  // Create a default empty entities object to avoid TypeScript errors
  const defaultEntitiesData: EntitiesData = {
    staff: [],
    roles: [],
    products: [],
    vehicles: [],
    meetingPoints: [],
  };

  // Use the default entities data
  const {
    data: entitiesResponse = { data: defaultEntitiesData },
    isFetching: isFetchingEntities,
  } = useFetchEntities();
  const entities = entitiesResponse as Entities;

  // Now entities is properly typed with Staff that includes isModerator and isAdmin
  // Cast the filtered staff to Author[] to ensure type compatibility
  const authors = (entities.data.staff || [])
    .filter((staff: Staff) => staff.isModerator || staff.isAdmin)
    .map(
      (staff: Staff): Author => ({
        _id: staff._id,
        name: staff.name,
        isModerator: !!staff.isModerator, // Convert to boolean
        isAdmin: !!staff.isAdmin, // Convert to boolean
      })
    );

  const { mutateAsync: addTask } = useAddTask();
  const { mutateAsync: editTask } = useEditTask();
  const { mutateAsync: deleteTask } = useDeleteTask();

  // Task management functions
  const handleAddNewTask = async (values: TaskFormValues) => {
    // Create a new object instead of modifying the original
    const taskData: Partial<TaskFormValues> = { ...values };

    if (taskData.vehicle_id === null) {
      taskData.vehicle_id = undefined; // Set to undefined instead of using delete
    }

    if (taskData.details === null) {
      taskData.details = undefined; // Set to undefined instead of using delete
    }

    try {
      setIsLoading(true);
      await addTask(taskData as unknown as void);

      await triggerRefreshTasksInTourGroup({
        instance_id: instanceId,
        tour_group_id: tourGroupId,
      } as unknown as void);

      refetch();
      setIsLoading(false);
      closeForm();
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);
      closeForm();
      if (err.response?.data) {
        alert(err.response.data);
      } else {
        alert(err.toString());
      }
    }
  };

  const handleEditTask = async (id: string, values: TaskFormValues) => {
    // Create a new object instead of modifying the original
    const taskData: Partial<TaskFormValues> = { ...values };

    if (taskData.vehicle_id === null) {
      taskData.vehicle_id = undefined; // Set to undefined instead of using delete
    }

    if (taskData.details === null) {
      taskData.details = undefined; // Set to undefined instead of using delete
    }

    try {
      setIsLoading(true);

      await editTask({
        id,
        payload: taskData,
      } as unknown as void);

      await triggerRefreshTasksInTourGroup({
        instance_id: instanceId,
        tour_group_id: tourGroupId,
      } as unknown as void);

      refetch();
      closeForm();
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);
      closeForm();
      if (err.response?.data) {
        alert(err.response.data);
      } else {
        alert(err.toString());
      }
    }
  };

  const handleDeleteTask = async (id: string) => {
    try {
      setIsLoading(true);
      await deleteTask(id as unknown as void);

      await triggerRefreshTasksInTourGroup({
        instance_id: instanceId,
        tour_group_id: tourGroupId,
      } as unknown as void);

      refetch();
      closeForm();
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);
      closeForm();
      if (err.response?.data) {
        alert(err.response.data);
      } else {
        alert(err.toString());
      }
    }
  };

  if (isLoading || isFetchingEntities) {
    return (
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Container>
          <SpinnerContainer>
            <CircularProgress />
          </SpinnerContainer>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Container>
        <HeaderContainer>
          <Typography variant="h5" fontWeight={500}>
            {isNew ? "Create New Task" : "Edit Task"}
          </Typography>
          <IconButton onClick={closeForm} color="primary">
            <CloseIcon />
          </IconButton>
        </HeaderContainer>

        <FormContainer>
          <Formik
            validateOnMount
            initialValues={{
              date: data.date,
              product: data.product,
              option_id: data.option_id,
              assignees: data ? (data.assignees ? data.assignees : []) : [],
              vehicle_id: data
                ? data.vehicle_id
                  ? data.vehicle_id
                  : null
                : null,
              pickups: data ? (data.pickups ? data.pickups : []) : [],
              details: data ? (data.details ? data.details : null) : null,
              silent: true,
              author_id: data?.author_id || user._id,
              tour_group_id: data.tour_group_id,
            }}
            validate={formikValidationSchema}
            validationSchema={yupValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (isNew) {
                if (window.confirm("Submit new task?")) {
                  handleAddNewTask(values);
                }
              } else {
                if (window.confirm("Update this task?")) {
                  handleEditTask(data.id!, values);
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              resetForm,
              isSubmitting,
              validateForm,
            }) => {
              // Build data for crew selection
              const product = entities.data.products?.find(
                (p: Product) => p._id === values.product
              );

              const option = product?.options?.find(
                (o: Option) => o._id === data.option_id
              );

              const crewData =
                product?.crewRoles?.map((roleId: string) => {
                  const role = entities.data.roles?.find(
                    (r: Role) => r._id === roleId
                  );

                  return {
                    section: {
                      label: role?.title || "",
                      value: roleId,
                    },
                    data: entities.data.staff
                      ?.filter((user: Staff) =>
                        user.roles.find((r) => r._id === roleId)
                      )
                      .map((user: Staff) => ({
                        label: user.name,
                        value: user._id,
                      })),
                  };
                }) || [];

              // Pickup stats
              const totalPickups = values.pickups.length;
              const shouldShowPickupsList = totalPickups > 0;
              const shouldShowSortingButton = totalPickups > 1;
              const totalGuests = values.pickups.reduce((acc, pickup) => {
                return (
                  acc +
                  pickup.guests.reduce((acc, guest) => {
                    return acc + Number(guest.count || 0);
                  }, 0)
                );
              }, 0);

              // Handler for reset form button - fix TS2769
              const handleResetForm = () => {
                resetForm();
              };

              // Handler for notification toggle - fix TS2769
              const handleNotificationToggle = () => {
                setFieldValue("silent", !values.silent);
              };

              return (
                <>
                  <Form>
                    <SectionWrapper>
                      <SectionHeader>
                        <SectionTitle>Task Information</SectionTitle>
                      </SectionHeader>

                      <FieldRow>
                        <FieldColumn>
                          <Typography variant="subtitle2" gutterBottom>
                            Product
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={product?.title || ""}
                            disabled
                          />
                        </FieldColumn>

                        <FieldColumn>
                          <Typography variant="subtitle2" gutterBottom>
                            Product Option
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={option?.title || ""}
                            disabled
                          />
                        </FieldColumn>

                        <FieldColumn>
                          <Typography variant="subtitle2" gutterBottom>
                            Event Date
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={moment(values.date).format(
                              "ddd, DD MMM YYYY"
                            )}
                            disabled
                          />
                        </FieldColumn>
                      </FieldRow>

                      <FieldRow>
                        <FieldColumn>
                          <Typography variant="subtitle2" gutterBottom>
                            Author
                          </Typography>
                          <TextField
                            select
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={values.author_id || "reset"}
                            onChange={(e) => {
                              if (e.target.value === "reset") {
                                setFieldValue("author_id", "");
                              } else {
                                setFieldValue("author_id", e.target.value);
                              }
                            }}
                          >
                            <MenuItem value="reset">
                              Select task author
                            </MenuItem>
                            {authors.map((author) => (
                              <MenuItem value={author._id} key={author._id}>
                                {author.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FieldColumn>

                        <FieldColumn>
                          <Typography variant="subtitle2" gutterBottom>
                            Vehicle
                          </Typography>
                          <TextField
                            select
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={values.vehicle_id || "reset"}
                            onChange={(e) => {
                              if (e.target.value === "reset") {
                                setFieldValue("vehicle_id", null);
                              } else {
                                setFieldValue("vehicle_id", e.target.value);
                              }
                            }}
                          >
                            <MenuItem value="reset">Select a vehicle</MenuItem>
                            {entities.data.vehicles?.map((vehicle: Vehicle) => (
                              <MenuItem value={vehicle._id} key={vehicle._id}>
                                {vehicle.plate}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FieldColumn>
                      </FieldRow>

                      <FieldRow>
                        <FieldColumn flex={1}>
                          <Typography variant="subtitle2" gutterBottom>
                            Crew Assignment
                          </Typography>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns:
                                "repeat(auto-fill, minmax(280px, 1fr))",
                              gap: "16px",
                            }}
                          >
                            {crewData.map((roleGroup, index) => (
                              <div key={`role-${roleGroup.section.value}`}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  sx={{
                                    fontWeight: 500,
                                    display: "block",
                                    mb: 0.5,
                                  }}
                                >
                                  {roleGroup.section.label}
                                </Typography>
                                <Autocomplete
                                  multiple
                                  size="small"
                                  options={roleGroup.data || []}
                                  getOptionLabel={(option) => option.label}
                                  value={(roleGroup.data || []).filter(
                                    (staff) =>
                                      values.assignees?.some(
                                        (assignee) =>
                                          assignee.user_id === staff.value &&
                                          assignee.role_id ===
                                            roleGroup.section.value
                                      )
                                  )}
                                  onChange={(event, newValue) => {
                                    // Get current assignees for this role
                                    const currentRoleAssignees =
                                      values.assignees?.filter(
                                        (a) =>
                                          a.role_id !== roleGroup.section.value
                                      ) || [];

                                    // Add the new selected users for this role
                                    const newRoleAssignees = newValue.map(
                                      (user) => ({
                                        user_id: user.value,
                                        role_id: roleGroup.section.value,
                                      })
                                    );

                                    // Update form with combined assignees
                                    setFieldValue("assignees", [
                                      ...currentRoleAssignees,
                                      ...newRoleAssignees,
                                    ]);
                                  }}
                                  renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                      <Chip
                                        label={option.label}
                                        size="small"
                                        {...getTagProps({ index })}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      placeholder={`Select ${roleGroup.section.label}`}
                                    />
                                  )}
                                />
                              </div>
                            ))}
                          </div>
                        </FieldColumn>
                      </FieldRow>

                      <FieldRow>
                        <FieldColumn flex={1}>
                          <Typography variant="subtitle2" gutterBottom>
                            Details
                          </Typography>
                          <TextArea
                            placeholder="Add task details here..."
                            name="details"
                            value={values.details || ""}
                            onChange={handleChange}
                          />
                        </FieldColumn>
                      </FieldRow>
                    </SectionWrapper>

                    <SectionWrapper>
                      <SectionHeader>
                        <SectionTitle>Pickups</SectionTitle>
                      </SectionHeader>

                      <PickupsContainer>
                        <PickupsHeader>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <Typography variant="subtitle1">
                              Pickups: {totalPickups}
                            </Typography>

                            {totalGuests > 0 && (
                              <Chip
                                label={`${totalGuests} passengers`}
                                size="small"
                                color="primary"
                              />
                            )}
                          </div>

                          <PickupControls>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              startIcon={<AddIcon />}
                              onClick={() => {
                                setFieldValue("pickups", [
                                  ...values.pickups,
                                  {
                                    meeting_point: "",
                                    time: "",
                                    details: "",
                                    lat: null,
                                    lon: null,
                                    guests: [
                                      {
                                        name: "",
                                        count: "",
                                      },
                                    ],
                                  },
                                ]);
                                setIsSortingPickups(false);
                              }}
                            >
                              Add Pickup
                            </Button>

                            {shouldShowSortingButton && (
                              <Button
                                variant="outlined"
                                size="small"
                                startIcon={<SortIcon />}
                                onClick={() =>
                                  setIsSortingPickups(!isSortingPickups)
                                }
                              >
                                Sort
                              </Button>
                            )}
                          </PickupControls>
                        </PickupsHeader>

                        {shouldShowPickupsList && (
                          <PickupsList
                            meetingPoints={entities.data.meetingPoints || []}
                            isSortingPickups={isSortingPickups}
                            setIsSortingPickups={setIsSortingPickups}
                          />
                        )}
                      </PickupsContainer>
                    </SectionWrapper>
                  </Form>

                  <ButtonsContainer>
                    <ButtonGroup
                      style={{
                        justifyContent: "flex-start",
                      }}
                    >
                      {!isNew && (
                        <Button
                          variant="contained"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this task?"
                              )
                            ) {
                              handleDeleteTask(data.id!);
                            }
                          }}
                        >
                          Delete
                        </Button>
                      )}

                      {/* Fix TS2769: Use onClick with proper type */}
                      <Button
                        variant="outlined"
                        startIcon={<RestartAltIcon />}
                        onClick={handleResetForm}
                      >
                        Reset Form
                      </Button>
                    </ButtonGroup>

                    <ButtonGroup
                      style={{
                        justifyContent: "flex-end",
                      }}
                    >
                      {!isNew && (
                        <Button
                          variant="outlined"
                          color={values.silent ? "inherit" : "primary"}
                          startIcon={
                            values.silent ? (
                              <NotificationsOffIcon />
                            ) : (
                              <NotificationsIcon />
                            )
                          }
                          onClick={handleNotificationToggle}
                        >
                          Notifications
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          const itHasErrors = Object.keys(errors).length > 0;
                          if (itHasErrors) {
                            const messages =
                              recursivelyExtractErrorMessages(errors);
                            alert(messages.join(", "));
                            console.log("HAS ERRORS", values);
                          } else {
                            handleSubmit();
                          }
                        }}
                      >
                        {isNew ? "Submit" : "Update"}
                      </Button>
                    </ButtonGroup>
                  </ButtonsContainer>
                </>
              );
            }}
          </Formik>
        </FormContainer>
      </Container>
    </Wrapper>
  );
};

export default ScheduleTaskForm;
