import styled from "styled-components"
import { list } from "./FormContainer"
import { useFormContext } from "react-hook-form"
import ListItem from "./ListItem"

//---------------------------------------------------------------------------

const ItemListContainer = styled.div`
  background-color: white;
  padding: 15px 15px 15px 10px;
  border-radius: 10px;
  height: 100%;
  width: 30%;
  max-width: 300px;
  overflow: hidden;
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: 100%;
  overflow-y: auto;
`

//---------------------------------------------------------------------------

const ItemsList = ({
  step,
  setStep,
}: {
  step: number;
  setStep: (step: number) => void;
}) => {
  const { trigger } = useFormContext();

  return (
    <ItemListContainer>
      <ListContainer>
        {list.map((item, index) => {
          const isCurrentStep = step === index + 1;
          return (
            <ListItem
              key={index}
              index={index}
              title={item.title}
              fieldName={item.field_name}
              step={step}
              onClick={async () => {
                if (!isCurrentStep) {
                  const hasBokunCode = await trigger("bokun_product_code");
                  if (!hasBokunCode) {
                    alert("You have to provide a Bokun ID first");
                    return;
                  } else {
                    setStep(index + 1);
                  }
                }
              }}
            />
          );
        })}
      </ListContainer>
    </ItemListContainer>
  );
};
export default ItemsList
