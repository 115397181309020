// src/components/AddUsersModal/sections/GroupsAndRolesSection.tsx
import React from "react";
import { GroupOption, RoleOption } from "../types";
import {
  Section,
  SectionTitle,
  FormRow,
  FormGroup,
  FormLabel,
} from "../styles";
import SearchableSelect from "../components/SearchableSelect";

interface GroupsAndRolesSectionProps {
  groupsOptions: GroupOption[];
  rolesOptions: RoleOption[];
  userGroups: GroupOption[];
  userRoles: RoleOption[];
  onGroupsChange: (selectedGroups: string[]) => void;
  onRolesChange: (selectedRoles: string[]) => void;
}

const GroupsAndRolesSection = ({
  groupsOptions,
  rolesOptions,
  userGroups,
  userRoles,
  onGroupsChange,
  onRolesChange,
}: GroupsAndRolesSectionProps) => {
  return (
    <Section>
      <SectionTitle>Groups & Roles</SectionTitle>
      <FormRow>
        <FormGroup>
          <FormLabel>User Groups</FormLabel>
          <SearchableSelect
            options={groupsOptions}
            selectedOptions={userGroups}
            onChange={(selected) => {
              const selectedValues = selected.map((option) => option.value);
              onGroupsChange(selectedValues);
            }}
            placeholder="Select user groups"
            isMulti
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>User Roles</FormLabel>
          <SearchableSelect
            options={rolesOptions}
            selectedOptions={userRoles}
            onChange={(selected) => {
              const selectedValues = selected.map((option) => option.value);
              onRolesChange(selectedValues);
            }}
            placeholder="Select user roles"
            isMulti
          />
        </FormGroup>
      </FormRow>
    </Section>
  );
};

export default GroupsAndRolesSection;
