import React, { useState, useEffect, useContext, memo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import {
  TextField,
  Button,
  IconButton,
  MenuItem,
  Tooltip,
  Chip,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SearchIcon from "@mui/icons-material/Search";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../App";

// Types
interface Guest {
  name: string;
  count: string | number;
}

interface Pickup {
  meeting_point: string;
  time: string;
  details: string;
  lat: number | null;
  lon: number | null;
  guests: Guest[];
}

interface MeetingPoint {
  _id: string;
  name: string;
  latitude: number;
  longitude: number;
}

interface PickupItemProps {
  pickup: Pickup;
  setPickup: (pickup: Pickup) => void;
  meetingPoints: MeetingPoint[];
  deletePickup: () => void;
  isSortingPickups: boolean;
}

// Styled Components
const PickupWrapper = styled(motion.div)<{
  isSorting: boolean;
}>`
  position: relative;
  margin-bottom: 16px;
  user-select: ${(props) => (props.isSorting ? "none" : "auto")};
`;

const PickupCard = styled.div<{ isValid: boolean }>`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 16px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border-left: 4px solid ${(props) => (props.isValid ? "#4caf50" : "#f44336")};
`;

const SortingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  cursor: grab;
  backdrop-filter: blur(2px);
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FormRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormColumn = styled.div<{ grow?: number }>`
  flex: ${(props) => props.grow || 1};
  min-width: ${(props) => (props.grow ? "0" : "200px")};

  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;

const GuestSection = styled.div`
  margin-top: 16px;
`;

const GuestSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const GuestSectionTitle = styled.div`
  font-size: 14px;
  color: #666;
  font-weight: 500;
`;

const GuestCard = styled.div`
  margin-bottom: 8px;
`;

const GuestRow = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const GuestNameField = styled.div`
  flex: 3;
`;

const GuestCountField = styled.div`
  flex: 1;
  min-width: 100px;
`;

const GuestActionField = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
`;

// Variants for Framer Motion
const containerVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  exit: { opacity: 0, y: -20, transition: { duration: 0.2 } },
};

const PickupItem: React.FC<PickupItemProps> = memo(
  ({ pickup, setPickup, meetingPoints, deletePickup, isSortingPickups }) => {
    const [isPickupLocationFindLoading, setIsPickupLocationFindLoading] =
      useState<boolean>(false);
    const [shouldShowMeetingPointSelect, setShouldShowMeetingPointSelect] =
      useState<boolean>(true);
    const [isPickupLocationValid, setIsPickupLocationValid] =
      useState<boolean>(false);

    const appContext = useContext(AppContext);
    const { googleInstance } = appContext;

    useEffect(() => {
      if (pickup.meeting_point) {
        setShouldShowMeetingPointSelect(false);
      } else {
        setShouldShowMeetingPointSelect(true);
      }

      if (pickup.meeting_point && pickup.lat && pickup.lon) {
        setIsPickupLocationValid(true);
      } else {
        setIsPickupLocationValid(false);
      }
    }, [pickup.meeting_point, pickup.lat, pickup.lon]);

    const handleFindPickupLocation = async (address: string) => {
      try {
        if (!googleInstance) {
          alert("Google Maps API has trouble loading! Please check logs");
          return;
        }

        setIsPickupLocationFindLoading(true);
        const geocoder = new googleInstance.Geocoder();
        geocoder.geocode({ address }, (results: any, status: string) => {
          if (status === "OK") {
            const location = results[0].geometry.location;
            const lat = location.lat();
            const lng = location.lng();
            const formattedAddress = results[0].formatted_address;

            if (
              window.confirm(
                `Location found!\n\nDo you want to add "${formattedAddress}" as pickup location?\n\nBy clicking cancel, pickup location will be set to "${address}"`
              )
            ) {
              setPickup({
                ...pickup,
                meeting_point: formattedAddress,
                lat: lat,
                lon: lng,
              });
            } else {
              setPickup({
                ...pickup,
                meeting_point: address,
                lat: lat,
                lon: lng,
              });
            }

            setIsPickupLocationFindLoading(false);
          } else {
            setIsPickupLocationFindLoading(false);
            alert(
              "Location not found, try adding PO BOX or a more precise address"
            );
          }
        });
      } catch (error) {
        console.log(error);
        alert(
          error instanceof Error
            ? error.toString()
            : "An unknown error occurred"
        );
      }
    };

    const totalGuests = pickup.guests.reduce((acc, guest) => {
      return acc + (parseInt(guest.count as string) || 0);
    }, 0);

    return (
      <AnimatePresence>
        <PickupWrapper
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          id={JSON.stringify(pickup)}
          isSorting={isSortingPickups}
        >
          <PickupCard isValid={isPickupLocationValid}>
            {isSortingPickups && (
              <SortingOverlay className="sorting-overlay">
                <DragIndicatorIcon fontSize="large" sx={{ color: "#fff" }} />
              </SortingOverlay>
            )}

            <HeaderRow>
              <HeaderTitle>
                <LocationOnIcon color="primary" style={{ marginRight: 8 }} />
                Pickup Details
              </HeaderTitle>

              <HeaderActions>
                {totalGuests > 0 && (
                  <Chip
                    label={`${totalGuests} passengers`}
                    size="small"
                    color="info"
                    style={{ marginRight: 8 }}
                  />
                )}
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={deletePickup}
                  size="small"
                >
                  Remove
                </Button>
              </HeaderActions>
            </HeaderRow>

            <FormRow>
              <FormColumn>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Meeting Point"
                  placeholder="Enter meeting location"
                  value={pickup.meeting_point || ""}
                  onChange={(e) =>
                    setPickup({
                      ...pickup,
                      meeting_point: e.target.value,
                      lat: null,
                      lon: null,
                    })
                  }
                  InputProps={{
                    startAdornment: (
                      <LocationOnIcon
                        color="primary"
                        style={{ marginRight: 8 }}
                      />
                    ),
                    endAdornment: pickup.meeting_point ? (
                      <IconButton
                        size="small"
                        onClick={() =>
                          setPickup({ ...pickup, meeting_point: "" })
                        }
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    ) : null,
                    sx: {
                      backgroundColor: isPickupLocationValid
                        ? "rgba(76, 175, 80, 0.1)"
                        : "rgba(244, 67, 54, 0.05)",
                    },
                  }}
                />
              </FormColumn>

              <FormColumn>
                <FormRow style={{ marginBottom: 0 }}>
                  <FormColumn grow={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Pickup Time"
                      type="time"
                      value={pickup.time}
                      onChange={(e) =>
                        setPickup({ ...pickup, time: e.target.value })
                      }
                      InputProps={{
                        startAdornment: (
                          <AccessTimeIcon
                            color="primary"
                            style={{ marginRight: 8 }}
                          />
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormColumn>

                  <FormColumn grow={3}>
                    {shouldShowMeetingPointSelect ? (
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        label="Saved Locations"
                        value={
                          pickup.meeting_point ? pickup.meeting_point : "reset"
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "reset") {
                            setPickup({
                              ...pickup,
                              meeting_point: "",
                              lat: null,
                              lon: null,
                            });
                          } else {
                            const meetingPoint = meetingPoints.find(
                              (mp) => mp._id === value
                            );
                            if (meetingPoint) {
                              setPickup({
                                ...pickup,
                                meeting_point: meetingPoint.name,
                                lat: meetingPoint.latitude,
                                lon: meetingPoint.longitude,
                              });
                            }
                          }
                        }}
                      >
                        <MenuItem value="reset">
                          Select a meeting point
                        </MenuItem>
                        {meetingPoints.map((meetingPoint, index) => (
                          <MenuItem value={meetingPoint._id} key={index}>
                            {meetingPoint.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      !isPickupLocationValid && (
                        <Tooltip title="Search pickup location">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              if (pickup.meeting_point.length > 9) {
                                handleFindPickupLocation(pickup.meeting_point);
                              } else {
                                alert(
                                  "Pickup location must be at least 10 characters"
                                );
                              }
                            }}
                            disabled={isPickupLocationFindLoading}
                            startIcon={
                              isPickupLocationFindLoading ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <SearchIcon />
                              )
                            }
                          >
                            Find
                          </Button>
                        </Tooltip>
                      )
                    )}
                  </FormColumn>
                </FormRow>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Pickup Details"
                  placeholder="Additional instructions"
                  multiline
                  rows={2}
                  value={pickup.details || ""}
                  onChange={(e) =>
                    setPickup({ ...pickup, details: e.target.value })
                  }
                />
              </FormColumn>
            </FormRow>

            <GuestSection>
              <GuestSectionHeader>
                <GuestSectionTitle>Passengers</GuestSectionTitle>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<PersonAddIcon />}
                  size="small"
                  onClick={() =>
                    setPickup({
                      ...pickup,
                      guests: [...pickup.guests, { name: "", count: "" }],
                    })
                  }
                >
                  Add Passenger
                </Button>
              </GuestSectionHeader>

              {pickup.guests.map((guest, guestIndex) => (
                <GuestCard key={guestIndex}>
                  <GuestRow>
                    <GuestNameField>
                      <TextField
                        fullWidth
                        label="Guest Name"
                        variant="outlined"
                        size="small"
                        value={guest.name}
                        InputProps={{ sx: { textTransform: "capitalize" } }}
                        onChange={(e) => {
                          const newGuests = [...pickup.guests];
                          newGuests[guestIndex].name = e.target.value;
                          setPickup({
                            ...pickup,
                            guests: newGuests,
                          });
                        }}
                      />
                    </GuestNameField>
                    <GuestCountField>
                      <TextField
                        fullWidth
                        label="Pax"
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 20 } }}
                        value={guest.count}
                        onChange={(e) => {
                          const newGuests = [...pickup.guests];
                          newGuests[guestIndex].count = e.target.value;
                          setPickup({
                            ...pickup,
                            guests: newGuests,
                          });
                        }}
                      />
                    </GuestCountField>
                    <GuestActionField>
                      <IconButton
                        color="error"
                        disabled={
                          guestIndex === 0 && pickup.guests.length === 1
                        }
                        onClick={() => {
                          const isOnlyGuest =
                            guestIndex === 0 && pickup.guests.length === 1;
                          if (!isOnlyGuest) {
                            const newGuests = [...pickup.guests];
                            newGuests.splice(guestIndex, 1);
                            setPickup({
                              ...pickup,
                              guests: newGuests,
                            });
                          }
                        }}
                      >
                        <PersonRemoveIcon />
                      </IconButton>
                    </GuestActionField>
                  </GuestRow>
                </GuestCard>
              ))}
            </GuestSection>
          </PickupCard>
        </PickupWrapper>
      </AnimatePresence>
    );
  }
);

export default PickupItem;
