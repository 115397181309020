// CalendarBox.tsx with drop functionality added
import styled from "styled-components";
import { useState, useEffect, useRef, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@mui/material/Tooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import Drawer from "@mui/material/Drawer";
import BlockIcon from "@mui/icons-material/Block";
import moment from "moment";
import { useQueryClient } from "react-query";
import {
  useAddOrUpdateUserDaySchedule,
  useFetchEntities,
} from "@/reactQueryHooks";
import ScheduleAssignmentDrawerContent from "./ScheduleAssignmentDrawerContent";
import { useAxios } from "@/axiosProvider";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";

//------------------------------------------------------------

// Interfaces
interface CornerRibbonProps {
  isProjectedSchedule: boolean;
}

interface TourGroup {
  _id: string;
  product_id: string;
  option_id: string;
  start_time_id: string;
  vehicle_id: string;
}

interface Role {
  _id: string;
  title: string;
}

interface Vehicle {
  _id: string;
  plate: string;
}

interface Product {
  _id: string;
  title: string;
  options: Option[];
  start_times?: StartTime[];
}

interface Option {
  _id: string;
  title: string;
}

interface StartTime {
  _id: string;
  time_slot: string;
}

interface Entities {
  data: {
    products: Product[];
    roles: Role[];
    vehicles: Vehicle[];
    staff: Staff[];
  };
}

interface Staff {
  _id: string;
  name: string;
  roles: { _id: string }[];
  value: string;
}

interface ScheduleItemProps {
  tourGroup: TourGroup;
  role_id: string;
  isProjectedSchedule: boolean;
}

interface Comment {
  _id: string;
  author_id: string;
  date: string;
  text: string;
}

interface TourGroupSchedule {
  id: string;
  role: string;
  projected?: boolean;
  _data?: TourGroup; // Make _data optional to accommodate new assignments that may not have _data yet
}

interface ScheduleData {
  _id?: string;
  tourGroups: TourGroupSchedule[];
  comments?: Comment[];
  isDayOff?: boolean;
  isLeave?: boolean;
  isProjectedDayOff?: boolean;
  isProjectedLeave?: boolean;
  user?: string;
  date?: string;
  notify?: boolean;
}

interface ContainerProps {
  isDragOver?: boolean;
  canDrop?: boolean;
}

interface User {
  value: string;
  isAdmin?: boolean;
  permissions?: {
    assignSchedule?: boolean;
  };
}

interface CalendarBoxProps {
  date: string;
  user: User;
  schedule: ScheduleData | null;
}

interface DragSourceData {
  date: string;
  roleId: string;
  tourGroupId: string;
  [key: string]: unknown; // Allow for additional properties
}

//------------------------------------------------------------

const CornerRibbon = styled.div<CornerRibbonProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
  z-index: auto;
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 12px;
    background-color: ${(props) =>
      props.isProjectedSchedule
        ? "rgba(204, 85, 0, 0.7)"
        : "rgba(46, 139, 87, 0.7)"};
    transform: rotate(-45deg) translateY(-15px) translateX(-15px);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
`;

const ScheduleItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 38px;
  border-radius: 6px;
  //border: 2px solid orange;
  align-items: center;
  color: #3d3d3d;
  background: #dff0f4;
  padding: 3px;
  gap: 2px;
  .title {
    font-size: 10px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  font-weight: bold;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);

  .resources-row {
    width: 100%;
    background: whitesmoke;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 9px;
    i {
      font-size: 8px;
      margin-right: 2px;
    }
  }
`;

const DayOffContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 35px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.green1};
  font-size: 10px;
  font-weight: bold;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;

// Add ProjectedDayOffContainer for projected day off
const ProjectedDayOffContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 35px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.green1};
  font-size: 10px;
  font-weight: bold;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;

// Update the LeaveContainer to include the ribbon
const LeaveContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 35px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.lavender};
  font-size: 10px;
  font-weight: bold;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;

// Add ProjectedLeaveContainer for projected leave
const ProjectedLeaveContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 35px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.lavender};
  font-size: 10px;
  font-weight: bold;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;
const CommentItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
  padding: 0 7px;
  min-height: 35px;
  gap: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #f2c571;
  font-size: 11px;
  color: #3d3d3d;
  font-style: italic;
  user-select: none;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px;
  cursor: pointer;
  justify-content: flex-start;
  overflow: auto;
  gap: 5px;
  height: 100%;
  width: 100%;
  color: white;
  .fa-plus-circle {
    color: #a3a3a3;
    font-size: 20px;
  }

  background: ${({ isDragOver, canDrop }) => {
    if (isDragOver && canDrop) return "green";
    if (isDragOver && !canDrop) return "red";
    return "whitesmoke";
  }};

  &:hover {
    .fa-plus-circle {
      color: ${({ theme }) => theme.colors.green};
    }
    filter: brightness(0.96);

    ${ScheduleItemContainer} {
      background: white;
    }
    ${CommentItemContainer} {
      background: #f2d6a1;
    }
  }
  overflow: auto;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #91c9ff;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

interface OverlayProps {
  allowDrop: boolean;
}

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  backdrop-filter: blur(6px);
  background: ${({ allowDrop }) =>
    allowDrop ? "rgba(0,255,0,0.2)" : "rgba(255,0,0,0.2)"};
`;

//-----------------------------------------------------------------------------------

const ScheduleItem: React.FC<ScheduleItemProps> = ({
  tourGroup,
  role_id,
  isProjectedSchedule,
}) => {
  const { data: entities } = useFetchEntities() as { data: Entities };

  const product = entities.data.products.find(
    (product) => product._id === tourGroup?.product_id
  );

  const option = product?.options.find(
    (option) => option._id === tourGroup?.option_id
  );

  const startTime = product?.start_times?.find(
    (start_time) => start_time._id === tourGroup?.start_time_id
  );

  const role = entities?.data?.roles.find((role) => role?._id === role_id);
  const vehicle = entities.data.vehicles.find(
    (vehicle) => vehicle._id === tourGroup?.vehicle_id
  );

  return (
    <ScheduleItemContainer
      title={`${product?.title} - ${option?.title} (${role?.title}) @ ${startTime?.time_slot}`}
    >
      <Tooltip title={isProjectedSchedule ? "Projected Schedule" : null}>
        <CornerRibbon isProjectedSchedule={isProjectedSchedule} />
      </Tooltip>

      {tourGroup ? (
        <>
          <span className="title">{`${product?.title} (${role?.title})`}</span>
          <div className="resources-row">
            <span>
              <i className="fa-solid fa-bus"></i>
              {vehicle?.plate || "N/A"}
            </span>
            <span>
              <i className="fa-solid fa-clock"></i>
              {startTime?.time_slot}
            </span>
          </div>
        </>
      ) : (
        <span
          style={{
            color: "indianred",
            fontSize: "13px",
          }}
        >
          Error getting group
        </span>
      )}
    </ScheduleItemContainer>
  );
};

//-----------------------------------------------------------------------------------

const CalendarBox = ({
  date: tourDate,
  user: userData,
  schedule,
}: CalendarBoxProps) => {
  const queryClient = useQueryClient();
  const { data: entities } = useFetchEntities() as { data: Entities };
  const user = entities.data.staff.find((user) => user._id === userData.value);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [scheduleData, setScheduleData] = useState<ScheduleData | null>(null);
  const [onDrop, setOnDrop] = useState<"allow" | "deny" | null>(null);
  const hasScheduleData = scheduleData !== null;
  const hasComments =
    hasScheduleData &&
    scheduleData.comments &&
    scheduleData.comments.length > 0;
  const isEmptyOrHasNoData =
    !hasScheduleData ||
    (scheduleData?.tourGroups?.length === 0 && !hasComments);
  const isDayOff = hasScheduleData && scheduleData.isDayOff;
  const isLeave = hasScheduleData && scheduleData.isLeave;
  const isProjectedDayOff = hasScheduleData && scheduleData.isProjectedDayOff;
  const isProjectedLeave = hasScheduleData && scheduleData.isProjectedLeave;
  const containerRef = useRef<HTMLDivElement>(null);

  const { user: currentUser } = useAxios() as { user: User };
  const userIsAdmin = currentUser.isAdmin;
  const userIsPermittedToAssignSchedule =
    currentUser.permissions?.assignSchedule || userIsAdmin;

  const {
    isLoading: isUpdating,
    data: updatedSchedule,
    mutateAsync: addOrUpdateUserDaySchedule,
  } = useAddOrUpdateUserDaySchedule() as unknown as {
    isLoading: boolean;
    data: ScheduleData | null;
    mutateAsync: (data: ScheduleData) => Promise<ScheduleData>;
  };

  const shouldShowPlusIcon =
    isEmptyOrHasNoData &&
    !isDayOff &&
    !isLeave &&
    !isProjectedDayOff &&
    !isProjectedLeave &&
    !hasComments &&
    !isUpdating;

  useEffect(() => {
    setScheduleData(schedule);
    if (updatedSchedule) {
      setScheduleData(updatedSchedule);
    }
  }, [schedule, updatedSchedule]);

  const checkIfUserCanAcceptRole = (
    user: Staff | undefined,
    roleId: string
  ): boolean => {
    return user?.roles.some((role) => role._id === roleId) || false;
  };

  const checkIfUserHasAlreadyAssignedThisTourGroup = useCallback(
    (tourGroupId: string): boolean => {
      return (
        scheduleData?.tourGroups.some((tg) => tg.id === tourGroupId) || false
      );
    },
    [scheduleData?.tourGroups]
  );

  useEffect(() => {
    const el = containerRef.current;
    if (!el) return;

    return dropTargetForElements({
      element: el,
      onDragEnter: ({ source }) => {
        const { date, roleId, tourGroupId } =
          source.data as unknown as DragSourceData;

        const canAcceptRole = checkIfUserCanAcceptRole(user, roleId);
        const hasAlreadyAssigned =
          checkIfUserHasAlreadyAssignedThisTourGroup(tourGroupId);

        const datesMatch = date === tourDate;

        const canDrop =
          canAcceptRole &&
          userIsPermittedToAssignSchedule &&
          datesMatch &&
          !hasAlreadyAssigned;

        setOnDrop(canDrop ? "allow" : "deny");
      },
      onDragLeave: () => {
        setOnDrop(null);
      },
      onDrop: async ({ source }) => {
        if (!userIsPermittedToAssignSchedule) {
          setOnDrop(null);
          alert("You are not permitted to assign schedules");
          return;
        }
        const { date, roleId, tourGroupId } =
          source.data as unknown as DragSourceData;

        if (onDrop === "allow") {
          await addOrUpdateUserDaySchedule({
            ...(scheduleData as ScheduleData),
            user: userData.value,
            date: moment(new Date(date)).format("YYYY-MM-DD"),
            isDayOff: false,
            isLeave: false,
            _id: scheduleData?._id,
            tourGroups: [
              ...(scheduleData?.tourGroups || []),
              {
                id: tourGroupId,
                role: roleId,
                projected: true, // Set new assignments as projected by default
              },
            ],
            notify: false, // No notification when adding to projected
          });

          await queryClient.invalidateQueries({
            queryKey: [
              "USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP",
              tourGroupId,
            ],
          });
        }

        setOnDrop(null);
      },
    });
  }, [
    tourDate,
    user,
    userIsPermittedToAssignSchedule,
    scheduleData,
    userData,
    addOrUpdateUserDaySchedule,
    onDrop,
    checkIfUserHasAlreadyAssignedThisTourGroup,
    queryClient,
  ]);

  //-----------------------------------------------------------

  return (
    <>
      <Container
        ref={containerRef}
        onClick={() => {
          setOpenDrawer(true);
        }}
      >
        {isUpdating && (
          <SpinnerContainer>
            <div
              style={{
                color: "grey",
                width: "15px",
                height: "15px",
              }}
              className="spinner-border"
              role="status"
            ></div>
          </SpinnerContainer>
        )}
        {isDayOff && !isUpdating && (
          <DayOffContainer>
            <CornerRibbon isProjectedSchedule={false} />
            DAY OFF
          </DayOffContainer>
        )}

        {isLeave && !isUpdating && (
          <LeaveContainer>
            <CornerRibbon isProjectedSchedule={false} />
            LEAVE
          </LeaveContainer>
        )}

        {isProjectedDayOff && !isDayOff && !isLeave && !isUpdating && (
          <ProjectedDayOffContainer>
            <CornerRibbon isProjectedSchedule={true} />
            PROJECTED DAY OFF
          </ProjectedDayOffContainer>
        )}

        {isProjectedLeave && !isDayOff && !isLeave && !isUpdating && (
          <ProjectedLeaveContainer>
            <CornerRibbon isProjectedSchedule={true} />
            PROJECTED LEAVE
          </ProjectedLeaveContainer>
        )}

        {shouldShowPlusIcon && !onDrop && (
          <AddCircleIcon
            color="success"
            sx={{
              margin: "auto",
            }}
          />
        )}

        {onDrop && (
          <Overlay allowDrop={onDrop === "allow"}>
            {onDrop === "allow" ? (
              <MoveToInboxIcon color="success" />
            ) : (
              <BlockIcon color="error" />
            )}
          </Overlay>
        )}

        {!isUpdating &&
          hasScheduleData &&
          scheduleData.tourGroups.map((tourGroup) => {
            return (
              <ScheduleItem
                key={uuidv4()}
                tourGroup={tourGroup._data as TourGroup}
                role_id={tourGroup.role}
                isProjectedSchedule={tourGroup?.projected || false}
              />
            );
          })}

        {!isUpdating &&
          scheduleData?.comments?.map((comment) => {
            const author = entities.data.staff.find(
              (user) => user._id === comment?.author_id
            );

            return (
              <Tooltip
                key={uuidv4()}
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "7px",
                      }}
                    >
                      <i
                        style={{
                          fontSize: "9px",
                        }}
                        className="fa-solid fa-user"
                      ></i>
                      <span
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {author?.name || "UNKNOWN"}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "7px",
                      }}
                    >
                      <i
                        style={{
                          fontSize: "9px",
                        }}
                        className="fa-solid fa-calendar"
                      ></i>
                      <span
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {comment.date}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "7px",
                        fontStyle: "italic",
                      }}
                    >
                      <i
                        style={{
                          fontSize: "9px",
                        }}
                        className="fa-solid fa-comment"
                      ></i>
                      <span
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {comment.text}
                      </span>
                    </div>
                  </div>
                }
              >
                <CommentItemContainer key={comment._id}>
                  <i
                    style={{
                      fontSize: "12px",
                      color: "#7b7b7b",
                    }}
                    className="fa-solid fa-comment"
                  ></i>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  >
                    {comment.text}
                  </span>
                </CommentItemContainer>
              </Tooltip>
            );
          })}
      </Container>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <ScheduleAssignmentDrawerContent
          user={userData}
          date={tourDate}
          scheduleData={scheduleData}
          addOrUpdateUserDaySchedule={addOrUpdateUserDaySchedule}
          isUpdating={isUpdating}
          closeDrawer={() => setOpenDrawer(false)}
        />
      </Drawer>
    </>
  );
};

export default CalendarBox;
