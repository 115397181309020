import styled from "styled-components";
import { useFetchEntities } from "@/reactQueryHooks";
import { v4 as uuidv4 } from "uuid";
import ScheduleInfoTooltip from "./ScheduleInfoTooltip";

//----------------------------------

const Container = styled.div<{ isHovered: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 8px 12px;
  background-color: ${({ isHovered }) =>
    isHovered ? "rgb(215 232 255)" : "whitesmoke"};
  transition: background-color 0.2s ease;
  border-right: 1px solid #e0e0e0;
  //z-index: 110;
`;

const UserName = styled.div`
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 4px;
  display: block;
  color: #333;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 2px;
`;

const RolesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  max-width: 100%;
`;

const RoleBadge = styled.div`
  font-size: 8px;
  font-weight: 600;
  padding: 3px 7px;
  border-radius: 12px;
  background: linear-gradient(145deg, #f3f5f7, #e1e5ea);
  color: #3a4c5a;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: linear-gradient(145deg, #6c8cff, #4a6ce0);
    margin-right: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

//----------------------------------

const UserBox = ({ isHovered, user }: { isHovered: boolean; user: any }) => {
  const { data: entities } = useFetchEntities() as any;
  const allRoles = entities.data.roles;

  const userData = entities.data.staff.find(
    (staff: any) => staff._id === user.value
  );

  const userRolesIds = userData?.roles.map((role: any) => role._id);
  const userRoles = allRoles.filter((role: any) =>
    userRolesIds.includes(role._id)
  );

  return (
    <Container className={`cell sticky-col`} isHovered={isHovered}>
      <ScheduleInfoTooltip userData={userData} />
      <UserName>{userData.name}</UserName>

      <RolesContainer>
        {userRoles.map((role: any) => (
          <RoleBadge key={uuidv4()}>{role.title}</RoleBadge>
        ))}

        {userRoles.length === 0 && (
          <RoleBadge style={{ color: "#dc3545" }}>No roles</RoleBadge>
        )}
      </RolesContainer>
    </Container>
  );
};

export default UserBox;
