import { useState, useEffect } from "react";
import {
  useGetOpenSessions,
  useGetSessionsPaginated,
} from "../../../../reactQueryHooks";
import styled, { keyframes } from "styled-components";
import SelectionBar from "../../../selection_bar/SelectionBar";
import BarLoader from "react-spinners/BarLoader";
import BookingPortalLogsBarContent from "../bar/BookingPortalLogsBarContent";
import SessionRow from "./SessionRow";
import io from "socket.io-client";
const socketTourInfo = io("https://getaways-tour-info.herokuapp.com", {
  transports: ["websocket", "polling", "flashsocket"],
});

// const socketTourInfoDev = io("http://localhost:3001", {
//   transports: ["websocket", "polling", "flashsocket"],
// });

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  padding: 10px;
  flex: 1;
  //background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 5px;
`;

const TableContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  table {
    width: 100%;
  }
  th {
    font-size: 12px;
  }
  td {
    font-size: 12px;
  }
  th,
  td {
    //dont wrap text
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const OnlineContentContainer = styled.div`
  padding: 10px;
  background-color: gainsboro;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  max-height: 50%;
  gap: 5px;
`;

const ContentContainer = styled.div`
  padding: 10px;
  background-color: gainsboro;
  border-radius: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 800px;
  gap: 10px;
`;

const ListWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const ListContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 0 1px 0 0;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
`;

// Keyframes for blinking effect
const blink = keyframes`
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
`;

// Styled component for the online status
const OnlineStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

// Styled component for the blinking dot
const BlinkingDot = styled.span`
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-left: 5px;
  animation: ${blink} 1s linear infinite;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  span {
    font-size: 12px;
    font-weight: 600;
    //wrap with ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  //input type checkbox
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  select {
    cursor: pointer;
    font-size: 12px;
    border: none;
    outline: none;
    padding: 2px;
  }
`;

//------------------------------------------------------------------------

const OnlineIndicator = () => (
  <OnlineStatus>
    ONLINE <BlinkingDot />
  </OnlineStatus>
);

const BookingPortalLogsContent = () => {
  const [dataPage, setDataPage] = useState(1);
  const [actionSelected, setActionSelected] = useState("all");
  const { data: openSessions } = useGetOpenSessions();

  const {
    data: sessions,
    isFetching,
    refetch,
  } = useGetSessionsPaginated(dataPage, actionSelected);
  const totalPages = sessions?.totalPages || 1;

  useEffect(() => {
    // subscribe socket listener on mount
    socketTourInfo.on(`tour_info/session_open`, (data) => {
      refetch();
    });
    socketTourInfo.on(`tour_info/session_killed`, (data) => {
      refetch();
    });

    // unsubscribe socket on unmount (cleanup)
    return () => {
      socketTourInfo.off(`tour_info/session_open`);
      socketTourInfo.off(`tour_info/session_killed`);
    };
  }, [refetch]);

  useEffect(() => {
    refetch();
  }, [actionSelected, dataPage, refetch]);

  //------------------------------------------------------------------------

  //------------------------------------------------------------------------

  return (
    <Container>
      <SelectionBar>
        <BookingPortalLogsBarContent refetch={refetch} />
      </SelectionBar>
      <ContentWrapper>
        <OnlineContentContainer>
          <OnlineIndicator />
          {openSessions?.data?.length ? (
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Booking ref</th>
                    <th>First Visit Timestamp</th>
                    <th>Actions #</th>
                    <th>Last Action</th>
                    <th>Last Action Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {openSessions.data.map((openSession, index) => {
                    return (
                      <SessionRow
                        key={`open-session-${openSession.booking_ref}`}
                        session={openSession}
                        selectedAction={null}
                      />
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          ) : null}

          {!openSessions?.data?.length && (
            <div
              style={{
                color: "dodgerblue",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              No online sessions
            </div>
          )}
        </OnlineContentContainer>
        <ContentContainer>
          <InfoContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span>Filter by actions</span>
              <select
                onChange={(e) => {
                  setDataPage(1);
                  setActionSelected(e.target.value);
                }}
              >
                <option value="all">All</option>
                <option value="SCROLLED_TO_BOTTOM">Scrolled to bottom</option>
                <option value="REVIEW_LINK_CLICK">Clicked review page</option>
                <option value="PROMO_PRODUCT_CLICK">Clicked promo page</option>
                <option value="SIM_LINK_CLICK">Clicked sim page</option>
                <option value="ADDED_LOCATION">Added location</option>
                <option value="CONFIRMED_INSTRUCTIONS">
                  Confirmed instructions
                </option>
                <option value="BUS_TRACKING_MAP_CLICK">
                  Visited bus tracking map
                </option>
                <option value="NAVIGATION_LINK_CLICK">
                  Clicked meeting point link
                </option>
                <option value="CONTACT_BUTTON_CLICK">
                  Clicked contact button
                </option>
              </select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span>Page</span>
              <select
                onChange={(e) => {
                  setDataPage(Number(e.target.value));
                }}
                value={dataPage}
              >
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (page) => {
                    return (
                      <option key={page} value={page}>
                        Page {page} / {totalPages}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          </InfoContainer>
          <ListWrapper>
            <ListContainer id="content-container">
              {isFetching && (
                <LoaderOverlay>
                  <BarLoader color="#606060" loading={true} />
                </LoaderOverlay>
              )}
              {sessions?.docs?.length ? (
                <TableContainer>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Booking ref</th>
                        <th>First Visit Timestamp</th>
                        <th>Actions #</th>
                        <th>Last Action</th>
                        <th>Last Action Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sessions.docs.map((openSession, index) => {
                        return (
                          <SessionRow
                            key={`session-${openSession.booking_ref}`}
                            session={openSession}
                            selectedAction={actionSelected}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </TableContainer>
              ) : null}

              {!sessions?.docs?.length && (
                <div
                  style={{
                    color: "indianred",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  No sessions found
                </div>
              )}

              {/* <OptionsButtonsContainer>
                <Tooltip title="Generate excel file" placement="left">
                  <OptionsButton
                    onClick={() => {
                      if (window.confirm("Generate excel file ?")) {
                        generateExcelFromPortalLogs(allData);
                      }
                    }}
                  >
                    <i className="fa-solid fa-file-excel"></i>
                  </OptionsButton>
                </Tooltip>
              </OptionsButtonsContainer> */}
            </ListContainer>
          </ListWrapper>

          <InfoContainer>
            <span>
              Total sessions:{" "}
              <span
                style={{
                  color: "dodgerblue",
                  fontWeight: "600",
                }}
              >
                {sessions?.totalDocs}
              </span>
            </span>
          </InfoContainer>
        </ContentContainer>
      </ContentWrapper>
    </Container>
  );
};

export default BookingPortalLogsContent;
