import { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { findBestMatch } from "string-similarity";

//---------------------------------------------------------

const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 100%;
  height: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;

`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Input = styled.input`
  flex: 1;
  height: 35px;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 13px;
`;

const ListContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-right: 5px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  :hover {
    div {
      opacity: 0.6;
    }
  }
  color: ${(props) => (props.isSelected ? "#1b81e5" : "black")};
  padding: 5px 8px 5px 6px;
  //font-weight: ${(props) => (props.isSelected ? "bold" : "normal")};
  background-color: ${(props) =>
    props.isSelectedByKey ? "whitesmoke" : "white"};
  border-radius: 5px;
`;

//---------------------------------------------------------

const CustomSelect = ({ data, selected_id, onSelect }) => {
  console.log("CustomSelect", data, selected_id, onSelect);
  const [searchInput, setSearchInput] = useState("");
  const [itemSelectedByKeyIndex, setItemSelectedByKeyIndex] = useState(0);
  const listRef = useRef(null);

  const sortData = useCallback(
    (searchTerm) => {
      const matches = data.map((item) => ({
        ...item,
        similarity: findBestMatch(searchTerm?.toLowerCase(), [
          item?.label?.toLowerCase(),
        ]).bestMatch.rating,
      }));
      matches.sort((a, b) => b.similarity - a.similarity);
      return matches;
    },
    [data]
  );
  const sortedData = sortData(searchInput);

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
    const listRefOk = listRef && listRef.current;
    if (listRefOk) {
      //scroll to top
      listRef.current.scrollTop = 0;
    }
    //reset selected item to first item (best match of search)
    setItemSelectedByKeyIndex(0);
  };

  //------------------------------ USEEFFECTS ---------------------------------

  //handle initial scroll to selected item on mount
  useEffect(() => {
    //find selected row and set index in component mount once (it will scroll to position on mount)
    const index = sortedData.findIndex((item) => item.value === selected_id);
    setItemSelectedByKeyIndex(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //handle scroll to selected item (itemSelectedByKeyIndex)
  useEffect(() => {
    const listRefOk = listRef && listRef.current;
    if (listRefOk) {
      const selectedRow = listRef.current.children[itemSelectedByKeyIndex];
      if (selectedRow) {
        const listRect = listRef.current.getBoundingClientRect();
        const rowRect = selectedRow.getBoundingClientRect();

        // Check if the selected item is already visible
        if (rowRect.top < listRect.top || rowRect.bottom > listRect.bottom) {
          selectedRow.scrollIntoView({
            behavior: "smooth",
            block: "nearest", // You can adjust this to control the scrolling position
            inline: "nearest",
          });
        }
      }
    }
  }, [itemSelectedByKeyIndex, listRef]);

  //handle keyboard navigation in the list
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        event.preventDefault();
        setItemSelectedByKeyIndex((prevIndex) =>
          //dont go lower than 0
          prevIndex > 0 ? prevIndex - 1 : 0
        );
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        setItemSelectedByKeyIndex((prevIndex) =>
          //dont go past the last item index
          prevIndex < sortedData.length - 1
            ? prevIndex + 1
            : sortedData.length - 1
        );
      } else if (event.key === "Enter") {
        event.preventDefault();
        const selectedItem = sortedData[itemSelectedByKeyIndex];
        if (selectedItem) {
          onSelect(selectedItem.value);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [itemSelectedByKeyIndex, sortedData, onSelect]);

  return (
    <Container>
      <TopContainer>
        <Input
          autoFocus
          placeholder="Search..."
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          value={searchInput}
          onChange={handleInputChange}
        />
      </TopContainer>
      <ListContainer ref={listRef}>
        {sortedData.map((item, index) => {
          const isSelected = selected_id === item.value;
          const isSelectedByKey = itemSelectedByKeyIndex === index;

          return (
            <RowContainer
              isSelected={isSelected}
              isSelectedByKey={isSelectedByKey}
              key={item.value}
              onClick={() => onSelect(item.value)}
            >
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                {item.label}
              </div>
              <i
                style={{
                  color: "darkgreen",
                  opacity: isSelected ? 1 : 0,
                }}
                className="fas fa-check"
              ></i>
            </RowContainer>
          );
        })}
      </ListContainer>
    </Container>
  );
};

export default CustomSelect;
