// src/components/AddUsersModal/styles/textFieldStyles.ts
import { SxProps, Theme } from "@mui/material/styles";

/**
 * Creates a style object for MUI TextFields that shows valid state styling
 * @param isValid Boolean indicating if the field is valid
 * @returns SxProps style object for MUI TextField
 */
export const validationFieldStyle = (isValid: boolean): SxProps<Theme> => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: isValid ? "#4caf50" : undefined,
    },
    "&:hover fieldset": {
      borderColor: isValid ? "#4caf50" : undefined,
    },
    "&.Mui-focused fieldset": {
      borderColor: isValid ? "#4caf50" : undefined,
    },
    backgroundColor: isValid ? "rgba(76, 175, 80, 0.05)" : undefined,
  },
});
