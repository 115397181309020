import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import {
  SectionContainer,
  SectionTitle,
  SectionOverlay,
  StatusBadge,
  EmptyMessage,
  colors,
} from "../styles";

const GroupList = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 5px 5px 5px 0;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.light};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray};
    border-radius: 6px;
  }
`;

const GroupItemContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-radius: 8px;
  /* background: ${({ isSelected }) =>
    isSelected ? colors.selected : colors.light}; */
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);

    .group-item-overlay {
      display: ${({ isSelected }) => (isSelected ? "none" : "flex")};
    }
  }

  .check-icon {
    color: #4ade80;
    display: ${({ isSelected }) => (isSelected ? "block" : "none")};
  }
`;

const GroupItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  display: none;
  flex-direction: row;
  z-index: 2;
`;

const GroupItemOverlayItem = styled.button`
  flex: 1;
  border: none;
  background: transparent;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primary};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(67, 97, 238, 0.1);
    color: ${colors.secondary};
  }

  &.disabled {
    color: ${colors.danger};
    cursor: not-allowed;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  span {
    color: ${colors.textPrimary};
  }
`;

const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: ${colors.primary};
  font-weight: 500;
`;

const AvailableGroups = ({
  scheduleData,
  entities,
  tourGroupsBySpecificDate,
  isFetchingBySpecificDate,
  dateHasTourGroups,
  isDayOff,
  isLeave,
  user,
  onAddTourGroup,
}) => {
  return (
    <SectionContainer disabled={isDayOff || isLeave}>
      <SectionTitle>
        <i className="fas fa-list"></i>
        Available Groups
        {(isDayOff || isLeave) && (
          <StatusBadge isLeave={isLeave}>
            {isDayOff ? "Day Off" : "On Leave"}
          </StatusBadge>
        )}
      </SectionTitle>

      {isFetchingBySpecificDate ? (
        <LoadingIndicator>
          <i
            className="fas fa-circle-notch fa-spin"
            style={{ marginRight: "8px" }}
          ></i>
          Loading available groups...
        </LoadingIndicator>
      ) : !dateHasTourGroups ? (
        <EmptyMessage>No groups available for this date</EmptyMessage>
      ) : (
        <GroupList>
          {tourGroupsBySpecificDate.map((tourGroup) => {
            const product = entities?.products?.find(
              (product) => product._id === tourGroup?.product_id
            );
            const option = product?.options?.find(
              (option) => option._id === tourGroup?.option_id
            );
            const startTime = product?.start_times?.find(
              (start_time) => start_time._id === tourGroup?.start_time_id
            );
            const userData = entities?.staff?.find(
              (staff) => staff._id === user.value
            );
            const isSelected = scheduleData?.tourGroups?.find(
              (tg) => tg?._data?._id === tourGroup?._id
            );
            const productRoles = product?.crewRoles;
            const userRolesThatMatchProductRoles = userData?.roles?.filter(
              (userRole) =>
                productRoles?.some((crewRoleId) => crewRoleId === userRole._id)
            );
            const hasNoMatchingRoles =
              !userRolesThatMatchProductRoles ||
              userRolesThatMatchProductRoles.length === 0;

            return (
              <GroupItemContainer key={uuidv4()} isSelected={isSelected}>
                <ProductInfo>
                  <span>{`${product?.title} - ${option?.title}`}</span>
                  <span>{startTime?.time_slot}</span>
                  <span
                    style={{ color: colors.gray }}
                  >{`#${tourGroup.index}`}</span>
                </ProductInfo>
                <i className="fas fa-check check-icon"></i>

                <GroupItemOverlay
                  className="group-item-overlay"
                  isSelected={isSelected}
                >
                  {hasNoMatchingRoles && (
                    <GroupItemOverlayItem className="disabled">
                      <i
                        className="fas fa-ban"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Cannot be selected
                    </GroupItemOverlayItem>
                  )}

                  {!hasNoMatchingRoles &&
                    userRolesThatMatchProductRoles.map((role) => (
                      <GroupItemOverlayItem
                        key={uuidv4()}
                        onClick={() => onAddTourGroup(tourGroup, role._id)}
                      >
                        {role.title}
                      </GroupItemOverlayItem>
                    ))}
                </GroupItemOverlay>
              </GroupItemContainer>
            );
          })}
        </GroupList>
      )}
    </SectionContainer>
  );
};

export default AvailableGroups;
