// src/components/AddUsersModal/sections/ActiveStatusSection.tsx
import React from "react";
import { useFormikContext } from "formik";
import { UserFormValues } from "../types";
import { Section, FormRow } from "../styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import styled from "styled-components";

// Create a styled wrapper for the form row to add custom styles
const StatusFormRow = styled(FormRow)`
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
`;

const ActiveStatusSection = () => {
  const { values, setFieldValue } = useFormikContext<UserFormValues>();

  return (
    <Section>
      <StatusFormRow>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" sx={{ mr: 1, fontWeight: 500 }}>
            Status:
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={values.isActive}
                onChange={(e) => setFieldValue("isActive", e.target.checked)}
                color={values.isActive ? "success" : "default"}
              />
            }
            label={
              <Typography
                variant="body1"
                sx={{
                  color: values.isActive ? "#4caf50" : "#f44336",
                  fontWeight: 500,
                }}
              >
                {values.isActive ? "Active" : "Inactive"}
              </Typography>
            }
          />
        </Box>
      </StatusFormRow>
      <Divider sx={{ mb: 2 }} />
    </Section>
  );
};

export default ActiveStatusSection;
