// src/components/AddUsersModal/sections/UserInfoSection.tsx
import React from "react";
import { useFormikContext } from "formik";
import { UserFormValues } from "../types";
import { Section, SectionTitle, FormRow, FormGroup } from "../styles";
import TextField from "@mui/material/TextField";

const UserInfoSection = () => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<UserFormValues>();

  return (
    <Section>
      <SectionTitle>Basic Information</SectionTitle>
      <FormRow>
        <FormGroup>
          <TextField
            id="name"
            name="name"
            label="Full Name"
            placeholder="Enter full name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && !!errors.name}
            helperText={touched.name && errors.name}
            variant="filled"
            size="small"
            fullWidth
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor:
                    touched.name && !errors.name ? "#4caf50" : undefined,
                },
                "&:hover fieldset": {
                  borderColor:
                    touched.name && !errors.name ? "#4caf50" : undefined,
                },
                "&.Mui-focused fieldset": {
                  borderColor:
                    touched.name && !errors.name ? "#4caf50" : undefined,
                },
                backgroundColor:
                  touched.name && !errors.name
                    ? "rgba(76, 175, 80, 0.05)"
                    : undefined,
              },
            }}
          />
        </FormGroup>

        <FormGroup>
          <TextField
            id="username"
            name="username"
            label="Username"
            placeholder="Enter username"
            value={values.username}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.username && !!errors.username}
            helperText={touched.username && errors.username}
            variant="filled"
            size="small"
            fullWidth
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor:
                    touched.username && !errors.username
                      ? "#4caf50"
                      : undefined,
                },
                "&:hover fieldset": {
                  borderColor:
                    touched.username && !errors.username
                      ? "#4caf50"
                      : undefined,
                },
                "&.Mui-focused fieldset": {
                  borderColor:
                    touched.username && !errors.username
                      ? "#4caf50"
                      : undefined,
                },
                backgroundColor:
                  touched.username && !errors.username
                    ? "rgba(76, 175, 80, 0.05)"
                    : undefined,
              },
            }}
          />
        </FormGroup>
      </FormRow>
    </Section>
  );
};

export default UserInfoSection;
