import styled from "styled-components"
import UsersList from "./UsersList"
import { useState } from "react"
import { useAxios } from "../../../../../axiosProvider"
import { useFetchEntities } from "../../../../../reactQueryHooks"
//import AddUsersModal from "./AddUsersModal"
import AddUsersModal from "./AddUsersModal"
import { Button } from "@mui/material"

//-----------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex: 1 0 450px;
  max-height: 500px;
  flex-direction: column;
  gap: 7px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 7px;
  border-radius: 5px;
  user-select: none;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow-y: auto;
  position: relative;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: rgb(35, 139, 243);
`

const AddButtonContainer = styled.div`
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
`

//-----------------------------------------------------------------------

const UsersOptions = () => {
  const { data: entities } = useFetchEntities()
  const [showAddUsersModal, setShowAddUsersModal] = useState(false)
  const [userData, setUserData] = useState({})
  const { user } = useAxios()
  const userIsPermittedToEdit =
    user.isAdmin || (user.isModerator && user.permissions.addNewStaff)

  const staffSortedByName = entities?.data?.staff?.sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  staffSortedByName.sort((a, b) => {
    if (a.isAdmin && b.isAdmin) {
      return 0
    } else if (a.isAdmin) {
      return -1
    } else if (b.isAdmin) {
      return 1
    } else if (a.isModerator && b.isModerator) {
      return 0
    } else if (a.isModerator) {
      return -1
    } else if (b.isModerator) {
      return 1
    } else {
      return 0
    }
  })

  return (
    <Container>
      <TitleContainer>Users</TitleContainer>
      <ContentContainer>
        {entities?.data && (
          <UsersList
            users={staffSortedByName}
            onSelect={(listedUser) => {
              const userIsAdmin = user.isAdmin
              const listedUserIsAdmin = listedUser.isAdmin
              const listedUserIsModerator = listedUser.isModerator
              const isOwnUser = listedUser._id === user._id

              //is admin
              if (listedUserIsAdmin) {
                // admins can edit their own profile only
                if (isOwnUser) {
                  setUserData(listedUser)
                  setShowAddUsersModal(true)
                } else {
                  alert("You have no permission to edit this user")
                }
              }
              //is moderator
              if (listedUserIsModerator) {
                // only admins can edit moderators
                if (userIsAdmin) {
                  setUserData(listedUser)
                  setShowAddUsersModal(true)
                } else {
                  alert("You have no permission to edit this user")
                }
              }

              //is simple user
              if (!listedUserIsAdmin && !listedUserIsModerator) {
                // simple users can be edited only by admins or permitted moderators
                if (userIsPermittedToEdit) {
                  setUserData(listedUser)
                  setShowAddUsersModal(true)
                } else {
                  alert("You have no permission to edit this user")
                }
              }
            }}
          />
        )}
        <AddButtonContainer>
          <Button
            sx={{
              width: "100%",
            }}
            variant="outlined"
            color="success"
            onClick={() => {
              if (userIsPermittedToEdit) {
                setShowAddUsersModal(true)
                setUserData({})
              } else {
                alert("You have no permission to add new users")
              }
            }}
            title="Add User"
          >
            Add User
          </Button>
        </AddButtonContainer>
      </ContentContainer>
      {showAddUsersModal && (
        <AddUsersModal
          close={() => setShowAddUsersModal(false)}
          data={userData}
        />
      )}
    </Container>
  )
}

export default UsersOptions
