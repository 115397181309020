import { createContext, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
// Components
import Header from "./components/Header";
import ProjectedSchedule from "./components/ProjectedSchedule";
import AssignedSchedule from "./components/AssignedSchedule";
import AvailableStaff from "./components/AvailableStaff";
import {
  useAddOrUpdateUserDaySchedule,
  useFinalizeUserDaySchedule,
  useRemoveProjectedAssignment,
} from "@/reactQueryHooks";

// Styled Components
import { Container, ScrollableContent, colors } from "./styles";

const TopButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

// Loader overlay
const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 8px;
`;

const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  i {
    color: ${colors.primary};
    font-size: 24px;
  }

  span {
    color: ${colors.textPrimary};
    font-weight: 500;
  }
`;

export const Context = createContext({
  isUpdating: false,
  setIsUpdating: () => {},
});

const GroupAssignmentDrawerContent = ({ tourGroup, closeDrawer }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  // Define pulse keyframes in a style object
  const pulseKeyframes = `
    @keyframes pulse {
      0% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1); }
      70% { box-shadow: 0 0 0 6px rgba(0, 0, 0, 0); }
      100% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }
    }
  `;

  return (
    <Container>
      <style>{pulseKeyframes}</style>

      {isUpdating && (
        <LoaderOverlay>
          <LoaderContent>
            <i className="fas fa-spinner fa-spin"></i>
          </LoaderContent>
        </LoaderOverlay>
      )}

      <TopButtonsRow onClick={() => closeDrawer()}>
        <CloseIcon fontSize="small" />
      </TopButtonsRow>

      <Header tourGroup={tourGroup} date={tourGroup?.date} />

      <Context.Provider value={{ isUpdating, setIsUpdating }}>
        <ScrollableContent>
          <ProjectedSchedule tourGroup={tourGroup} />
          <AssignedSchedule tourGroup={tourGroup} />
          <AvailableStaff tourGroup={tourGroup} />
        </ScrollableContent>
      </Context.Provider>
    </Container>
  );
};

export default GroupAssignmentDrawerContent;
