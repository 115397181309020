import styled from "styled-components";
import { useState } from "react";
import StaffSelect from "./StaffSelect";
import ClickAwayListener from "@mui/material/ClickAwayListener";

//----------------------------------------------------------------

const StaffSelectBox = styled.div<{
  isDropdownOpen: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
  height: 100%;
  color: #1a7cdb;
  top: 0;
  user-select: none;
  cursor: pointer;
  font-size: 11px;

  .fa-angle-down {
    transform: ${({ isDropdownOpen }) =>
      isDropdownOpen ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
    font-size: 10px;
  }
`;

const StaffDropdown = styled.div`
  position: absolute;
  top: 110%;
  left: 0;

`;

//------------------------------------------------------------------------

interface StaffSelectProps {
  user_ids: string[];
  setUser_ids: (user_ids: string[]) => void;
}

const StaffSelectDropdown = ({ user_ids, setUser_ids }: StaffSelectProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleClickAway = () => {
    setIsDropdownOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="cell sticky-col sticky-row top-left">
        <StaffSelectBox
          id="select-name"
          isDropdownOpen={isDropdownOpen}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span>Select staff</span>
          <i className="fas fa-angle-down"></i>
        </StaffSelectBox>

        {isDropdownOpen && (
          <StaffDropdown>
            <StaffSelect user_ids={user_ids} setUser_ids={setUser_ids} />
          </StaffDropdown>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default StaffSelectDropdown;
