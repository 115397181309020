// src/components/AddUsersModal/index.tsx
import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useAxios } from "@/axiosProvider";
import {
  useAddNewUser,
  useUpdateUser,
  useDeleteUser,
  useLogoutUserFromMobileDevices,
  useFetchEntities,
} from "@/reactQueryHooks";
import IconButton from "@mui/material/IconButton";
import ActiveStatusSection from "./sections/ActiveStatusSection";
import CloseIcon from "@mui/icons-material/Close";

import { UserFormValues, DeviceInfo } from "./types";
import {
  ModalWrapper,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "./styles";

// Component imports
import UserInfoSection from "./sections/UserInfoSection";
import ContactInfoSection from "./sections/ContactInfoSection";
import IdentificationSection from "./sections/IdentificationSection";
import PasswordSection from "./sections/PasswordSection";
import GroupsAndRolesSection from "./sections/GroupsAndRolesSection";
import UserRoleSection from "./sections/UserRoleSection";
import DevicesSection from "./sections/DevicesSection";
import PermissionsSection from "./sections/PermissionsSection";
import SchedulePreferencesSection from "./sections/SchedulePreferencesSection";
import ActionButtons from "./components/ActionButtons";
import { generateRandomPassword } from "./utils";

interface AddUsersModalProps {
  close: () => void;
  data?: any;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string().when("_id", {
    is: (val: string) => !val,
    then: () => Yup.string().required("Password is required"),
    otherwise: () => Yup.string(),
  }),
  email: Yup.string().email("Invalid email address"),
  tel: Yup.string().required("Phone number is required"),
  id_number: Yup.string(),
  afm_number: Yup.string(),
  amka_number: Yup.string(),
  driver_license_number: Yup.string(),
  guide_reg_number: Yup.string(),
});

const AddUsersModal = ({ close, data = {} }: AddUsersModalProps) => {
  const { user } = useAxios();
  const { data: entities, refetch } = useFetchEntities() as any;

  const { mutateAsync: addNewUser, isLoading: isAddingNewUser } =
    useAddNewUser();
  const { mutateAsync: updateUser, isLoading: isUpdatingUser } =
    useUpdateUser();
  const { mutateAsync: deleteUser, isLoading: isDeletingUser } =
    useDeleteUser();
  const { mutateAsync: logoutUser, isLoading: isLoggingOutUser } =
    useLogoutUserFromMobileDevices();

  const initialValues: UserFormValues = {
    _id: data?._id || "",
    name: data?.name || "",
    username: data?.username || "",
    password: data?.password || "",
    email: data?.contact?.email || "",
    tel: data?.contact?.tel || "",
    id_number: data?.id_number || "",
    afm_number: data?.afm_number || "",
    amka_number: data?.amka_number || "",
    driver_license_number: data?.driver_license_number || "",
    guide_reg_number: data?.guide_reg_number || "",
    isAdmin: data?.isAdmin || false,
    isModerator: data?.isModerator || false,
    shouldReceiveAnnouncements: data?.shouldReceiveAnnouncements || false,
    isEmergencyContact: data?.isEmergencyContact || false,
    isActive: data?.isActive !== undefined ? data.isActive : true,

    // Permissions
    addNewTasks: data?.permissions?.addNewTasks || false,
    addNewActivities: data?.permissions?.addNewActivities || false,
    addNewStaff: data?.permissions?.addNewStaff || false,
    addNewStaffGroups: data?.permissions?.addNewStaffGroups || false,
    addNewStaffRoles: data?.permissions?.addNewStaffRoles || false,
    addNewVehicles: data?.permissions?.addNewVehicles || false,
    handleRequests: data?.permissions?.handleRequests || false,
    publishAnnouncements: data?.permissions?.publishAnnouncements || false,
    sendOlpEmail: data?.permissions?.sendOlpEmail || false,
    addMeetingPoints: data?.permissions?.addMeetingPoints || false,
    handleBalance: data?.permissions?.handleBalance || false,
    sortBookingsInDayPlanner:
      data?.permissions?.sortBookingsInDayPlanner || false,
    assignSchedule: data?.permissions?.assignSchedule || false,
    assignVehicle: data?.permissions?.assignVehicle || false,
    addMessageDrafts: data?.permissions?.addMessageDrafts || false,
    addNotes: data?.permissions?.addNotes || false,
    uploadFiles: data?.permissions?.uploadFiles || false,
    updateBookings: data?.permissions?.updateBookings || false,
    editGuidePlan: data?.permissions?.editGuidePlan || false,
    handleServiceRecords: data?.permissions?.handleServiceRecords || false,
    hideTourGroups: data?.permissions?.hideTourGroups || false,
    createNewTourGroups: data?.permissions?.createNewTourGroups || false,
    handleAvailabilities: data?.permissions?.handleAvailabilities || false,

    // Groups and roles
    groups: data?.groups || [],
    roles: data?.roles || [],

    // Devices
    loggedDevices: data?.loggedDevices || [],
    mobileLogStatus: data?.mobileLogStatus || false,

    // Schedule Preferences
    amountOfWorkDaysPerWeek: data?.amountOfWorkDaysPerWeek || 5,
    canExceedAmountOfWorkDaysPerWeek:
      data?.canExceedAmountOfWorkDaysPerWeek || false,
    preferredWorkDaysPerWeek: data?.preferredWorkDaysPerWeek || [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
    ],
    preferredDayOffDaysPerWeek: data?.preferredDayOffDaysPerWeek || [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
    ],
    isFlexibleWithWorkDaysPerWeek: data?.isFlexibleWithWorkDaysPerWeek || false,
    canWorkMultipleShiftDays: data?.canWorkMultipleShiftDays || false,
    maxMultipleShiftDaysPerWeek: data?.maxMultipleShiftDaysPerWeek || 3,
    canWorkFullDayShifts: data?.canWorkFullDayShifts || false,
    maxFullDayShiftsPerWeek: data?.maxFullDayShiftsPerWeek || 2,
    availableBetweenTimes: data?.availableBetweenTimes || ["00:00", "23:59"],
    isFlexibleWithTimes: data?.isFlexibleWithTimes || false,
    scheduleRemarks: data?.scheduleRemarks || "",
  };

  const handleSubmit = async (
    values: UserFormValues,
    { setSubmitting }: FormikHelpers<UserFormValues>
  ) => {
    try {
      const payload = {
        ...values,
        password: values.password || null,
        contact: {
          email: values.email,
          tel: values.tel,
        },
        isActive: values.isActive,
        permissions: {
          addNewTasks: values.addNewTasks,
          addNewActivities: values.addNewActivities,
          addNewStaff: values.addNewStaff,
          addNewStaffGroups: values.addNewStaffGroups,
          addNewStaffRoles: values.addNewStaffRoles,
          addNewVehicles: values.addNewVehicles,
          handleRequests: values.handleRequests,
          publishAnnouncements: values.publishAnnouncements,
          sendOlpEmail: values.sendOlpEmail,
          addMeetingPoints: values.addMeetingPoints,
          handleBalance: values.handleBalance,
          sortBookingsInDayPlanner: values.sortBookingsInDayPlanner,
          assignSchedule: values.assignSchedule,
          assignVehicle: values.assignVehicle,
          addMessageDrafts: values.addMessageDrafts,
          addNotes: values.addNotes,
          uploadFiles: values.uploadFiles,
          updateBookings: values.updateBookings,
          editGuidePlan: values.editGuidePlan,
          handleServiceRecords: values.handleServiceRecords,
          hideTourGroups: values.hideTourGroups,
          createNewTourGroups: values.createNewTourGroups,
          handleAvailabilities: values.handleAvailabilities,
        },
        // Include schedule preferences in the payload
        amountOfWorkDaysPerWeek: values.amountOfWorkDaysPerWeek,
        canExceedAmountOfWorkDaysPerWeek:
          values.canExceedAmountOfWorkDaysPerWeek,
        preferredWorkDaysPerWeek: values.preferredWorkDaysPerWeek,
        preferredDayOffDaysPerWeek: values.preferredDayOffDaysPerWeek,
        isFlexibleWithWorkDaysPerWeek: values.isFlexibleWithWorkDaysPerWeek,
        canWorkMultipleShiftDays: values.canWorkMultipleShiftDays,
        maxMultipleShiftDaysPerWeek: values.maxMultipleShiftDaysPerWeek,
        canWorkFullDayShifts: values.canWorkFullDayShifts,
        maxFullDayShiftsPerWeek: values.maxFullDayShiftsPerWeek,
        availableBetweenTimes: values.availableBetweenTimes,
        isFlexibleWithTimes: values.isFlexibleWithTimes,
        scheduleRemarks: values.scheduleRemarks,
      };

      if (values._id) {
        // Cast to any to avoid TypeScript errors with the response type
        const response: any = await updateUser({
          id: values._id,
          payload,
        } as any);

        if (response?.data) {
          alert(response.data);
        } else {
          alert("User updated successfully");
        }
      } else {
        // Cast to any to avoid TypeScript errors with the response type
        const response: any = await addNewUser(payload as any);

        if (response?.data) {
          alert(response.data);
        } else {
          alert("User created successfully");
        }
      }

      refetch();
      close();
    } catch (err: any) {
      if (err.response?.data) {
        alert(err.response.data);
      } else {
        alert(err.toString());
      }
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteUser = async () => {
    if (!data?._id) return;

    try {
      if (window.confirm("Are you sure you want to delete this user?")) {
        // Cast to any to avoid TypeScript errors with the response type
        const response: any = await deleteUser(data._id as any);

        if (response?.data) {
          alert(response.data);
        } else {
          alert("User deleted successfully");
        }

        refetch();
        close();
      }
    } catch (error: any) {
      alert(error?.toString());
    }
  };

  const handleLogoutDevices = async () => {
    if (!data?._id) return;

    try {
      if (
        window.confirm(
          "Are you sure you want to logout this user from all devices?"
        )
      ) {
        await logoutUser(data._id);
        alert("User successfully logged out from all devices");
      }
    } catch (error: any) {
      alert(error?.toString());
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      enableReinitialize
    >
      {(formik) => {
        const groupsOptions =
          entities?.data?.groups?.map((group: any) => ({
            label: group.title,
            value: group._id,
          })) || [];

        const rolesOptions =
          entities?.data?.roles?.map((role: any) => ({
            label: role.title,
            value: role._id,
          })) || [];

        const userGroups = formik.values.groups.map((group: any) => ({
          label: group.title,
          value: group._id,
        }));

        const userRoles = formik.values.roles.map((role: any) => ({
          label: role.title,
          value: role._id,
        }));

        return (
          <ModalWrapper>
            <ModalContent
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
            >
              <ModalHeader>
                <h2>{data?._id ? "Edit User" : "Add New User"}</h2>
                <IconButton
                  aria-label="close"
                  onClick={close}
                  size="small"
                  sx={{
                    color: "#666",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                      color: "#333",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </ModalHeader>

              <Form>
                <ModalBody>
                  <ActiveStatusSection />
                  <UserInfoSection />
                  <ContactInfoSection />
                  <IdentificationSection />
                  <PasswordSection
                    isEditing={!!data?._id}
                    generatePassword={() => {
                      const password = generateRandomPassword();
                      formik.setFieldValue("password", password);
                    }}
                  />
                  <GroupsAndRolesSection
                    groupsOptions={groupsOptions}
                    rolesOptions={rolesOptions}
                    userGroups={userGroups}
                    userRoles={userRoles}
                    onGroupsChange={(selectedGroups) => {
                      const newGroups = selectedGroups.map((groupId: string) =>
                        entities.data.groups.find((g: any) => g._id === groupId)
                      );
                      formik.setFieldValue("groups", newGroups);
                    }}
                    onRolesChange={(selectedRoles) => {
                      const newRoles = selectedRoles.map((roleId: string) =>
                        entities.data.roles.find((r: any) => r._id === roleId)
                      );
                      formik.setFieldValue("roles", newRoles);
                    }}
                  />
                  {data?._id && (
                    <DevicesSection
                      devices={formik.values.loggedDevices as DeviceInfo[]}
                      mobileLogStatus={formik.values.mobileLogStatus}
                      onLogoutDevices={handleLogoutDevices}
                      isLoggingOut={isLoggingOutUser}
                    />
                  )}
                  <UserRoleSection isAdmin={user.isAdmin} />
                  {formik.values.isModerator && <PermissionsSection />}
                  {/* Schedule Preferences Section */}
                  <SchedulePreferencesSection />
                </ModalBody>

                <ModalFooter>
                  <ActionButtons
                    isEditing={!!data?._id}
                    isSubmitting={isAddingNewUser || isUpdatingUser}
                    isDeleting={isDeletingUser}
                    onReset={() => formik.resetForm()}
                    onDelete={handleDeleteUser}
                    onSubmit={() => {
                      if (Object.keys(formik.errors).length > 0) {
                        formik.validateForm();
                        const errorsString = Object.values(formik.errors).join(
                          "\n"
                        );
                        alert(errorsString);
                      } else {
                        if (
                          window.confirm(
                            "Submit? Please make sure all the data is correct"
                          )
                        ) {
                          formik.handleSubmit();
                        }
                      }
                    }}
                  />
                </ModalFooter>
              </Form>
            </ModalContent>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default AddUsersModal;
