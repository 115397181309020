import { useState, useMemo, useContext, useEffect } from "react";
import { Context } from "..";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { SectionContainer, SectionTitle, colors } from "../styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useQueryClient } from "react-query";
import {
  useFetchEntities,
  useGetUserDaySchedulesThatIncludesTourGroup,
  useGetActiveUsersSectionedByGivenRoleIds,
  useAddOrUpdateUserDaySchedule,
} from "@/reactQueryHooks";

const SearchInputContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 14px;
  //border-radius: 8px;
  border: 1px solid ${colors.border};
  font-size: 12px;
  color: ${colors.textPrimary};
  //background: ${colors.light};
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  &:focus {
    outline: none;
    border-color: ${colors.primary};
    box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.1);
  }

  &::placeholder {
    color: ${colors.textSecondary};
  }
`;

const RoleItemContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-radius: 8px;
  background: ${({ isSelected }) =>
    isSelected ? colors.selected : colors.light};
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);

    .group-item-overlay {
      display: ${({ isSelected }) => (isSelected ? "none" : "flex")};
    }
  }

  .check-icon {
    color: #4ade80;
    display: ${({ isSelected }) => (isSelected ? "block" : "none")};
  }
`;

const RoleItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  display: none;
  flex-direction: row;
  z-index: 2;
`;

const RoleItemOverlayItem = styled.button`
  flex: 1;
  border: none;
  background: transparent;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primary};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(67, 97, 238, 0.1);
    color: ${colors.secondary};
  }

  &.disabled {
    color: ${colors.danger};
    cursor: not-allowed;
  }
`;

const RoleSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const RoleSectionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const SectionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
`;

const RoleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  border-radius: 8px;
  background: ${colors.primary};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  font-weight: 500;
  color: ${colors.light};
`;

const NoResults = styled.div`
  padding: 12px;
  text-align: center;
  color: ${colors.textSecondary};
  background: ${colors.light};
  border-radius: 8px;
`;

const InfoIconButton = styled(IconButton)`
  padding: 4px !important;
  color: ${colors.gray} !important;

  &:hover {
    color: ${colors.primary} !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
`;

//-------------------------------------------

const AvailableStaff = ({ tourGroup }) => {
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState("");
  const { data: entities } = useFetchEntities();
  const { setIsUpdating } = useContext(Context);

  const product = entities?.data?.products.find(
    (product) => product._id === tourGroup.product_id
  );
  const roles = product?.crewRoles || [];

  const { data: assignees } = useGetUserDaySchedulesThatIncludesTourGroup(
    tourGroup._id
  );

  const { mutateAsync: addOrUpdateUserDaySchedule, isLoading } =
    useAddOrUpdateUserDaySchedule();

  const { data: usersSectionedByRoleIds } =
    useGetActiveUsersSectionedByGivenRoleIds(roles);

  // Filter users based on search term
  const filteredRoles = useMemo(() => {
    if (!usersSectionedByRoleIds || !searchTerm.trim()) {
      return usersSectionedByRoleIds;
    }

    return usersSectionedByRoleIds.map((role) => ({
      ...role,
      users: role.users.filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
      // Keep track of original length for showing counts
      originalLength: role.users.length,
    }));
  }, [usersSectionedByRoleIds, searchTerm]);

  // Check if there are any results across all sections
  const hasResults = useMemo(() => {
    if (!filteredRoles) return false;
    return filteredRoles.some((role) => role.users.length > 0);
  }, [filteredRoles]);

  //------------------------------------------------------------------------

  useEffect(() => {
    setIsUpdating(isLoading);
  }, [isLoading, setIsUpdating]);

  //------------------------------------------------------------------------

  return (
    <SectionContainer>
      <SectionTitle>
        <i className="fas fa-list"></i>
        Available Staff
        <Tooltip
          title={`This list shows only users marked with status "Active"`}
          arrow
          placement="top"
        >
          <InfoIconButton size="small">
            <InfoIcon fontSize="small" />
          </InfoIconButton>
        </Tooltip>
      </SectionTitle>

      <SearchInputContainer>
        <SearchInput
          type="text"
          placeholder="Search staff by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchInputContainer>

      <SectionList>
        {!hasResults && searchTerm && (
          <NoResults>No staff members match your search</NoResults>
        )}

        {filteredRoles?.map((role) => {
          // Skip sections with no matching users when filtering
          if (searchTerm && role.users.length === 0) return null;

          return (
            <RoleSectionContainer key={uuidv4()}>
              <RoleHeader>
                <span>{role.title}</span>
                <span>
                  {searchTerm
                    ? `${role.users.length}/${
                        role.originalLength || role.users.length
                      }`
                    : role.users.length}
                </span>
              </RoleHeader>

              <RoleSectionList>
                {role.users.map((user) => {
                  const isSelected = assignees?.some(
                    (assignee) => assignee.id === user._id
                  );

                  return (
                    <RoleItemContainer key={uuidv4()} isSelected={isSelected}>
                      {user.name}
                      <RoleItemOverlay
                        className="group-item-overlay"
                        isSelected={isSelected}
                      >
                        <RoleItemOverlayItem
                          key={uuidv4()}
                          onClick={async () => {
                            await addOrUpdateUserDaySchedule({
                              user: user._id,
                              date: tourGroup.date,
                              isDayOff: false,
                              isLeave: false,
                              tourGroups: [
                                {
                                  id: tourGroup._id,
                                  role: role.id,
                                  projected: true,
                                },
                              ],
                              notify: false,
                            });

                            await queryClient.invalidateQueries({
                              queryKey: [
                                "USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP",
                                tourGroup._id,
                              ],
                            });
                          }}
                        >
                          {role.title}
                        </RoleItemOverlayItem>
                      </RoleItemOverlay>
                    </RoleItemContainer>
                  );
                })}
              </RoleSectionList>
            </RoleSectionContainer>
          );
        })}
      </SectionList>
    </SectionContainer>
  );
};

export default AvailableStaff;
