// src/components/AddUsersModal/sections/UserRoleSection.tsx
import React from "react";
import { useFormikContext } from "formik";
import Switch from "@mui/material/Switch";
import { UserFormValues } from "../types";
import { Section, SectionTitle, FormRow, ToggleWrapper } from "../styles";
import ToggleField from "../components/ToggleField";

interface UserRoleSectionProps {
  isAdmin: boolean;
}

const UserRoleSection = ({ isAdmin }: UserRoleSectionProps) => {
  const { values, setFieldValue } = useFormikContext<UserFormValues>();

  // Handle toggles that affect each other
  const handleAdminToggle = (checked: boolean) => {
    if (checked) {
      setFieldValue("isAdmin", true);
      setFieldValue("isModerator", false);
    } else {
      setFieldValue("isAdmin", false);
    }
  };

  const handleModeratorToggle = (checked: boolean) => {
    if (checked) {
      setFieldValue("isModerator", true);
      setFieldValue("isAdmin", false);
    } else {
      setFieldValue("isModerator", false);
    }
  };

  if (!isAdmin) return null;

  return (
    <Section>
      <SectionTitle>User Role</SectionTitle>

      <FormRow>
        {!values.isModerator && (
          <ToggleWrapper>
            <Switch
              checked={values.isAdmin}
              onChange={(e) => handleAdminToggle(e.target.checked)}
              size="small"
              color="success"
            />
            <label>Admin</label>
          </ToggleWrapper>
        )}

        {!values.isAdmin && (
          <ToggleWrapper>
            <Switch
              checked={values.isModerator}
              onChange={(e) => handleModeratorToggle(e.target.checked)}
              size="small"
              color="success"
            />
            <label>Moderator</label>
          </ToggleWrapper>
        )}
      </FormRow>

      <FormRow>
        <ToggleField
          name="shouldReceiveAnnouncements"
          label="Should receive announcements"
          disabled={false}
        />

        {(values.isAdmin || values.isModerator) && (
          <ToggleField
            name="isEmergencyContact"
            label="Emergency Contact"
            disabled={false}
          />
        )}
      </FormRow>
    </Section>
  );
};

export default UserRoleSection;
