import styled from "styled-components";
import { useState, useEffect, createContext } from "react";
import moment from "moment";
import SelectionBar from "../../../selection_bar/SelectionBar";
import TourGuidesBarContent from "../bar/TourGuidesBarContent";
import BarLoader from "react-spinners/BarLoader";
import AddTourArrangementModal from "./AddTourArrangementModal";
import ProposalModal from "./ProposalModal";
import GuideProposedModal from "./GuideProposedModal";
import { motion } from "framer-motion";
import Tooltip from "@mui/material/Tooltip";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import {
  useFetchTourGroupsByDateTourGuides,
  useFetchEntities,
} from "../../../../reactQueryHooks";
import TourGuidesTable from "./TourGuidesTable";
import { useAxios } from "../../../../axiosProvider";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { generateExcelFromTourGuidePlanner } from "../../day_planner_tour_groups/content/file_utilities";
// import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react"; // ADDS STYLING BUGS - CAUSING ISSUES
import SearchableMultiSelect from "../../settings/content/users/SearchableMultiSelect";

//----------------------------------------------------------------

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  overflow: hidden;
  position: relative;
`;
const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  flex-wrap: wrap;
`;

const TourGuideTableContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(0.5px);
  z-index: 100;
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: ${(props) => props.theme.colors.green};
  @media (min-width: 1332px) {
    opacity: 1;
  }

  opacity: 0.3;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 1;
    filter: brightness(1.1);
    transition: opacity 0.2s;
    transition: filter 0.2s;
  }
  i {
    font-size: 20px;
    color: white;
  }

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const SelectContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  top: 50px;
  right: 25px;
  z-index: 100;
  gap: 5px;
`;

const SelectActionButton = styled(motion.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(160 160 160);
  min-width: 100px;
  opacity: 0.6;
  gap: 10px;
  font-size: 12px;
  font-weight: bold;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: white;
  border: none;
  cursor: pointer;
  i {
    font-size: 16px;
  }
`;

const SelectButton = styled(motion.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.blue};
  opacity: 0.6;
  gap: 10px;
  font-size: 12px;
  font-weight: bold;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 1;
    filter: brightness(1.1);
    transition: opacity 0.2s;
    transition: filter 0.2s;
  }
  span {
    font-size: 12px;
    font-weight: bold;
  }
  i {
    font-size: 14px;
    color: white;
  }
`;

const FilterButton = styled.button`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  background: white;
  border: none;
  padding: 7px 12px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: dodgerblue;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  i {
    font-size: 10px;
  }
  //dont wrap content
  white-space: nowrap;
`;

const CheckAllButton = styled.button`
  width: 100%;
  background-color: whitesmoke;
  height: 40px;
  border-radius: 5px;
  &:hover {
    color: dodgerblue;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  gap: 5px;
`;

const LoadButton = styled.button`
  width: 100%;
  background-color: darkgreen;
  color: white;
  height: 40px;
  border-radius: 5px;
  &:hover {
    filter: brightness(1.2);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
`;

const GuidedFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  padding: 10px 0 10px 0;
`;

const GuideFilterItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  cursor: pointer;
`;

//------------------------------------------------------------------------
export const TourGuidesContentContext = createContext();
const TourGuidesContent = () => {
  const { data: entities } = useFetchEntities();
  const [uncheckAllProducts, setUncheckAllProducts] = useState(true);
  const [uncheckAllGuides, setUncheckAllGuides] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProposalModalOpen, setIsProposalModalOpen] = useState(false);
  const [isGuideProposedModalOpen, setIsGuideProposedModalOpen] =
    useState(false);
  const [IsListSelectable, setIsListSelectable] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allGuidedSelected, setAllGuidedSelected] = useState(true);
  const [includeUnassigned, setIncludeUnassigned] = useState(true);
  const [onlyUnassigned, setOnlyUnassigned] = useState(false);
  const [includeCancelled, setIncludeCancelled] = useState(true);
  const [showSelectButtons, setShowSelectButtons] = useState(false);

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin;
  const userIsPermittedToEditGuidePlan =
    user.permissions.editGuidePlan || userIsAdmin;

  const product_ids = entities.data.products
    .filter((product) =>
      allGuidedSelected
        ? product?.options?.some((option) => option?.is_guided === true)
        : true
    )
    .map((product) => product._id);
  const [selectedProductIds, setSelectedProductIds] = useState(product_ids);

  const filterGuided = () => {
    if (allGuidedSelected) {
      const allProductIds = entities.data.products.map(
        (product) => product._id
      );
      setSelectedProductIds(allProductIds);
      setAllGuidedSelected(false);
    } else {
      const guidedProductIds = entities.data.products
        .filter((product) =>
          product.options.some((option) => option.is_guided === true)
        )
        .map((product) => product._id);
      setSelectedProductIds(guidedProductIds);
      setAllGuidedSelected(true);
    }
  };

  //console.log(product_ids);
  //const sortedByName = entities.data.staff.sort((a, b) => a.name.localeCompare(b.name));
  const tour_guide_ids = [
    ...entities.data.staff
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((staff) =>
        staff.roles.some((role) => role._id === "636d1329f2535b158a9acd20")
      )
      .map((staff) => staff._id),
  ];
  const [start_date, setStart_date] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [selectedGuideIds, setSelectedGuideIds] = useState(tour_guide_ids);

  const guides = entities?.data?.staff
    .filter((staff) =>
      staff.roles.some((role) => role._id === "636d1329f2535b158a9acd20")
    )
    .map((guide) => {
      return {
        value: guide._id,
        label: guide.name,
      };
    });

  const selectedGuides = selectedGuideIds.map((id) => {
    const guide = entities.data.staff.find((guide) => guide._id === id);
    return {
      value: guide._id,
      label: guide.name,
    };
  });

  const products = entities?.data?.products
    .filter((product) =>
      allGuidedSelected
        ? product?.options?.some((option) => option?.is_guided === true)
        : true
    )
    .map((product) => {
      return {
        value: product._id,
        label: product.title,
      };
    });

  const selectedProducts = selectedProductIds.map((id) => {
    const { _id, title } = entities.data.products.find(
      (product) => product._id === id
    );
    return {
      value: _id,
      label: title,
    };
  });

  const {
    data: tourGroups,
    isFetching: isFetchingTourGroups,
    refetch,
    hasNextPage,
    fetchNextPage,
  } = useFetchTourGroupsByDateTourGuides({
    start_date,
    product_ids: selectedProductIds,
    guide_ids: selectedGuideIds,
    include_cancelled: includeCancelled,
    include_unassigned: includeUnassigned,
    only_unassigned: onlyUnassigned,
  });

  const allData = tourGroups?.pages?.flatMap((page) =>
    page.data.docs.map((doc, i) => {
      return {
        ...doc,
      };
    })
  );

  useEffect(() => {
    refetch();
  }, [includeCancelled, includeUnassigned, onlyUnassigned, refetch]);

  const handleDateChange = (action) => {
    if (action === "next") {
      setStart_date(moment(start_date).add(1, "days").format("YYYY-MM-DD"));
      setTimeout(() => {
        refetch();
      }, 100);
    } else {
      setStart_date(
        moment(start_date).subtract(1, "days").format("YYYY-MM-DD")
      );
      setTimeout(() => {
        refetch();
      }, 100);
    }
  };

  //-----------------------------------------------------------------------

  return (
    <TourGuidesContentContext.Provider
      value={{
        IsListSelectable,
        selectedItems,
        setSelectedItems,
      }}
    >
      <Wrapper>
        <SelectionBar>
          <TourGuidesBarContent
            date={start_date}
            setDate={(date) => {
              setStart_date(moment(date).format("YYYY-MM-DD"));
              setTimeout(() => {
                refetch();
              }, 100);
            }}
            handleDateChange={handleDateChange}
            refetch={refetch}
          />
        </SelectionBar>
        <ContentContainer>
          <FiltersContainer>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="bottom"
              overlay={
                <Popover>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      width: "100%",
                      minWidth: "300px",
                      flex: 1,
                      padding: "10px",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                      gap: "10px",
                    }}
                  >
                    <CheckAllButton
                      onClick={() => {
                        if (uncheckAllGuides) {
                          setSelectedGuideIds([]);
                          setUncheckAllGuides(false);
                        } else {
                          setSelectedGuideIds(
                            entities.data.staff
                              .filter((staff) =>
                                staff.roles.some(
                                  (role) =>
                                    role._id === "636d1329f2535b158a9acd20"
                                )
                              )
                              .map((guide) => guide._id)
                          );
                          setUncheckAllGuides(true);
                        }
                      }}
                    >
                      {uncheckAllGuides ? "Uncheck All" : "Check All"}
                      <i className="fa-solid fa-list-check"></i>
                    </CheckAllButton>
                    <SearchableMultiSelect
                      data={guides}
                      selectedData={selectedGuides}
                      onSelect={(data) => {
                        const newGuides = data.map((guideId) => {
                          return entities.data.staff.find(
                            (product) => product._id === guideId
                          );
                        });
                        setSelectedGuideIds(
                          newGuides.map((guide) => guide._id)
                        );
                      }}
                      searchable
                    />
                    <LoadButton
                      onClick={() => {
                        refetch();
                      }}
                    >
                      LOAD
                    </LoadButton>
                  </div>
                </Popover>
              }
            >
              <FilterButton>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <span>Select guides</span>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
              </FilterButton>
            </OverlayTrigger>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="bottom"
              overlay={
                <Popover>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      width: "100%",
                      minWidth: "300px",
                      flex: 1,
                      padding: "10px",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                      gap: "10px",
                    }}
                  >
                    <CheckAllButton
                      onClick={() => {
                        if (uncheckAllProducts) {
                          setSelectedProductIds([]);
                          setUncheckAllProducts(false);
                        } else {
                          setSelectedProductIds(
                            entities.data.products.map((product) => product._id)
                          );
                          setUncheckAllProducts(true);
                        }
                      }}
                    >
                      {uncheckAllProducts ? "Uncheck All" : "Check All"}
                      <i className="fa-solid fa-list-check"></i>
                    </CheckAllButton>

                    <SearchableMultiSelect
                      data={products}
                      selectedData={selectedProducts}
                      onSelect={(data) => {
                        const newProducts = data.map((productId) => {
                          return entities.data.products.find(
                            (product) => product._id === productId
                          );
                        });
                        setSelectedProductIds(
                          newProducts.map((product) => product._id)
                        );
                      }}
                      searchable
                    />
                    <GuidedFilterContainer>
                      <GuideFilterItem
                        onClick={() => {
                          filterGuided();
                          setUncheckAllProducts(true);
                        }}
                      >
                        <span>Only Guided</span>
                        <i
                          style={{
                            color: allGuidedSelected ? "darkgreen" : "grey",
                          }}
                          className={`fa-${
                            allGuidedSelected ? "solid" : "regular"
                          } fa-circle-check`}
                        ></i>
                      </GuideFilterItem>
                    </GuidedFilterContainer>
                    <LoadButton
                      onClick={() => {
                        refetch();
                      }}
                    >
                      LOAD
                    </LoadButton>
                  </div>
                </Popover>
              }
            >
              <FilterButton>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <span>Select products</span>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
              </FilterButton>
            </OverlayTrigger>

            <FilterButton
              onClick={() => {
                setIncludeCancelled(!includeCancelled);
              }}
            >
              <span>Include Cancelled</span>
              <i
                style={{
                  color: includeCancelled ? "darkgreen" : "grey",
                }}
                className={`fa-${
                  includeCancelled ? "solid" : "regular"
                } fa-circle-check`}
              ></i>
            </FilterButton>
            <FilterButton
              onClick={() => {
                setIncludeUnassigned(!includeUnassigned);
              }}
            >
              <span>Include Unassigned</span>
              <i
                style={{
                  color: includeUnassigned ? "darkgreen" : "grey",
                }}
                className={`fa-${
                  includeUnassigned ? "solid" : "regular"
                } fa-circle-check`}
              ></i>
            </FilterButton>
            <FilterButton
              onClick={() => {
                setIncludeCancelled(!includeCancelled);
                setOnlyUnassigned(!onlyUnassigned);
                //setIncludeUnassigned(!onlyUnassigned);
              }}
            >
              <span>Only Unassigned</span>
              <i
                style={{
                  color: onlyUnassigned ? "darkgreen" : "grey",
                }}
                className={`fa-${
                  onlyUnassigned ? "solid" : "regular"
                } fa-circle-check`}
              ></i>
            </FilterButton>
          </FiltersContainer>
          <TourGuideTableContainer>
            <TourGuidesTable
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              data={allData?.map((tourGroup) => {
                const guide = entities?.data?.staff?.find(
                  (entity) => entity._id === tourGroup?.guide_id
                );

                const guide_name = guide?.name || "";
                const guide_afm = guide?.afm_number || "";
                const guide_amka = guide?.amka_number || "";
                const guide_reg_number = guide?.guide_reg_number || "";

                const product = entities?.data?.products?.find(
                  (product) => product._id === tourGroup.product_id
                );
                const option = product.options.find(
                  (option) => option._id === tourGroup.option_id
                );
                const startTime = product?.start_times.find(
                  (startTime) => startTime._id === tourGroup.start_time_id
                );

                return {
                  tour_group_id: tourGroup._id,
                  date: tourGroup.date,
                  time: startTime?.time_slot,
                  client_name: tourGroup.first_booking_name,
                  guide_id: tourGroup.guide_id,
                  guidename: guide_name,
                  guide_afm: guide_afm,
                  guide_amka: guide_amka,
                  guide_reg_number: guide_reg_number,
                  uds_id: tourGroup?.guide_uds_id,
                  guide_confirmation: tourGroup.guide_confirmation,
                  guides_asked: tourGroup.guides_asked,
                  guide_email_sent: tourGroup.guide_email_sent,
                  guide_details: tourGroup.guide_details,
                  product: product.title,
                  option: option?.title,
                  seatsCount: tourGroup.seatsCount,
                  bookingsCount: tourGroup.bookings.length,
                  pickup_location: tourGroup.pickup_location,
                  notes: tourGroup.notes,
                  isCancelled: tourGroup.isCancelled,
                };
              })}
            />

            {!isModalOpen && (
              <SelectContainer onHoverEnd={() => setShowSelectButtons(false)}>
                <SelectButton
                  onHoverStart={() => setShowSelectButtons(true)}
                  onClick={() => {
                    if (!userIsPermittedToEditGuidePlan) {
                      alert("You are not permitted to edit the guide plan");
                      return;
                    }
                    setIsListSelectable(!IsListSelectable);
                  }}
                >
                  <span>SELECT</span>
                  {IsListSelectable && (
                    <i
                      style={{
                        color: "indianred",
                      }}
                      className="fa-solid fa-times"
                    ></i>
                  )}
                </SelectButton>
                {IsListSelectable && (
                  <Tooltip
                    title={`GENERATE EXCEL FILE FOR ${selectedItems.length} ASSIGNMENTS`}
                    placement="right"
                  >
                    <SelectActionButton
                      initial={{ opacity: 0, x: 50 }}
                      animate={{
                        opacity: showSelectButtons ? 1 : 0,
                        x: showSelectButtons ? 0 : 50,
                      }}
                      transition={{ duration: 0.2 }}
                      onClick={() => {
                        if (selectedItems.length === 0) {
                          alert(
                            "Please select at least one assignment to compose a proposal"
                          );
                          return;
                        }

                        const excelData = allData
                          .filter((tourGroup) =>
                            selectedItems.includes(tourGroup._id)
                          )
                          .map((item) => {
                            const productIdentifier =
                              entities.data.products.find(
                                (product) => product._id === item.product_id
                              ).guide_assignment_identifier;
                            return {
                              date: moment(item.date).format("DD/MM/YY"),
                              activity: productIdentifier,
                              time: item.time,
                              guide_confirmation: item.guide_confirmation,
                            };
                          });

                        if (window.confirm("Generate excel file?")) {
                          generateExcelFromTourGuidePlanner(excelData);
                        }
                      }}
                    >
                      <i className="fa-solid fa-file-excel"></i>
                    </SelectActionButton>
                  </Tooltip>
                )}
                {IsListSelectable && (
                  <Tooltip
                    title={`COMPOSE PROPOSAL FOR ${selectedItems.length} ASSIGNMENTS`}
                    placement="right"
                  >
                    <SelectActionButton
                      initial={{ opacity: 0, x: 50 }}
                      animate={{
                        opacity: showSelectButtons ? 1 : 0,
                        x: showSelectButtons ? 0 : 50,
                      }}
                      transition={{ duration: 0.3 }}
                      onClick={() => {
                        if (selectedItems.length === 0) {
                          alert(
                            "Please select at least one assignment to compose a proposal"
                          );
                          return;
                        }
                        setIsProposalModalOpen(true);
                      }}
                    >
                      <i className="fa-solid fa-envelope"></i>
                    </SelectActionButton>
                  </Tooltip>
                )}
                {IsListSelectable && (
                  <Tooltip
                    title={`GUIDE PROPOSED FOR ${selectedItems.length} ASSIGNMENTS`}
                    placement="right"
                  >
                    <SelectActionButton
                      initial={{ opacity: 0, x: 50 }}
                      animate={{
                        opacity: showSelectButtons ? 1 : 0,
                        x: showSelectButtons ? 0 : 50,
                      }}
                      transition={{ duration: 0.4 }}
                      onClick={() => {
                        if (selectedItems.length === 0) {
                          alert("Please select at least one assignment");
                          return;
                        }
                        setIsGuideProposedModalOpen(true);
                      }}
                    >
                      <MarkChatReadIcon
                        style={{
                          fontSize: "16px",
                        }}
                      />
                    </SelectActionButton>
                  </Tooltip>
                )}
              </SelectContainer>
            )}
          </TourGuideTableContainer>
          {isFetchingTourGroups && (
            <LoadingOverlay>
              <BarLoader color="#606060" loading={true} />
            </LoadingOverlay>
          )}

          {isModalOpen && (
            <AddTourArrangementModal
              close={() => {
                setIsModalOpen(false);
              }}
              refetch={refetch}
            />
          )}
          {isProposalModalOpen && (
            <ProposalModal
              close={() => {
                setIsProposalModalOpen(false);
              }}
              //tour groups of selectedItems
              data={allData.filter((tourGroup) =>
                selectedItems.includes(tourGroup._id)
              )}
            />
          )}
          {isGuideProposedModalOpen && (
            <GuideProposedModal
              refetch={refetch}
              close={() => {
                setIsGuideProposedModalOpen(false);
              }}
              data={allData.filter((tourGroup) =>
                selectedItems.includes(tourGroup._id)
              )}
            />
          )}

          {!isModalOpen && (
            <AddButton
              onClick={() => {
                if (!userIsPermittedToEditGuidePlan) {
                  alert("You are not permitted to edit the guide plan");
                  return;
                }
                setIsModalOpen(true);
              }}
            >
              <i className="fa-solid fa-plus"></i>
            </AddButton>
          )}
        </ContentContainer>
      </Wrapper>
    </TourGuidesContentContext.Provider>
  );
};

export default TourGuidesContent;
