import styled from "styled-components";
import { SectionContainer, SectionTitle, colors } from "../styles";

const StatusButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const StatusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 6px;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  border: 1px solid transparent;

  background-color: ${(props) =>
    props.isActive
      ? props.type === "dayOff"
        ? colors.dayOff
        : colors.leave
      : "transparent"};

  color: ${(props) =>
    props.isActive
      ? "white"
      : props.type === "dayOff"
      ? colors.dayOff
      : colors.leave};

  border-color: ${(props) =>
    props.type === "dayOff" ? colors.dayOff : colors.leave};

  &:hover {
    background-color: ${(props) =>
      props.type === "dayOff" ? colors.dayOffHover : colors.leaveHover};
    color: white;
  }
`;

const ProjectedStatusCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  background-color: ${(props) =>
    props.type === "dayOff"
      ? "rgba(76, 175, 80, 0.1)"
      : "rgba(156, 39, 176, 0.1)"};
  border: 1px dashed
    ${(props) => (props.type === "dayOff" ? colors.dayOff : colors.leave)};
`;

const CornerRibbon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
  z-index: auto;
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 12px;
    background-color: ${(props) =>
      props.isProjected ? "rgba(204, 85, 0, 0.7)" : "rgba(46, 139, 87, 0.7)"};
    transform: rotate(-45deg) translateY(-15px) translateX(-15px);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
`;

const StatusInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusLabel = styled.span`
  font-weight: 600;
  color: ${(props) => (props.type === "dayOff" ? colors.dayOff : colors.leave)};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: ${(props) => (props.primary ? colors.primary : "white")};
  color: ${(props) => (props.primary ? "white" : colors.textPrimary)};
  border: 1px solid
    ${(props) => (props.primary ? "transparent" : colors.border)};

  &:hover {
    background-color: ${(props) =>
      props.primary ? colors.secondary : colors.light};
  }
`;

const ProjectedStatus = ({
  scheduleData,
  onAddProjectedDayOff,
  onAddProjectedLeave,
  onRemoveProjectedStatus,
  onFinalizeStatus,
  onRemoveFinalizedStatus,
}) => {
  const hasProjectedDayOff = scheduleData?.isProjectedDayOff;
  const hasProjectedLeave = scheduleData?.isProjectedLeave;
  const isDayOff = scheduleData?.isDayOff;
  const isLeave = scheduleData?.isLeave;

  return (
    <SectionContainer>
      <SectionTitle>
        <i className="fas fa-calendar-alt"></i>
        Status Management
      </SectionTitle>

      {!hasProjectedDayOff && !hasProjectedLeave && !isDayOff && !isLeave && (
        <StatusButtonsContainer>
          <StatusButton
            type="dayOff"
            onClick={onAddProjectedDayOff}
            title="Mark as projected day off"
          >
            <i className="fas fa-home"></i>
            Set Projected Day Off
          </StatusButton>
          <StatusButton
            type="leave"
            onClick={onAddProjectedLeave}
            title="Mark as projected leave"
          >
            <i className="fas fa-umbrella-beach"></i>
            Set Projected Leave
          </StatusButton>
        </StatusButtonsContainer>
      )}

      {hasProjectedDayOff && (
        <ProjectedStatusCard type="dayOff">
          <CornerRibbon isProjected={true} />
          <StatusInfo>
            <StatusLabel type="dayOff">Projected Day Off</StatusLabel>
          </StatusInfo>
          <ActionButtons>
            <ActionButton onClick={() => onRemoveProjectedStatus("dayOff")}>
              Remove
            </ActionButton>
            <ActionButton primary onClick={() => onFinalizeStatus("dayOff")}>
              Finalize
            </ActionButton>
          </ActionButtons>
        </ProjectedStatusCard>
      )}

      {hasProjectedLeave && (
        <ProjectedStatusCard type="leave">
          <CornerRibbon isProjected={true} />
          <StatusInfo>
            <StatusLabel type="leave">Projected Leave</StatusLabel>
          </StatusInfo>
          <ActionButtons>
            <ActionButton onClick={() => onRemoveProjectedStatus("leave")}>
              Remove
            </ActionButton>
            <ActionButton primary onClick={() => onFinalizeStatus("leave")}>
              Finalize
            </ActionButton>
          </ActionButtons>
        </ProjectedStatusCard>
      )}

      {isDayOff && (
        <ProjectedStatusCard type="dayOff">
          <CornerRibbon isProjected={false} />
          <StatusInfo>
            <StatusLabel type="dayOff">Day Off</StatusLabel>
          </StatusInfo>
          <ActionButtons>
            <ActionButton
              onClick={() => {
                if (window.confirm("Remove day off?")) {
                  onRemoveFinalizedStatus("dayOff");
                }
              }}
            >
              Remove
            </ActionButton>
          </ActionButtons>
        </ProjectedStatusCard>
      )}

      {isLeave && (
        <ProjectedStatusCard type="leave">
          <CornerRibbon isProjected={false} />
          <StatusInfo>
            <StatusLabel type="leave">Leave</StatusLabel>
          </StatusInfo>
          <ActionButtons>
            <ActionButton
              onClick={() => {
                if (window.confirm("Remove leave?")) {
                  onRemoveFinalizedStatus("leave");
                }
              }}
            >
              Remove
            </ActionButton>
          </ActionButtons>
        </ProjectedStatusCard>
      )}
    </SectionContainer>
  );
};

export default ProjectedStatus;
