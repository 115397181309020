// TourGroupList.tsx
import React from "react";
import styled from "styled-components";
import { DateSection as DateSectionType, TourGroup } from "./types";
import TourGroupItem from "./TourGroupItem";

//-------------------------------------------------------------------

const ListContainer = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-right: 5px;
`;

//------------------------------------------------------

const TourGroupList = ({
  tourGroups,
}: {
  tourGroups: TourGroup[] | undefined;
}) => {
  if (!tourGroups || tourGroups?.length === 0) {
    return (
      <div>
        <div>No tour groups found</div>
        <div style={{ fontSize: "12px", marginTop: "8px" }}>
          There are no tour groups scheduled for this day
        </div>
      </div>
    );
  }

  return (
    <ListContainer>
      {tourGroups?.map((tourGroup) => (
        <TourGroupItem key={tourGroup._id} tourGroup={tourGroup} />
      ))}
    </ListContainer>
  );
};

export default TourGroupList;
