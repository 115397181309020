import { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Fab from "@mui/material/Fab";
import CloseIcon from "@mui/icons-material/Close";
import {
  useFetchTourGroupsBySpecificDate,
  useFetchEntities,
  useFetchTourGroupsBySpecificDateForSchedule,
} from "@/reactQueryHooks";
import { useAxios } from "@/axiosProvider";
import { useQueryClient } from "react-query";
// Components
import Header from "./components/Header";
import ProjectedStatus from "./components/ProjectedStatus";
import ProjectedSchedule from "./components/ProjectedSchedule";
import AssignedSchedule from "./components/AssignedSchedule";
import AvailableGroups from "./components/AvailableGroups";
import CommentsSection from "./components/CommentsSection";

// Styled Components
import { Container, ScrollableContent, colors } from "./styles";

const TopButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

// Loader overlay
const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 8px;
`;

const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  i {
    color: ${colors.primary};
    font-size: 24px;
  }

  span {
    color: ${colors.textPrimary};
    font-weight: 500;
  }
`;

const ScheduleAssignmentDrawerContent = ({
  date,
  user,
  scheduleData,
  addOrUpdateUserDaySchedule,
  isUpdating,
  closeDrawer,
}) => {
  const queryClient = useQueryClient();
  const [commentInput, setCommentInput] = useState("");
  const { user: currentUser } = useAxios();
  const hasScheduleData = scheduleData !== null;
  const isDayOff = hasScheduleData && scheduleData.isDayOff;
  const isLeave = hasScheduleData && scheduleData.isLeave;
  const isProjectedDayOff = hasScheduleData && scheduleData.isProjectedDayOff;
  const isProjectedLeave = hasScheduleData && scheduleData.isProjectedLeave;
  const hasComments = hasScheduleData && scheduleData.comments?.length > 0;
  const { data: entities } = useFetchEntities();
  // const {
  //   data: tourGroupsBySpecificDate,
  //   isFetching: isFetchingBySpecificDate,
  // } = useFetchTourGroupsBySpecificDate(moment(date).format("YYYY-MM-DD"));

  const {
    data: tourGroupsBySpecificDate,
    isFetching: isFetchingBySpecificDate,
  } = useFetchTourGroupsBySpecificDateForSchedule(
    moment(date).format("YYYY-MM-DD")
  );

  const dateHasTourGroups = tourGroupsBySpecificDate?.length > 0;

  const handleAddComment = async () => {
    if (!commentInput.trim()) return;

    await addOrUpdateUserDaySchedule({
      ...scheduleData,
      user: user.value,
      date: moment(new Date(date)).format("YYYY-MM-DD"),
      _id: scheduleData?._id,
      tourGroups: scheduleData?.tourGroups || [],
      comments: [
        ...(scheduleData?.comments || []),
        {
          text: commentInput,
          user: user.label,
          date: moment(new Date()).format("DD-MM-YYYY HH:mm"),
          author_id: currentUser._id,
        },
      ],
      notify: false,
    });
    setCommentInput("");
  };

  const handleRemoveAssignedTourGroup = async (tourGroupItemId) => {
    const tourGroupToRemove = scheduleData?.tourGroups.find(
      (tourGroup) => tourGroup._id === tourGroupItemId
    );
    const newTourGroups = scheduleData?.tourGroups.filter(
      (tourGroup, i) => tourGroup._id !== tourGroupItemId
    );
    if (window.confirm("Remove this assignment ?")) {
      await addOrUpdateUserDaySchedule({
        ...scheduleData,
        user: user.value,
        date: moment(new Date(date)).format("YYYY-MM-DD"),
        _id: scheduleData?._id,
        tourGroups: newTourGroups,
        notify: true, // Notify when removing from assignments
      });

      await queryClient.invalidateQueries({
        queryKey: [
          "USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP",
          tourGroupToRemove._data._id,
        ],
      });
    }
  };

  const handleRemoveProjectedTourGroup = async (tourGroupItemId) => {
    const tourGroupToRemove = scheduleData?.tourGroups.find(
      (tourGroup) => tourGroup._id === tourGroupItemId
    );

    const newTourGroups = scheduleData?.tourGroups.filter(
      (tourGroup, i) => tourGroup._id !== tourGroupItemId
    );
    await addOrUpdateUserDaySchedule({
      ...scheduleData,
      user: user.value,
      date: moment(new Date(date)).format("YYYY-MM-DD"),
      _id: scheduleData?._id,
      tourGroups: newTourGroups,
      notify: false, // No notification when removing from projected
    });

    await queryClient.invalidateQueries({
      queryKey: [
        "USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP",
        tourGroupToRemove._data._id,
      ],
    });
  };

  const handleAddToAssignments = async (tourGroupItemId) => {
    const tourGroupToAdd = scheduleData?.tourGroups.find(
      (tourGroup) => tourGroup._id === tourGroupItemId
    );
    const newTourGroups = scheduleData?.tourGroups.map((tg) => {
      if (tg._id === tourGroupItemId) {
        return {
          ...tg,
          projected: false, // Set projected to false when adding to assignments
        };
      }
      return tg;
    });

    await addOrUpdateUserDaySchedule({
      ...scheduleData,
      user: user.value,
      date: moment(new Date(date)).format("YYYY-MM-DD"),
      _id: scheduleData?._id,
      tourGroups: newTourGroups,
      notify: true, // Notify when adding to assignments
    });

    await queryClient.invalidateQueries({
      queryKey: [
        "USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP",
        tourGroupToAdd._data._id,
      ],
    });
  };

  // New functions for projected status
  const handleAddProjectedDayOff = async () => {
    await addOrUpdateUserDaySchedule({
      ...scheduleData,
      user: user.value,
      date: moment(new Date(date)).format("YYYY-MM-DD"),
      isProjectedDayOff: true,
      isProjectedLeave: false,
      isDayOff: false,
      isLeave: false,
      _id: scheduleData?._id,
      notify: false,
    });
  };

  const handleAddProjectedLeave = async () => {
    await addOrUpdateUserDaySchedule({
      ...scheduleData,
      user: user.value,
      date: moment(new Date(date)).format("YYYY-MM-DD"),
      isProjectedLeave: true,
      isProjectedDayOff: false,
      isDayOff: false,
      isLeave: false,
      _id: scheduleData?._id,
      notify: false,
    });
  };

  const handleRemoveProjectedStatus = async (type) => {
    await addOrUpdateUserDaySchedule({
      ...scheduleData,
      user: user.value,
      date: moment(new Date(date)).format("YYYY-MM-DD"),
      isProjectedDayOff:
        type === "dayOff" ? false : scheduleData?.isProjectedDayOff,
      isProjectedLeave:
        type === "leave" ? false : scheduleData?.isProjectedLeave,
      _id: scheduleData?._id,
      notify: false,
    });
  };

  const handleFinalizeStatus = async (type) => {
    const allTourGroupIds = scheduleData?.tourGroups.map((tg) => tg._data._id);

    await addOrUpdateUserDaySchedule({
      ...scheduleData,
      user: user.value,
      date: moment(new Date(date)).format("YYYY-MM-DD"),
      isDayOff: type === "dayOff" ? true : false,
      isLeave: type === "leave" ? true : false,
      isProjectedDayOff: false,
      isProjectedLeave: false,
      _id: scheduleData?._id,
      tourGroups: [], // Clear all tour groups when finalizing day off/leave
      notify: true,
    });

    await Promise.all(
      allTourGroupIds.map(async (tourGroupId) => {
        queryClient.invalidateQueries({
          queryKey: ["USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP", tourGroupId],
        });
      })
    );
  };

  const handleRemoveFinalizedStatus = async (type) => {
    // Get all tour group IDs to properly invalidate queries
    const allTourGroupIds =
      scheduleData?.tourGroups.map((tg) => tg._data._id) || [];

    // Update the day schedule with the appropriate status removed
    await addOrUpdateUserDaySchedule({
      ...scheduleData,
      user: user.value,
      date: moment(new Date(date)).format("YYYY-MM-DD"),
      isDayOff: type === "dayOff" ? false : scheduleData?.isDayOff,
      isLeave: type === "leave" ? false : scheduleData?.isLeave,
      _id: scheduleData?._id,
      notify: true,
    });

    // Invalidate all relevant queries
    await Promise.all(
      allTourGroupIds.map(async (tourGroupId) => {
        queryClient.invalidateQueries({
          queryKey: ["USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP", tourGroupId],
        });
      })
    );
  };

  const handleDeleteComment = async (commentId) => {
    const newComments = scheduleData.comments.filter(
      (c) => c._id !== commentId
    );
    await addOrUpdateUserDaySchedule({
      ...scheduleData,
      user: user.value,
      date: moment(new Date(date)).format("YYYY-MM-DD"),
      _id: scheduleData?._id,
      tourGroups: scheduleData?.tourGroups || [],
      comments: newComments,
      notify: false,
    });
  };

  const addTourGroup = async (tourGroup, roleId) => {
    await addOrUpdateUserDaySchedule({
      ...scheduleData,
      user: user.value,
      date: moment(new Date(date)).format("YYYY-MM-DD"),
      isDayOff: false,
      isLeave: false,
      _id: scheduleData?._id,
      tourGroups: [
        ...(scheduleData?.tourGroups || []),
        {
          id: tourGroup._id,
          role: roleId,
          projected: true, // Set new assignments as projected by default
        },
      ],
      notify: false, // No notification when adding to projected
    });

    await queryClient.invalidateQueries({
      queryKey: ["USER_DAY_SCHEDULES_THAT_INCLUDE_TOUR_GROUP", tourGroup._id],
    });
  };

  // Define pulse keyframes in a style object
  const pulseKeyframes = `
    @keyframes pulse {
      0% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1); }
      70% { box-shadow: 0 0 0 6px rgba(0, 0, 0, 0); }
      100% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }
    }
  `;

  return (
    <Container>
      <style>{pulseKeyframes}</style>

      {isUpdating && (
        <LoaderOverlay>
          <LoaderContent>
            <i className="fas fa-spinner fa-spin"></i>
            <span>Updating...</span>
          </LoaderContent>
        </LoaderOverlay>
      )}

      <TopButtonsRow onClick={() => closeDrawer()}>
        <CloseIcon fontSize="small" />
      </TopButtonsRow>

      <Header userName={user.label} date={date} />

      <ScrollableContent>
        <ProjectedStatus
          scheduleData={scheduleData}
          onAddProjectedDayOff={handleAddProjectedDayOff}
          onAddProjectedLeave={handleAddProjectedLeave}
          onRemoveProjectedStatus={handleRemoveProjectedStatus}
          onFinalizeStatus={handleFinalizeStatus}
          onRemoveFinalizedStatus={handleRemoveFinalizedStatus}
        />

        <ProjectedSchedule
          scheduleData={scheduleData}
          entities={entities?.data}
          isDayOff={isDayOff}
          isLeave={isLeave}
          isProjectedDayOff={isProjectedDayOff}
          isProjectedLeave={isProjectedLeave}
          onRemoveTourGroup={handleRemoveProjectedTourGroup}
          onAddToAssignments={handleAddToAssignments}
        />

        <AssignedSchedule
          scheduleData={scheduleData}
          entities={entities?.data}
          isDayOff={isDayOff}
          isLeave={isLeave}
          onRemoveTourGroup={handleRemoveAssignedTourGroup}
        />

        <AvailableGroups
          scheduleData={scheduleData}
          entities={entities?.data}
          tourGroupsBySpecificDate={tourGroupsBySpecificDate}
          isFetchingBySpecificDate={isFetchingBySpecificDate}
          dateHasTourGroups={dateHasTourGroups}
          isDayOff={isDayOff}
          isLeave={isLeave}
          isProjectedDayOff={isProjectedDayOff}
          isProjectedLeave={isProjectedLeave}
          user={user}
          onAddTourGroup={addTourGroup}
        />

        <CommentsSection
          commentInput={commentInput}
          setCommentInput={setCommentInput}
          scheduleData={scheduleData}
          entities={entities?.data}
          hasComments={hasComments}
          onAddComment={handleAddComment}
          onDeleteComment={handleDeleteComment}
        />
      </ScrollableContent>
    </Container>
  );
};

export default ScheduleAssignmentDrawerContent;
