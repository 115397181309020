import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import {
  SectionContainer,
  SectionTitle,
  SectionOverlay,
  StatusBadge,
  EmptyMessage,
  Badge,
  colors,
} from "../styles";

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProductItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-radius: 8px;
  background: ${colors.assigned};
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  &:hover {
    background: ${colors.light};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  }
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  span {
    color: ${colors.textPrimary};
  }
`;

const RemoveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background: rgba(247, 37, 133, 0.1);
  margin-left: 8px;
  color: ${colors.danger};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(247, 37, 133, 0.2);
    transform: rotate(90deg);
  }
`;

const AssignedSchedule = ({
  scheduleData,
  entities,
  isDayOff,
  isLeave,
  onRemoveTourGroup,
}) => {
  // Filter tour groups that have projected === false or undefined (for backward compatibility)
  const assignedTourGroups = scheduleData?.tourGroups?.filter(
    (tourGroup) =>
      tourGroup.projected === false || tourGroup.projected === undefined
  );

  const hasAssignedGroups = assignedTourGroups && assignedTourGroups.length > 0;

  return (
    <SectionContainer disabled={isDayOff || isLeave}>
      <SectionTitle>
        <i className="fas fa-clipboard-list"></i>
        Assignments
        {(isDayOff || isLeave) && (
          <StatusBadge isLeave={isLeave}>
            {isDayOff ? "Day Off" : "On Leave"}
          </StatusBadge>
        )}
      </SectionTitle>
      {!hasAssignedGroups && <EmptyMessage>No schedule assigned</EmptyMessage>}
      {hasAssignedGroups && (
        <ProductList>
          {assignedTourGroups.map((tourGroup, index) => {
            const product = entities?.products?.find(
              (product) => product._id === tourGroup?._data?.product_id
            );
            const option = product?.options?.find(
              (option) => option._id === tourGroup?._data?.option_id
            );
            const startTime = product?.start_times?.find(
              (start_time) => start_time._id === tourGroup?._data?.start_time_id
            );
            const assignedRoleId = tourGroup?.role;
            const role = entities?.roles?.find(
              (crewRole) => crewRole._id === assignedRoleId || null
            );
            const hasData = tourGroup._data !== null;

            return (
              <ProductItemContainer key={uuidv4()}>
                {hasData ? (
                  <>
                    <ProductInfo>
                      <span>{`${product?.title} - ${option?.title}`}</span>
                      <Badge>{role?.title}</Badge>
                      <span>{startTime?.time_slot}</span>
                      <span
                        style={{ color: colors.gray }}
                      >{`#${tourGroup._data.index}`}</span>
                    </ProductInfo>
                    <RemoveButton
                      onClick={() => onRemoveTourGroup(tourGroup._id)}
                    >
                      <i className="fa-solid fa-times"></i>
                    </RemoveButton>
                  </>
                ) : (
                  <span>Invalid group</span>
                )}
              </ProductItemContainer>
            );
          })}
        </ProductList>
      )}
    </SectionContainer>
  );
};

export default AssignedSchedule;
