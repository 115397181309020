import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import {
  SectionContainer,
  SectionTitle,
  SectionOverlay,
  StatusBadge,
  EmptyMessage,
  Badge,
  colors,
} from "../styles";

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProductItemContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-radius: 8px;
  background: ${colors.assigned};
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  &:hover {
    background: ${colors.light};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);

    .item-overlay {
      display: flex;
    }
  }
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  span {
    color: ${colors.textPrimary};
  }
`;

const ProjectedBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 500;
  background: ${colors.warning};
  color: white;
  margin-left: 5px;
`;

const ItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  border-radius: 8px;
  display: none;
  flex-direction: row;
  z-index: 2;
`;

const OverlayButton = styled.button`
  flex: 1;
  border: none;
  background: transparent;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &.confirm {
    color: ${colors.success};

    &:hover {
      background: rgba(76, 201, 240, 0.1);
      color: ${colors.primary};
    }
  }

  &.remove {
    color: ${colors.danger};

    &:hover {
      background: rgba(247, 37, 133, 0.1);
    }
  }

  i {
    margin-right: 5px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InfoIconButton = styled(IconButton)`
  padding: 4px !important;
  color: ${colors.gray} !important;

  &:hover {
    color: ${colors.primary} !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
`;

const tooltipText =
  "Preliminary assignments that are not shown to the user until added to assignments";

const ProjectedSchedule = ({
  scheduleData,
  entities,
  isDayOff,
  isLeave,
  onRemoveTourGroup,
  onAddToAssignments,
}) => {
  // Filter tour groups that have projected === true
  const projectedTourGroups = scheduleData?.tourGroups?.filter(
    (tourGroup) => tourGroup.projected === true
  );

  const hasProjectedGroups =
    projectedTourGroups && projectedTourGroups.length > 0;

  return (
    <SectionContainer disabled={isDayOff || isLeave}>
      <SectionTitle>
        <TitleContainer>
          <i className="fas fa-clock"></i>
          <span>Projected Assignments</span>
          <Tooltip title={tooltipText} arrow placement="top">
            <InfoIconButton size="small">
              <InfoIcon fontSize="small" />
            </InfoIconButton>
          </Tooltip>
        </TitleContainer>
        {(isDayOff || isLeave) && (
          <StatusBadge isLeave={isLeave}>
            {isDayOff ? "Day Off" : "On Leave"}
          </StatusBadge>
        )}
      </SectionTitle>


      {!hasProjectedGroups && (
        <EmptyMessage>No projected assignments</EmptyMessage>
      )}

      {hasProjectedGroups && (
        <ProductList>
          {projectedTourGroups.map((tourGroup, index) => {
            const product = entities?.products?.find(
              (product) => product._id === tourGroup?._data?.product_id
            );
            const option = product?.options?.find(
              (option) => option._id === tourGroup?._data?.option_id
            );
            const startTime = product?.start_times?.find(
              (start_time) => start_time._id === tourGroup?._data?.start_time_id
            );
            const assignedRoleId = tourGroup?.role;
            const role = entities?.roles?.find(
              (crewRole) => crewRole._id === assignedRoleId || null
            );
            const hasData = tourGroup._data !== null;

            return (
              <ProductItemContainer key={uuidv4()}>
                {hasData ? (
                  <>
                    <ProductInfo>
                      <span>{`${product?.title} - ${option?.title}`}</span>
                      <Badge>{role?.title}</Badge>
                      <span>{startTime?.time_slot}</span>
                      <span
                        style={{ color: colors.gray }}
                      >{`#${tourGroup._data.index}`}</span>
                      <ProjectedBadge>Projected</ProjectedBadge>
                    </ProductInfo>

                    <ItemOverlay className="item-overlay">
                      <OverlayButton
                        className="confirm"
                        onClick={() => {
                          if (window.confirm("Add to assignments?")) {
                            onAddToAssignments(tourGroup._id);
                          }
                        }}
                      >
                        <i className="fas fa-check-circle"></i>
                        Add to Assignments
                      </OverlayButton>
                      <OverlayButton
                        className="remove"
                        onClick={() => onRemoveTourGroup(tourGroup._id)}
                      >
                        <i className="fas fa-times-circle"></i>
                        Remove
                      </OverlayButton>
                    </ItemOverlay>
                  </>
                ) : (
                  <span>Invalid group</span>
                )}
              </ProductItemContainer>
            );
          })}
        </ProductList>
      )}
    </SectionContainer>
  );
};

export default ProjectedSchedule;
