// src/components/AddUsersModal/sections/IdentificationSection.tsx
import React from "react";
import { useFormikContext } from "formik";
import { UserFormValues } from "../types";
import { Section, SectionTitle, FormRow, FormGroup } from "../styles";
import TextField from "@mui/material/TextField";

const IdentificationSection = () => {
  const { values, handleChange, handleBlur } =
    useFormikContext<UserFormValues>();

  return (
    <Section>
      <SectionTitle>Identification Details</SectionTitle>
      <FormRow>
        <FormGroup>
          <TextField
            id="id_number"
            name="id_number"
            label="ID Number"
            placeholder="Enter ID number"
            value={values.id_number}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="filled"
            size="small"
            fullWidth
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: values.id_number ? "#4caf50" : undefined,
                },
                "&:hover fieldset": {
                  borderColor: values.id_number ? "#4caf50" : undefined,
                },
                "&.Mui-focused fieldset": {
                  borderColor: values.id_number ? "#4caf50" : undefined,
                },
                backgroundColor: values.id_number
                  ? "rgba(76, 175, 80, 0.05)"
                  : undefined,
              },
            }}
          />
        </FormGroup>

        <FormGroup>
          <TextField
            id="afm_number"
            name="afm_number"
            label="AFM Number"
            placeholder="Enter AFM number"
            value={values.afm_number}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="filled"
            size="small"
            fullWidth
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: values.afm_number ? "#4caf50" : undefined,
                },
                "&:hover fieldset": {
                  borderColor: values.afm_number ? "#4caf50" : undefined,
                },
                "&.Mui-focused fieldset": {
                  borderColor: values.afm_number ? "#4caf50" : undefined,
                },
                backgroundColor: values.afm_number
                  ? "rgba(76, 175, 80, 0.05)"
                  : undefined,
              },
            }}
          />
        </FormGroup>
      </FormRow>

      <FormRow>
        <FormGroup>
          <TextField
            id="amka_number"
            name="amka_number"
            label="AMKA Number"
            placeholder="Enter AMKA number"
            value={values.amka_number}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="filled"
            size="small"
            fullWidth
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: values.amka_number ? "#4caf50" : undefined,
                },
                "&:hover fieldset": {
                  borderColor: values.amka_number ? "#4caf50" : undefined,
                },
                "&.Mui-focused fieldset": {
                  borderColor: values.amka_number ? "#4caf50" : undefined,
                },
                backgroundColor: values.amka_number
                  ? "rgba(76, 175, 80, 0.05)"
                  : undefined,
              },
            }}
          />
        </FormGroup>

        <FormGroup>
          <TextField
            id="driver_license_number"
            name="driver_license_number"
            label="Driver License"
            placeholder="Enter driver license number"
            value={values.driver_license_number}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="filled"
            size="small"
            fullWidth
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: values.driver_license_number
                    ? "#4caf50"
                    : undefined,
                },
                "&:hover fieldset": {
                  borderColor: values.driver_license_number
                    ? "#4caf50"
                    : undefined,
                },
                "&.Mui-focused fieldset": {
                  borderColor: values.driver_license_number
                    ? "#4caf50"
                    : undefined,
                },
                backgroundColor: values.driver_license_number
                  ? "rgba(76, 175, 80, 0.05)"
                  : undefined,
              },
            }}
          />
        </FormGroup>

        <FormGroup>
          <TextField
            id="guide_reg_number"
            name="guide_reg_number"
            label="Guide Reg. Number"
            placeholder="Enter guide registration number"
            value={values.guide_reg_number}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="filled"
            size="small"
            fullWidth
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: values.guide_reg_number ? "#4caf50" : undefined,
                },
                "&:hover fieldset": {
                  borderColor: values.guide_reg_number ? "#4caf50" : undefined,
                },
                "&.Mui-focused fieldset": {
                  borderColor: values.guide_reg_number ? "#4caf50" : undefined,
                },
                backgroundColor: values.guide_reg_number
                  ? "rgba(76, 175, 80, 0.05)"
                  : undefined,
              },
            }}
          />
        </FormGroup>
      </FormRow>
    </Section>
  );
};

export default IdentificationSection;
