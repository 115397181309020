import styled from "styled-components";
import { useState, useEffect } from "react";
import { useAxios } from "../../axiosProvider";
import {
  useGetUserOfficeDutyStatus,
  useUpdateUserOfficeDutyStatus,
} from "../../reactQueryHooks";
import NavButtonWithCustomMenu from "./NavButtonWithCustomMenu";
import MenuItem from "@mui/material/MenuItem";

//-----------------------------------------------------------------------

const ProfileIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-right: auto; //sits on the left of the grid
  * {
    user-select: none;
  }
  z-index: 5000;
`;

const ProfileIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  /* border: ${({ onOfficeDuty }) =>
    onOfficeDuty ? "2px solid #3b943b" : "2px solid #B95151"}; */
  background-color: #e6e6e6;
  width: 28px;
  height: 28px;
  font-size: 12px;
  @media (max-width: 400px) {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
  //padding: 8px;
  border-radius: 100%;
  i {
    color: #4a8ed1;
  }
`;

//-----------------------------------------------------------------------

const ProfileIcon = () => {
  const { user, setIsAuthenticated } = useAxios();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { data: onOfficeDuty, refetch: refetchOfficeDutyStatus } =
    useGetUserOfficeDutyStatus(user?._id);

  const { mutateAsync: updateUserOfficeDutyStatus } =
    useUpdateUserOfficeDutyStatus();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const shouldShowFullName = windowWidth >= 550;

  const name = user.name;
  const splitName = name.split(" ");

  //-----------------------------------------------------------------------

  return (
    <ProfileIconWrapper>
      <ProfileIconContainer>
        <Icon>
          <i className={`fas fa-user${user ? "" : "-slash"}`}></i>
        </Icon>

        <NavButtonWithCustomMenu
          title={
            <span
              style={{
                fontSize: "12px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span>
                {shouldShowFullName
                  ? user.name
                  : splitName.map((name, index) => `${name[0].toUpperCase()}`)}
              </span>
              <i className={`fa-solid fa-angle-down`}></i>
            </span>
          }
        >
          <div
            style={{
              padding: "5px",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
            }}
          >
            <MenuItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                fontSize: "13px",
              }}
              onClick={async () => {
                try {
                  await updateUserOfficeDutyStatus({
                    id: user._id,
                    isOnDuty: !onOfficeDuty.data,
                  });
                  refetchOfficeDutyStatus();
                } catch (error) {
                  console.log(error);
                  alert(error?.response?.data || error.toString());
                }
              }}
            >
              <span>{onOfficeDuty?.data ? `Set OFF duty` : `Set ON Duty`}</span>
              <i
                style={{
                  fontSize: "11px",
                  color: "#747474",
                }}
                className="fa-solid fa-user-clock"
              ></i>
            </MenuItem>
            <MenuItem
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                fontSize: "13px",
              }}
              onClick={async () => {
                try {
                  await updateUserOfficeDutyStatus({
                    id: user._id,
                    isOnDuty: false,
                  });
                  setIsAuthenticated(false);
                } catch (error) {
                  console.log(error);
                  alert(error?.response?.data || error.toString());
                }
              }}
            >
              <span>Log-out</span>
              <i
                style={{
                  fontSize: "12px",
                  color: "#747474",
                }}
                className="fa-solid fa-right-from-bracket"
              ></i>
            </MenuItem>
          </div>
        </NavButtonWithCustomMenu>
      </ProfileIconContainer>
    </ProfileIconWrapper>
  );
};

export default ProfileIcon;
