// src/components/AddUsersModal/sections/PermissionsSection.tsx
import React from "react";
import { Section, SectionTitle, PermissionsGrid } from "../styles";
import ToggleField from "../components/ToggleField";

const PermissionsSection = () => {
  // Group permissions by category for better organization
  const userManagementPermissions = [
    { name: "addNewStaff", label: "Add new users" },
    { name: "addNewStaffGroups", label: "Add new user groups" },
    { name: "addNewStaffRoles", label: "Add new user roles" },
    { name: "handleRequests", label: "Handle user requests" },
    { name: "handleBalance", label: "Handle user balance" },
  ];

  const contentManagementPermissions = [
    { name: "addNewTasks", label: "Add new tasks" },
    { name: "addNewActivities", label: "Add new products" },
    { name: "addNewVehicles", label: "Add new vehicles" },
    { name: "addMeetingPoints", label: "Add meeting points" },
    { name: "publishAnnouncements", label: "Publish announcements" },
    { name: "sendOlpEmail", label: "Send OLP emails" },
  ];

  const bookingManagementPermissions = [
    { name: "sortBookingsInDayPlanner", label: "Sort bookings" },
    { name: "assignSchedule", label: "Assign schedule" },
    { name: "assignVehicle", label: "Assign vehicle" },
    { name: "updateBookings", label: "Update bookings" },
    { name: "editGuidePlan", label: "Edit guide plan" },
  ];

  const tourManagementPermissions = [
    { name: "hideTourGroups", label: "Hide tour groups" },
    { name: "createNewTourGroups", label: "Create new tour groups" },
    { name: "handleAvailabilities", label: "Handle availabilities" },
    { name: "handleServiceRecords", label: "Handle service records" },
  ];

  const documentManagementPermissions = [
    { name: "uploadFiles", label: "Upload files" },
    { name: "addNotes", label: "Add notes" },
    { name: "addMessageDrafts", label: "Handle message drafts" },
  ];

  // Using ToggleField component which now uses MUI Switch internally
  return (
    <Section>
      <SectionTitle>Permissions</SectionTitle>

      <Section>
        <h4 style={{ fontSize: "14px", marginBottom: "8px", color: "#555" }}>
          User Management
        </h4>
        <PermissionsGrid>
          {userManagementPermissions.map((permission) => (
            <ToggleField
              key={permission.name}
              name={permission.name}
              label={permission.label}
            />
          ))}
        </PermissionsGrid>
      </Section>

      <Section>
        <h4 style={{ fontSize: "14px", marginBottom: "8px", color: "#555" }}>
          Content Management
        </h4>
        <PermissionsGrid>
          {contentManagementPermissions.map((permission) => (
            <ToggleField
              key={permission.name}
              name={permission.name}
              label={permission.label}
            />
          ))}
        </PermissionsGrid>
      </Section>

      <Section>
        <h4 style={{ fontSize: "14px", marginBottom: "8px", color: "#555" }}>
          Booking Management
        </h4>
        <PermissionsGrid>
          {bookingManagementPermissions.map((permission) => (
            <ToggleField
              key={permission.name}
              name={permission.name}
              label={permission.label}
            />
          ))}
        </PermissionsGrid>
      </Section>

      <Section>
        <h4 style={{ fontSize: "14px", marginBottom: "8px", color: "#555" }}>
          Tour Management
        </h4>
        <PermissionsGrid>
          {tourManagementPermissions.map((permission) => (
            <ToggleField
              key={permission.name}
              name={permission.name}
              label={permission.label}
            />
          ))}
        </PermissionsGrid>
      </Section>

      <Section>
        <h4 style={{ fontSize: "14px", marginBottom: "8px", color: "#555" }}>
          Document Management
        </h4>
        <PermissionsGrid>
          {documentManagementPermissions.map((permission) => (
            <ToggleField
              key={permission.name}
              name={permission.name}
              label={permission.label}
            />
          ))}
        </PermissionsGrid>
      </Section>
    </Section>
  );
};

export default PermissionsSection;
