import React from "react";
import styled from "styled-components";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import NotesIcon from "@mui/icons-material/Notes";

// Define the User interface with schedule preferences
interface User {
  amountOfWorkDaysPerWeek?: number;
  canExceedAmountOfWorkDaysPerWeek?: boolean;
  preferredWorkDaysPerWeek?: string[];
  preferredDayOffDaysPerWeek?: string[];
  isFlexibleWithWorkDaysPerWeek?: boolean;
  canWorkMultipleShiftDays?: boolean;
  maxMultipleShiftDaysPerWeek?: number;
  canWorkFullDayShifts?: boolean;
  maxFullDayShiftsPerWeek?: number;
  availableBetweenTimes?: string[];
  isFlexibleWithTimes?: boolean;
  scheduleRemarks?: string;
}

interface SchedulePreferencesTooltipProps {
  user: User;
}

// Styled components
const TooltipContainer = styled.div`
  padding: 16px;
  min-width: 280px;
  width: auto;
  max-width: max-content;
  background-color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  box-sizing: border-box;
`;

const Title = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: #000000;
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: #e0e0e0;
  margin: 12px 0;
`;

const Section = styled.div`
  margin-bottom: 12px;
  width: 100%;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 8px;
  color: #000000;
  font-size: 0.875rem;

  svg {
    margin-right: 8px;
    color: #3f51b5;
  }
`;

const PreferenceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  align-items: center;
  gap: 16px;
`;

const Label = styled.span`
  color: #424242;
  font-size: 0.875rem;
  flex-shrink: 0;
`;

const Value = styled.span`
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: right;
`;

const DayChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  gap: 4px;
`;

const DayChip = styled.div`
  background-color: transparent;
  border: 1px solid #3f51b5;
  color: #3f51b5;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  margin: 2px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimeRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8eaf6;
  border: 1px solid #c5cae9;
  border-radius: 4px;
  padding: 4px 8px;
  margin-top: 4px;
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
`;

const PreferredDaysSection = styled.div`
  margin-top: 8px;
  width: 100%;
`;

const RemarksContainer = styled.div`
  margin-top: 8px;
  width: 100%;
`;

const RemarksContent = styled.div`
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 0.875rem;
  color: #424242;
  margin-top: 4px;
  white-space: pre-wrap;
  max-height: 120px;
  overflow-y: auto;
  word-break: break-word;
`;

// Helper function to format day of week
const formatDay = (day: string): string => {
  return day.charAt(0) + day.slice(1).toLowerCase();
};

// Boolean indicator component
const BooleanIndicator: React.FC<{ value?: boolean }> = ({ value }) => {
  if (value === undefined) return <Value>Not set</Value>;

  return value ? (
    <CheckCircleIcon style={{ fontSize: "18px", color: "#2e7d32" }} />
  ) : (
    <CancelIcon style={{ fontSize: "18px", color: "#d32f2f" }} />
  );
};

const SchedulePreferencesTooltip: React.FC<SchedulePreferencesTooltipProps> = ({
  user,
}) => {
  const workDays = user.preferredWorkDaysPerWeek || [];
  const dayOffDays = user.preferredDayOffDaysPerWeek || [];
  const availableTimes = user.availableBetweenTimes || ["Not set", "Not set"];
  const hasRemarks = user.scheduleRemarks && user.scheduleRemarks.trim() !== "";

  return (
    <TooltipContainer>
      <Title>Schedule Preferences</Title>
      <Divider />

      <Section>
        <SectionTitle>
          <EventAvailableIcon style={{ fontSize: "small" }} />
          Work Days
        </SectionTitle>

        <PreferenceRow>
          <Label>Days per week:</Label>
          <Value>
            {user.amountOfWorkDaysPerWeek !== undefined
              ? user.amountOfWorkDaysPerWeek
              : "Not set"}
          </Value>
        </PreferenceRow>

        <PreferenceRow>
          <Label>Can exceed days:</Label>
          <BooleanIndicator value={user.canExceedAmountOfWorkDaysPerWeek} />
        </PreferenceRow>

        <PreferenceRow>
          <Label>Flexible with days:</Label>
          <BooleanIndicator value={user.isFlexibleWithWorkDaysPerWeek} />
        </PreferenceRow>

        {workDays.length > 0 ? (
          <PreferredDaysSection>
            <Label>Preferred days:</Label>
            <DayChipsContainer>
              {workDays.map((day, index) => (
                <DayChip key={index}>{formatDay(day)}</DayChip>
              ))}
            </DayChipsContainer>
          </PreferredDaysSection>
        ) : (
          <PreferenceRow>
            <Label>Preferred days:</Label>
            <Value>Not set</Value>
          </PreferenceRow>
        )}

        {dayOffDays.length > 0 ? (
          <PreferredDaysSection>
            <Label>Preferred Day-Off days:</Label>
            <DayChipsContainer>
              {dayOffDays.map((day, index) => (
                <DayChip key={index}>{formatDay(day)}</DayChip>
              ))}
            </DayChipsContainer>
          </PreferredDaysSection>
        ) : (
          <PreferenceRow>
            <Label>Preferred Day-Off days:</Label>
            <Value>Not set</Value>
          </PreferenceRow>
        )}
      </Section>

      <Divider />

      <Section>
        <SectionTitle>
          <AccessTimeIcon style={{ fontSize: "small" }} />
          Shift Preferences
        </SectionTitle>

        <PreferenceRow>
          <Label>Multiple shifts per day:</Label>
          <BooleanIndicator value={user.canWorkMultipleShiftDays} />
        </PreferenceRow>

        <PreferenceRow>
          <Label>Max multiple shift days:</Label>
          <Value>
            {user.maxMultipleShiftDaysPerWeek !== undefined
              ? user.maxMultipleShiftDaysPerWeek
              : "Not set"}
          </Value>
        </PreferenceRow>

        <PreferenceRow>
          <Label>Can work full day shifts:</Label>
          <BooleanIndicator value={user.canWorkFullDayShifts} />
        </PreferenceRow>

        <PreferenceRow>
          <Label>Max full day shifts:</Label>
          <Value>
            {user.maxFullDayShiftsPerWeek !== undefined
              ? user.maxFullDayShiftsPerWeek
              : "Not set"}
          </Value>
        </PreferenceRow>

        <PreferenceRow>
          <Label>Flexible with times:</Label>
          <BooleanIndicator value={user.isFlexibleWithTimes} />
        </PreferenceRow>

        {availableTimes[0] !== "Not set" && (
          <PreferredDaysSection>
            <Label>Available hours:</Label>
            <TimeRange>
              {availableTimes[0]} - {availableTimes[1]}
            </TimeRange>
          </PreferredDaysSection>
        )}
      </Section>

      {hasRemarks && (
        <>
          <Divider />
          <Section>
            <SectionTitle>
              <NotesIcon style={{ fontSize: "small" }} />
              Schedule Remarks
            </SectionTitle>
            <RemarksContainer>
              <RemarksContent>{user.scheduleRemarks}</RemarksContent>
            </RemarksContainer>
          </Section>
        </>
      )}
    </TooltipContainer>
  );
};

export default SchedulePreferencesTooltip;
