import { createContext, useContext, useState, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"

//------------------------------------------------------------------------

const AxiosContext = createContext()

const createAxiosInstance = (token, isAuthenticated, setIsAuthenticated) => {
  const instance = axios.create({
    //baseURL: "http://localhost:4000/",
    baseURL: process.env.REACT_APP_BASE_URL,
  });
  instance.interceptors.request.use(
    (config) => {
      if (config.url !== "api/login/bookings_manager/loginjwt") {
        if (!isAuthenticated) {
          // Throw a cancellation error with a custom message to stop the request
          throw new axios.Cancel(
            `Request canceled: Ιnvalid credentials ${config.url}`
          );
        }
      }
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        const requestMethod = error.config.method;
        const requestUrl = error.config.url;
        console.log(
          `Unauthorized error occurred for request: ${requestMethod} ${requestUrl}`
        );
        setIsAuthenticated(false);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const WithAxios = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      setToken(null);
      setUser(null);
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  let axiosInstance = useMemo(() => {
    return createAxiosInstance(token, isAuthenticated, setIsAuthenticated);
  }, [isAuthenticated, token]);

  return (
    <AxiosContext.Provider
      value={{
        axiosInstance,
        user,
        setUser,
        setToken,
        isAuthenticated,
        setIsAuthenticated,
      }}
    >
      {children}
    </AxiosContext.Provider>
  );
};

export const useAxios = () => {
  return useContext(AxiosContext)
}


//67d84b248460eae112e9c117