// AssignedRoleBadge.tsx
import React from "react";
import styled from "styled-components";
import PersonIcon from "@mui/icons-material/Person";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useFetchEntities } from "@/reactQueryHooks";

interface AssignedRoleBadgeProps {
  roleId: string;
  userName: string;
}

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border-radius: 20px;
  background: linear-gradient(145deg, #e3fcef, #c5f2dd);
  color: #2e7d5b;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
`;

const RoleTitle = styled.span`
  margin-right: 8px;
  white-space: nowrap;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 2px 6px;
  border-radius: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;

  svg {
    font-size: 12px;
    margin-right: 4px;
    color: #2e7d5b;
  }
`;

const CheckIcon = styled.div`
  margin-right: 6px;
  color: #2e7d5b;

  svg {
    font-size: 16px;
  }
`;

const AssignedRoleBadge = ({ roleId, userName }: AssignedRoleBadgeProps) => {
  const { data: entities } = useFetchEntities() as any;
  const role = entities?.data.roles.find((role: any) => role._id === roleId);
  return (
    <BadgeContainer>
      <CheckIcon>
        <CheckCircleIcon />
      </CheckIcon>
      <RoleTitle>{role.title}</RoleTitle>
      <UserInfo>
        <PersonIcon />
        <span>{userName}</span>
      </UserInfo>
    </BadgeContainer>
  );
};

export default AssignedRoleBadge;
