import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ContentContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  min-width: 300px;
`;

//------------------------------------------------------------------------------------

const TourGroupLoader = () => {
  return (
    <ContentContainer>
      <Skeleton
        height={150}
        baseColor={"transparent"}
        borderRadius={7}
        duration={0.7}
        highlightColor={"white"}
      />
      <Skeleton
        height={150}
        baseColor={"transparent"}
        borderRadius={7}
        duration={0.7}
        highlightColor={"white"}
        containerClassName="skeleton-container"
      />
      <Skeleton
        height={150}
        baseColor={"transparent"}
        borderRadius={7}
        duration={0.7}
        highlightColor={"white"}
      />
      <Skeleton
        height={150}
        baseColor={"transparent"}
        borderRadius={7}
        duration={0.7}
        highlightColor={"white"}
      />
      <Skeleton
        height={150}
        baseColor={"transparent"}
        borderRadius={7}
        duration={0.7}
        highlightColor={"white"}
      />
      <Skeleton
        height={150}
        baseColor={"transparent"}
        borderRadius={7}
        duration={0.7}
        highlightColor={"white"}
      />
    </ContentContainer>
  );
};

export default TourGroupLoader;
