// src/components/AddUsersModal/components/ActionButtons.tsx
import React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

interface ActionButtonsProps {
  isEditing: boolean;
  isSubmitting: boolean;
  isDeleting: boolean;
  onReset: () => void;
  onDelete: () => void;
  onSubmit: () => void;
}

const ActionButtons = ({
  isEditing,
  isSubmitting,
  isDeleting,
  onReset,
  onDelete,
  onSubmit,
}: ActionButtonsProps) => {
  return (
    <div style={{ display: "flex", gap: "12px", width: "100%" }}>
      <Button
        variant="outlined"
        color="inherit"
        onClick={onReset}
        disabled={isSubmitting}
        sx={{ flex: 1 }}
      >
        Clear
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        disabled={isSubmitting}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        sx={{ flex: 3 }}
      >
        {!isSubmitting && isEditing ? "Update User" : "Create User"}
      </Button>

      {isEditing && (
        <Button
          variant="contained"
          color="error"
          onClick={onDelete}
          disabled={isDeleting || isSubmitting}
          startIcon={isDeleting ? <CircularProgress size={20} /> : null}
        >
          <DeleteIcon />
        </Button>
      )}
    </div>
  );
};

export default ActionButtons;
