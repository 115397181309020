import styled from "styled-components";
import { useState, useEffect, useRef, useContext } from "react";
import { Context } from "./SchedulePlanner";
import {
  format,
  isSameDay,
  endOfMonth,
  subDays,
  addDays,
  parseISO,
  eachDayOfInterval,
} from "date-fns";
import BarLoader from "react-spinners/BarLoader";
import {
  useFetchEntities,
  useFetchScheduleByDateRange,
} from "../../../../reactQueryHooks";
import UserBox from "./UserBox";
import CalendarBox from "./CalendarBox";
import StaffSelectDropdown from "./tour_groups_list/StaffSelectDropdown";
import StaffSelect from "./tour_groups_list/StaffSelect";
import React from "react";
import "./style.css";

//----------------------------------------------------------------

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const DateCell = styled.div<{
  isCurrentDay: boolean;
  isSelectedInTourGroups: boolean;
}>`
  background-color: ${(props) =>
    props.isCurrentDay ? "rgb(221 207 173)" : "#fcfcfc"};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: rgb(26 99 172);
  }
  text-decoration: ${(props) =>
    props.isSelectedInTourGroups ? "underline" : "none"};
  color: ${(props) =>
    props.isSelectedInTourGroups ? "rgb(26 99 172)" : "black"};
`;

//------------------------------------------------------------------------

interface User {
  value: string;
  label: string;
}

interface Schedule {
  user: string;
  date: string;
  [key: string]: any;
}

interface EntitiesData {
  data: {
    staff: Array<{
      _id: string;
      name: string;
      [key: string]: any;
    }>;
    [key: string]: any;
  };
}

interface SchedulePlannerGridProps {
  targetDate: string;
  selectedDate?: string;
}

const SchedulePlannerGrid: React.FC<SchedulePlannerGridProps> = ({
  targetDate,
  selectedDate,
}) => {
  const { tourGroupsDate, setTourGroupsDate } = useContext(Context);
  const date_range: string[] = eachDayOfInterval({
    start: subDays(new Date(targetDate), 7),
    end: addDays(new Date(targetDate), 7),
  }).map((date) => format(date, "yyyy-MM-dd"));

  const [user_ids, setUser_ids] = useState<string[]>([]);
  const {
    data: entities = { data: { staff: [] } } as EntitiesData,
    isFetching: isFetchingEntities,
  } = useFetchEntities() as any;

  const staff: User[] = entities.data.staff
    .map((user: any) => {
      return {
        value: user._id,
        label: user.name,
      };
    })
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  useEffect(() => {
    const local_user_ids = localStorage.getItem("user_ids");
    if (local_user_ids) {
      setUser_ids(JSON.parse(local_user_ids));
    }
  }, []);
  const noUsersSelected = user_ids.length === 0;

  const selectedStaff: User[] =
    user_ids
      .map((id) => {
        const user = staff.find((user) => user.value === id);
        return user
          ? {
              value: id,
              label: user.label,
            }
          : null;
      })
      .filter((user): user is User => user !== null)
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const {
    data: scheduleData = [],
    isRefetching,
    isFetching: isFetchingSchedule,
  } = useFetchScheduleByDateRange(date_range, user_ids);

  //------------------------------------------------------------------------

  const listRef = useRef<HTMLDivElement>(null);
  const currentDayRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (currentDayRef.current) {
      currentDayRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    } else {
      if (listRef.current) {
        listRef.current.scrollLeft = 0;
      }
    }
  }, [scheduleData, isRefetching, selectedDate]);

  //------------------------------------------------------------------------

  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [hoveredCol, setHoveredCol] = useState<number | null>(null);

  return (
    <ContentWrapper>
      {!isFetchingEntities &&
        !isFetchingSchedule &&
        !isRefetching &&
        !noUsersSelected && (
          <div
            ref={listRef}
            className="grid-container"
            style={{
              gridTemplateColumns: `125px repeat(${date_range.length}, 150px)`,
              gridTemplateRows: `35px repeat(${selectedStaff.length}, 100px)`,
            }}
          >
            <StaffSelectDropdown
              user_ids={user_ids}
              setUser_ids={setUser_ids}
            />

            {date_range.map((date, colIndex) => {
              const currentDay =
                selectedDate || format(new Date(), "yyyy-MM-dd");
              const isCurrentDay = date === currentDay;
              return (
                <DateCell
                  isSelectedInTourGroups={date === tourGroupsDate}
                  ref={isCurrentDay ? currentDayRef : null}
                  isCurrentDay={isCurrentDay}
                  key={colIndex}
                  className={`cell header-cell sticky-row ${
                    hoveredCol === colIndex ? "highlight" : ""
                  }`}
                  onClick={() => {
                    setTourGroupsDate(date);
                    localStorage.setItem("tourGroupsDate", date);
                  }}
                >
                  {format(parseISO(date), "EEE, dd MMM yy")}
                </DateCell>
              );
            })}

            {selectedStaff.map((user, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <UserBox isHovered={hoveredRow === user.value} user={user} />

                {date_range.map((date, colIndex) => {
                  const hasSchedule = scheduleData?.find(
                    (schedule: Schedule) =>
                      schedule.user === user.value && schedule.date === date
                  );

                  return (
                    <div
                      onMouseEnter={() => {
                        setHoveredRow(user.value);
                        setHoveredCol(colIndex);
                      }}
                      onMouseLeave={() => {
                        setHoveredRow(null);
                        setHoveredCol(null);
                      }}
                      key={colIndex}
                      className="cell"
                    >
                      <CalendarBox
                        key={`${user.value}-${date}`}
                        date={date}
                        user={user}
                        schedule={hasSchedule || null}
                      />
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </div>
        )}
      {(isFetchingEntities || isFetchingSchedule || isRefetching) && (
        <SpinnerContainer>
          <BarLoader color="#606060" loading={true} />
        </SpinnerContainer>
      )}
      {noUsersSelected &&
        !isFetchingEntities &&
        !isFetchingSchedule &&
        !isRefetching && (
          <StaffSelect user_ids={user_ids} setUser_ids={setUser_ids} />
        )}
    </ContentWrapper>
  );
};

export default SchedulePlannerGrid;
