// src/components/AddUsersModal/utils.ts

/**
 * Generates a secure random password that meets the following criteria:
 * - At least 15 characters long
 * - Contains at least 2 special characters
 * - Contains at least 4 lower case letters
 * - Contains at least 4 upper case letters
 * - Contains at least 4 numbers
 * - Does not start with a special character
 */
export const generateRandomPassword = (): string => {
  const length: number = 15;
  const specialCharacters: string = "_!$@";
  const lowerLetters: string = "abcdefghijklmnopqrstuvwxyz";
  const upperLetters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers: string = "0123456789";

  // Ensure at least 2 special characters
  let password: string =
    specialCharacters.charAt(
      Math.floor(Math.random() * specialCharacters.length)
    ) +
    specialCharacters.charAt(
      Math.floor(Math.random() * specialCharacters.length)
    );

  // Ensure at least 4 lower and 4 upper letters, and 4 numbers
  for (let i: number = 0; i < 4; i++) {
    password +=
      lowerLetters.charAt(Math.floor(Math.random() * lowerLetters.length)) +
      upperLetters.charAt(Math.floor(Math.random() * upperLetters.length)) +
      numbers.charAt(Math.floor(Math.random() * numbers.length));
  }

  // Complete the password with random characters
  for (let i: number = password.length; i < length; i++) {
    const allCharacters: string =
      lowerLetters + upperLetters + numbers + specialCharacters;
    password += allCharacters.charAt(
      Math.floor(Math.random() * allCharacters.length)
    );
  }

  // Convert the password to an array to shuffle
  const passwordArray: string[] = password.split("");

  // Shuffle the password characters using Fisher-Yates algorithm
  for (let i: number = passwordArray.length - 1; i > 0; i--) {
    const j: number = Math.floor(Math.random() * (i + 1));
    [passwordArray[i], passwordArray[j]] = [passwordArray[j], passwordArray[i]];
  }

  // Convert the array back to a string
  password = passwordArray.join("");

  // Ensure the password does not start with a special character
  if (specialCharacters.includes(password.charAt(0))) {
    // Swap the first character with a random character from the rest of the password
    const firstCharIndex: number =
      Math.floor(Math.random() * (password.length - 1)) + 1;
    [passwordArray[0], passwordArray[firstCharIndex]] = [
      passwordArray[firstCharIndex],
      passwordArray[0],
    ];
    password = passwordArray.join("");
  }

  return password;
};
