import styled from "styled-components";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { useFormikContext } from "formik";
import { useFetchEntities } from "../../../../reactQueryHooks";

//----------------------------------------------------------

export type TourArrangement = {
  product_id: string;
  option_id: string;
  guide_id: string | null;
  date: string | number;
  start_time_id: string;
  index: number;
};

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 8px 0;
  max-height: 280px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
`;

const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 16px 10px 10px;
  font-size: 12px;
  font-weight: 600;
  color: #606060;
  background-color: #f8f9fa;
  border-radius: 6px;
  margin-bottom: 4px;

  div {
    flex: 1;
  }
`;

const ListItemButtonContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  padding: 0 10px;
  width: 50%;
  height: 100%;
  left: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  button {
    background: none;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    transition: all 0.2s ease;

    &:hover {
      filter: brightness(0.85);
    }
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  position: relative;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 6px;
  font-size: 12px;
  margin-bottom: 2px;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f8f9fa;
    ${ListItemButtonContainer} {
      display: flex;
    }
  }

  div {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const EmptyMessage = styled.div`
  color: #e05252;
  text-align: center;
  font-size: 13px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 6px;
  border: 1px dashed #e0e0e0;
`;

const StatusTag = styled.div`
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  width: auto !important;
  display: inline-block;
`;

const SubmittingTag = styled(StatusTag)`
  background-color: #e6f4ff;
  color: #0074cc;
`;

const SubmittedTag = styled(StatusTag)`
  background-color: #effaef;
  color: #2e7d32;
`;

const ErrorTag = styled(StatusTag)`
  background-color: #feebee;
  color: #d32f2f;
  cursor: help;
`;

//----------------------------------------------------------

const AddTourArrangementActionListItem = ({
  tourArrangement,
  tourArrangementList,
  setTourArrangementList,
  isSubmitting,
  isSubmitted,
  isSubmittedError,
  index,
}: {
  tourArrangement: TourArrangement;
  tourArrangementList: TourArrangement[];
  setTourArrangementList: (tourArrangementList: TourArrangement[]) => void;
  isSubmitting: Boolean;
  isSubmitted: Boolean;
  isSubmittedError: Boolean | string;
  index: number;
}) => {
  const { data: entities } = useFetchEntities() as any;
  const { setFieldValue, validateForm } = useFormikContext();

  const product = entities.data.products.find(
    (product: any) => product._id === tourArrangement.product_id
  );
  const guide = entities.data.staff.find(
    (guide: any) => guide._id === tourArrangement.guide_id
  );

  const startTime = product?.start_times.find(
    (startTime: any) => startTime._id === tourArrangement.start_time_id
  );

  return (
    <ListItem>
      <div>{moment(tourArrangement.date).format("DD/MM/YY ddd")}</div>
      <div>{startTime?.time_slot}</div>
      <div>{product?.title}</div>
      <div
        style={{
          color: guide?.name ? "#333" : "#d32f2f",
        }}
      >
        {guide?.name || "Guide not provided"}
      </div>
      {isSubmitting && <SubmittingTag>Submitting...</SubmittingTag>}
      {isSubmitted && <SubmittedTag>Submitted</SubmittedTag>}
      {isSubmittedError && typeof isSubmittedError === "string" && (
        <Tooltip title={isSubmittedError}>
          <ErrorTag>Error</ErrorTag>
        </Tooltip>
      )}
      {!isSubmitting && (
        <ListItemButtonContainer>
          <button
            style={{
              color: "#90caf9",
            }}
            onClick={() => {
              setFieldValue("product_id", tourArrangement.product_id);
              setFieldValue("option_id", tourArrangement.option_id);
              setFieldValue("date", tourArrangement.date);
              setFieldValue("start_time_id", tourArrangement.start_time_id);
              setFieldValue("guide_id", tourArrangement.guide_id);
              setFieldValue("index", tourArrangement.index);

              //remove the selected tour arrangement from the list
              const filteredTourArrangementList = tourArrangementList.filter(
                (_, i) => i !== index
              );
              setTourArrangementList(filteredTourArrangementList);
              setTimeout(() => {
                validateForm();
              }, 50);
            }}
          >
            Edit
          </button>
          <button
            style={{
              color: "white",
            }}
            onClick={() => {
              //add one like the selected tour arrangement to the list
              const newTourArrangementList = [...tourArrangementList];
              newTourArrangementList.splice(index, 0, tourArrangement);
              setTourArrangementList(newTourArrangementList);
              setTimeout(() => {
                validateForm();
              }, 50);
            }}
          >
            Duplicate
          </button>
          <button
            style={{
              color: "#ef9a9a",
            }}
            onClick={() => {
              //remove the selected tour arrangement from the list
              const filteredTourArrangementList = tourArrangementList.filter(
                (_, i) => i !== index
              );
              setTourArrangementList(filteredTourArrangementList);
              setTimeout(() => {
                validateForm();
              }, 50);
            }}
          >
            Remove
          </button>
        </ListItemButtonContainer>
      )}
    </ListItem>
  );
};

const AddTourArrangementActionList = ({
  tourArrangementList,
  setTourArrangementList,
  submittingItems,
  submittedItems,
  submittedErrorItems,
}: {
  tourArrangementList: TourArrangement[];
  setTourArrangementList: (tourArrangementList: TourArrangement[]) => void;
  submittingItems: Boolean[];
  submittedItems: Boolean[];
  submittedErrorItems: Array<boolean | string>;
}) => {
  return (
    <>
      {tourArrangementList.length > 0 && (
        <ListHeader>
          <div>Date</div>
          <div>Time</div>
          <div>Product</div>
          <div>Guide</div>
          <div>Status</div>
        </ListHeader>
      )}
      <ListContainer>
        {tourArrangementList.length ? (
          tourArrangementList.map((tourArrangement, index) => {
            return (
              <AddTourArrangementActionListItem
                key={index}
                tourArrangement={tourArrangement}
                tourArrangementList={tourArrangementList}
                setTourArrangementList={setTourArrangementList}
                isSubmitting={submittingItems[index]}
                isSubmitted={submittedItems[index]}
                isSubmittedError={submittedErrorItems[index]}
                index={index}
              />
            );
          })
        ) : (
          <EmptyMessage>Add a tour assignment to your list</EmptyMessage>
        )}
      </ListContainer>
    </>
  );
};

export default AddTourArrangementActionList;
