// src/components/AddUsersModal/sections/ContactInfoSection.tsx
import React from "react";
import { useFormikContext } from "formik";
import { UserFormValues } from "../types";
import { Section, SectionTitle, FormRow, FormGroup } from "../styles";
import TextField from "@mui/material/TextField";

const ContactInfoSection = () => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<UserFormValues>();

  return (
    <Section>
      <SectionTitle>Contact Information</SectionTitle>
      <FormRow>
        <FormGroup>
          <TextField
            id="email"
            name="email"
            label="Email Address"
            type="email"
            placeholder="Enter email address"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            variant="filled"
            size="small"
            fullWidth
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor:
                    touched.email && !errors.email ? "#4caf50" : undefined,
                },
                "&:hover fieldset": {
                  borderColor:
                    touched.email && !errors.email ? "#4caf50" : undefined,
                },
                "&.Mui-focused fieldset": {
                  borderColor:
                    touched.email && !errors.email ? "#4caf50" : undefined,
                },
                backgroundColor:
                  touched.email && !errors.email
                    ? "rgba(76, 175, 80, 0.05)"
                    : undefined,
              },
            }}
          />
        </FormGroup>

        <FormGroup>
          <TextField
            id="tel"
            name="tel"
            label="Phone Number"
            placeholder="Enter phone number"
            value={values.tel}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.tel && !!errors.tel}
            helperText={touched.tel && errors.tel}
            variant="filled"
            size="small"
            fullWidth
            autoComplete="off"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor:
                    touched.tel && !errors.tel ? "#4caf50" : undefined,
                },
                "&:hover fieldset": {
                  borderColor:
                    touched.tel && !errors.tel ? "#4caf50" : undefined,
                },
                "&.Mui-focused fieldset": {
                  borderColor:
                    touched.tel && !errors.tel ? "#4caf50" : undefined,
                },
                backgroundColor:
                  touched.tel && !errors.tel
                    ? "rgba(76, 175, 80, 0.05)"
                    : undefined,
              },
            }}
          />
        </FormGroup>
      </FormRow>
    </Section>
  );
};

export default ContactInfoSection;
