import { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { motion } from "framer-motion";
import _ from "lodash";
import moment from "moment";
import {
  useFetchEntities,
  useAddTourGroupFromGuidePlan,
} from "../../../../reactQueryHooks";
import AddTourArrangementActionList from "./AddTourArrangementActionList";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import { TourArrangement } from "./AddTourArrangementActionList";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  backdrop-filter: blur(2px);
`;

const Modal = styled(motion.div)`
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  width: 90%;
  max-width: 540px;
  max-height: 90%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 6px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CloseButton = styled.button`
  background-color: #f8f8f8;
  border: none;
  color: white;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;

  i {
    color: #555;
    font-size: 16px;
    transition: all 0.2s ease;
  }

  &:hover {
    background-color: #f0f0f0;
    i {
      color: #e05252;
    }
  }
`;

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;

  @media (max-width: 400px) {
    flex-direction: column;
    height: auto;
  }

  flex-wrap: wrap;

  .MuiFormControlLabel-label {
    font-size: 13px;
    user-select: none;
  }

  .MuiButtonBase-root {
    padding: 2px !important;
  }

  .MuiSvgIcon-root {
    font-size: 19px;
  }

  .MuiFormControl-root {
    flex: 1;
  }

  .MuiInputBase-root {
    min-height: 42px;
    border-radius: 8px;
    background-color: #f8f9fa;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f0f2f5;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #e0e0e0;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #6eafbb !important;
  }
`;

const ItemContainer = styled.div<{
  isValidated?: boolean;
}>`
  flex: 1;
  min-height: 42px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: ${(props) => (props.isValidated ? "#effaef" : "#f8f9fa")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 5px;
  padding: 0 10px;
  font-size: 13px;
  transition: all 0.2s ease;

  .input {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 10px 5px;
    font-size: 13px;
    &:focus {
      outline: none;
    }
  }

  select {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 10px 5px;
    font-size: 13px;
    &:focus {
      outline: none;
    }
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1;
  height: 46px;
  border: none;
  border-radius: 8px;
  background-color: #b4b4b4;
  color: white;
  padding: 0 16px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(1.1);
  }

  &:focus {
    outline: none;
  }

  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  margin-bottom: 4px;
`;

const SelectorButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;

  &:hover {
    border-color: #d0d0d0;
  }
`;

const SelectorButton = styled.button<{
  selected: boolean;
}>`
  text-align: center;
  background-color: ${({ selected }) => (selected ? "#f0f8ff" : "white")};
  border: none;
  font-weight: ${({ selected }) => (selected ? "600" : "normal")};
  font-size: 13px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ selected }) => (selected ? "#e6f4ff" : "#f8f9fa")};
  }
`;

const SelectorIndicatorBottomLine = styled.div`
  width: 100%;
  height: 3px;
  background-color: #6eafbb;
  position: absolute;
  bottom: 0;
  transition: all 0.3s ease;
`;

const SectionTitle = styled.h3`
  font-size: 15px;
  font-weight: 500;
  color: #444;
  margin: 8px 0 0 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;

  .MuiFormControlLabel-root {
    margin: 0;
  }
`;

//---------------------------------------------------------

const AddTourArrangementModal = ({
  close,
  refetch,
}: {
  close: () => void;
  refetch: () => void;
}) => {
  const [tourArrangementList, setTourArrangementList] = useState<
    TourArrangement[]
  >([]);
  const sortedTourArrangementListByDate = _.sortBy(tourArrangementList, "date");
  const [submittingItems, setSubmittingItems] = useState<any>([Boolean]);
  const [submittedItems, setSubmittedItems] = useState<any>([Boolean]);
  const [range, setRange] = useState([moment(), moment()]);
  const [isRange, setIsRange] = useState(false);
  const [days, setDays] = useState([true, true, true, true, true, true, true]);
  const [submittedErrorItems, setSubmittedErrorItems] = useState<
    Array<boolean | string>
  >([]);

  const {
    data: entities,
  }: {
    data: any;
  } = useFetchEntities();

  useEffect(() => {
    const sortedTourArrangementListByDate = _.sortBy(
      tourArrangementList,
      "date"
    );
    const items = sortedTourArrangementListByDate.map(
      (tourArrangement, index) => false
    );
    setSubmittingItems(items);
    setSubmittedItems(items);
    setSubmittedErrorItems(items);
  }, [tourArrangementList]);

  const products = entities?.data?.products;
  const tour_guides = entities?.data?.staff.filter((staff: any) =>
    staff.roles.some((role: any) => role._id === "636d1329f2535b158a9acd20")
  );

  const { mutateAsync: addTourGroup } = useAddTourGroupFromGuidePlan();

  const resetItems = async () => {
    const items = sortedTourArrangementListByDate.map(
      (tourArrangement, index) => false
    );
    setSubmittingItems(items);
    setSubmittedItems(items);
    setSubmittedErrorItems(items);
  };

  const initiateSubmission = async () => {
    try {
      await resetItems();

      for (let i = 0; i < sortedTourArrangementListByDate.length; i++) {
        try {
          setSubmittingItems((prev: boolean[]) => {
            const newItems = [...prev];
            newItems[i] = true;
            return newItems;
          });

          await addTourGroup(sortedTourArrangementListByDate[i] as any);

          setSubmittedItems((prev: boolean[]) => {
            const newItems = [...prev];
            newItems[i] = true;
            return newItems;
          });
        } catch (err: any) {
          setSubmittedErrorItems((prev: (string | boolean)[]) => {
            const newItems = [...prev];
            newItems[i] = err.response?.data || "Unknown error";
            return newItems;
          });
        } finally {
          setSubmittingItems((prev: boolean[]) => {
            const newItems = [...prev];
            newItems[i] = false;
            return newItems;
          });
        }
      }
    } catch (error: any) {
      console.error("An unexpected error occurred:", error);
      alert(error.toString());
    } finally {
      refetch();
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        product_id: "",
        option_id: "",
        start_time_id: "",
        date: moment().format("YYYY-MM-DD"),
        guide_id: null,
        index: 1,
      }}
      validationSchema={Yup.object({
        product_id: Yup.string().required("Product is required"),
        option_id: Yup.string().required("Option is required"),
        start_time_id: Yup.string().required("Start time id is required"),
        date: Yup.string().required("Date is required"),
        index: Yup.number().required("Group is required"),
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {}}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
        isValid,
      }) => {
        const product = products?.find(
          (product: any) => product._id === values.product_id
        );
        const selectedProductStartTimes = product?.start_times;

        console.log(values);

        return (
          <Wrapper>
            <Modal
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                opacity: { duration: 0.2 },
                scale: { duration: 0.25, ease: "easeOut" },
              }}
            >
              <CloseContainer>
                <CloseButton onClick={close}>
                  <i className="fas fa-times"></i>
                </CloseButton>
              </CloseContainer>
              <ContentContainer>
                <RowsContainer>
                  <SelectorContainer>
                    <SelectorButtonContainer>
                      <SelectorButton
                        onClick={() => {
                          setIsRange(false);
                        }}
                        selected={!isRange}
                      >
                        SINGLE
                      </SelectorButton>
                      {!isRange && <SelectorIndicatorBottomLine />}
                    </SelectorButtonContainer>
                    <SelectorButtonContainer>
                      <SelectorButton
                        onClick={() => {
                          setIsRange(true);
                        }}
                        selected={isRange}
                      >
                        RANGE
                      </SelectorButton>
                      {isRange && <SelectorIndicatorBottomLine />}
                    </SelectorButtonContainer>
                  </SelectorContainer>

                  {isRange && (
                    <>
                      <SectionTitle>Date Range</SectionTitle>
                      <Row>
                        <ItemContainer isValidated={true}>
                          <input
                            className="input"
                            type="date"
                            name="date"
                            value={range[0].format("YYYY-MM-DD")}
                            onChange={(date) => {
                              const isValid = moment(
                                date.target.value
                              ).isSameOrBefore(range[1]);
                              if (isValid) {
                                setRange([moment(date.target.value), range[1]]);
                              } else {
                                alert("Start date must be before end date");
                              }
                            }}
                          />
                        </ItemContainer>
                        <ItemContainer isValidated={true}>
                          <input
                            className="input"
                            type="date"
                            name="date"
                            value={range[1].format("YYYY-MM-DD")}
                            onChange={(date) => {
                              const isValid = moment(
                                date.target.value
                              ).isSameOrAfter(range[0]);
                              if (isValid) {
                                setRange([range[0], moment(date.target.value)]);
                              } else {
                                alert("End date must be after start date");
                              }
                            }}
                          />
                        </ItemContainer>
                      </Row>

                      <SectionTitle>Days of Week</SectionTitle>
                      <CheckboxContainer>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[1]}
                              onChange={(e) => {
                                const newDays = [...days];
                                newDays[1] = e.target.checked;
                                setDays(newDays);
                              }}
                            />
                          }
                          label="Monday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[2]}
                              onChange={(e) => {
                                const newDays = [...days];
                                newDays[2] = e.target.checked;
                                setDays(newDays);
                              }}
                            />
                          }
                          label="Tuesday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[3]}
                              onChange={(e) => {
                                const newDays = [...days];
                                newDays[3] = e.target.checked;
                                setDays(newDays);
                              }}
                            />
                          }
                          label="Wednesday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[4]}
                              onChange={(e) => {
                                const newDays = [...days];
                                newDays[4] = e.target.checked;
                                setDays(newDays);
                              }}
                            />
                          }
                          label="Thursday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[5]}
                              onChange={(e) => {
                                const newDays = [...days];
                                newDays[5] = e.target.checked;
                                setDays(newDays);
                              }}
                            />
                          }
                          label="Friday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[6]}
                              onChange={(e) => {
                                const newDays = [...days];
                                newDays[6] = e.target.checked;
                                setDays(newDays);
                              }}
                            />
                          }
                          label="Saturday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[0]}
                              onChange={(e) => {
                                const newDays = [...days];
                                newDays[0] = e.target.checked;
                                setDays(newDays);
                              }}
                            />
                          }
                          label="Sunday"
                        />
                      </CheckboxContainer>
                    </>
                  )}

                  <SectionTitle>Tour Details</SectionTitle>
                  <Row>
                    <FormControl fullWidth>
                      <InputLabel id="product-select-label">Product</InputLabel>
                      <Select
                        labelId="product-select-label"
                        id="product-select"
                        value={values.product_id}
                        label="Product"
                        onChange={(e) => {
                          setFieldValue("time", "");
                          setFieldValue("start_time_id", "");
                          setFieldValue("option_id", "");
                          setFieldValue("product_id", e.target.value);
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Product</em>
                        </MenuItem>
                        {products?.map((product: any) => (
                          <MenuItem key={product._id} value={product._id}>
                            {product.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {!isRange && (
                      <ItemContainer isValidated={values.date ? true : false}>
                        <input
                          className="input"
                          type="date"
                          name="date"
                          value={values.date}
                          onChange={(date) => {
                            setFieldValue("date", date.target.value);
                          }}
                        />
                      </ItemContainer>
                    )}
                  </Row>

                  {product && (
                    <Row>
                      <FormControl fullWidth>
                        <InputLabel id="option-select-label">Option</InputLabel>
                        <Select
                          labelId="option-select-label"
                          id="option-select"
                          value={values.option_id}
                          label="Option"
                          onChange={(e) => {
                            setFieldValue("time", "");
                            setFieldValue("start_time_id", "");
                            setFieldValue("option_id", e.target.value);
                          }}
                        >
                          <MenuItem value="">
                            <em>Select Option</em>
                          </MenuItem>
                          {product.options.map((option: any) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl fullWidth>
                        <InputLabel id="time-select-label">Time</InputLabel>
                        <Select
                          labelId="time-select-label"
                          id="time-select"
                          value={values.start_time_id}
                          label="Time"
                          onChange={(e) => {
                            const start_time_id = e.target.value;
                            const startTime = product.start_times.find(
                              (startTime: any) =>
                                startTime._id === start_time_id
                            );
                            setFieldValue("start_time_id", start_time_id);
                            setFieldValue("time", startTime.time_slot);
                            setTimeout(() => {
                              validateForm();
                            }, 50);
                          }}
                        >
                          <MenuItem value="">
                            <em>Select Time</em>
                          </MenuItem>
                          {selectedProductStartTimes?.map((start_time: any) => (
                            <MenuItem
                              key={start_time._id}
                              value={start_time._id}
                            >
                              {`${start_time.time_slot} (${start_time.label})`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Row>
                  )}

                  <Row>
                    <FormControl fullWidth>
                      <InputLabel id="guide-select-label">Guide</InputLabel>
                      <Select
                        labelId="guide-select-label"
                        id="guide-select"
                        value={values.guide_id || ""}
                        label="Guide"
                        onChange={(e) => {
                          setFieldValue("guide_id", e.target.value);
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Guide</em>
                        </MenuItem>
                        {tour_guides?.map((guide: any) => (
                          <MenuItem key={guide._id} value={guide._id}>
                            {guide.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="group-select-label">Group</InputLabel>
                      <Select
                        labelId="group-select-label"
                        id="group-select"
                        value={values.index}
                        label="Group"
                        onChange={(e) => {
                          setFieldValue("index", e.target.value);
                        }}
                      >
                        <MenuItem value={1}>Group 1</MenuItem>
                        <MenuItem value={2}>Group 2</MenuItem>
                        <MenuItem value={3}>Group 3</MenuItem>
                        <MenuItem value={4}>Group 4</MenuItem>
                        <MenuItem value={5}>Group 5</MenuItem>
                        <MenuItem value={6}>Group 6</MenuItem>
                      </Select>
                    </FormControl>
                  </Row>
                </RowsContainer>
              </ContentContainer>

              <ButtonWrapper>
                <Button
                  style={{
                    backgroundColor: "#f0f2f5",
                    color: "#555",
                  }}
                  onClick={() => {
                    resetForm();
                    setTimeout(() => {
                      validateForm();
                    }, 50);
                  }}
                >
                  CLEAR
                </Button>
                <Button
                  style={{
                    backgroundColor: "#f8f9fa",
                    border: "1px solid #e0e0e0",
                    color: "#333",
                    flex: 4,
                  }}
                  title="Submit"
                  type="submit"
                  onClick={() => {
                    if (!isValid) {
                      const errorsString = Object.values(errors).join("\n");
                      alert(errorsString);
                    } else {
                      if (!isRange) {
                        const exists = tourArrangementList.find(
                          (tourArrangement) =>
                            tourArrangement.product_id === values.product_id &&
                            tourArrangement.date === values.date
                        );

                        if (exists) {
                          alert("This tour assignment already exists");
                        } else {
                          setTourArrangementList([
                            ...tourArrangementList,
                            {
                              product_id: values.product_id,
                              option_id: values.option_id,
                              guide_id: values.guide_id,
                              date: values.date,
                              start_time_id: values.start_time_id,
                              index: values.index,
                            },
                          ]);
                          resetForm();
                          setTimeout(() => {
                            validateForm();
                          }, 50);
                        }
                      } else {
                        const startDate = moment(range[0]);
                        const endDate = moment(range[1]);

                        const daysToAdd = [];
                        for (
                          let i = 0;
                          i <= endDate.diff(startDate, "days");
                          i++
                        ) {
                          const currentDate = startDate.clone().add(i, "days");
                          const dayOfWeek = currentDate.day();
                          if (days[dayOfWeek]) {
                            const newTourAssignment = {
                              product_id: values.product_id,
                              option_id: values.option_id,
                              guide_id: values.guide_id,
                              date: currentDate.format("YYYY-MM-DD"),
                              start_time_id: values.start_time_id,
                              index: values.index,
                            };
                            // Check if the same object is already in the list
                            const isAlreadyAdded = tourArrangementList.some(
                              (item) =>
                                item.product_id ===
                                  newTourAssignment.product_id &&
                                item.option_id ===
                                  newTourAssignment.option_id &&
                                item.date === newTourAssignment.date &&
                                item.start_time_id ===
                                  newTourAssignment.start_time_id &&
                                item.index === newTourAssignment.index
                            );
                            if (!isAlreadyAdded) {
                              daysToAdd.push(newTourAssignment);
                            }
                          }
                        }
                        setTourArrangementList([
                          ...tourArrangementList,
                          ...daysToAdd,
                        ]);
                        resetForm();
                        setTimeout(() => {
                          validateForm();
                        }, 50);
                      }
                    }
                  }}
                >
                  <PlaylistAddCircleIcon
                    style={{
                      fontSize: "20px",
                    }}
                  />
                  <span>ADD TO LIST</span>
                </Button>
              </ButtonWrapper>

              <AddTourArrangementActionList
                tourArrangementList={sortedTourArrangementListByDate}
                setTourArrangementList={setTourArrangementList}
                submittingItems={submittingItems}
                submittedItems={submittedItems}
                submittedErrorItems={submittedErrorItems}
              />

              <ButtonWrapper>
                <Button
                  style={{
                    backgroundColor: "#f0f2f5",
                    color: "#555",
                    flex: 2,
                  }}
                  onClick={() => {
                    setTourArrangementList([]);
                  }}
                >
                  EMPTY LIST
                </Button>
                <Button
                  style={{
                    backgroundColor: "#609C72",
                    flex: 4,
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    if (tourArrangementList.length === 0) {
                      alert(
                        "Please add at least one tour assignment to the list"
                      );
                    } else {
                      initiateSubmission();
                    }
                  }}
                >
                  SUBMIT
                </Button>
              </ButtonWrapper>
            </Modal>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

export default AddTourArrangementModal;
