import styled from "styled-components";

// Colors
export const colors = {
  primary: "#4361ee",
  secondary: "#3f37c9",
  success: "#4cc9f0",
  danger: "#f72585",
  warning: "#ff9e00",
  info: "#4895ef",
  light: "#f8f9fa",
  dark: "#212529",
  gray: "#adb5bd",
  background: "#ffffff",
  textPrimary: "#2b2d42",
  textSecondary: "#6c757d",
  border: "#dee2e6",
  dayOff: "#4CAF50", // Green for day off
  dayOffHover: "#388E3C", // Darker green for hover
  available: "#66BB6A", // Light green for available
  availableHover: "#43A047", // Medium green for hover
  leave: "#9C27B0", // Purple for leave
  leaveHover: "#7B1FA2", // Darker purple for hover
  comment: "#f8f9fa",
  commentHover: "#e9ecef",
  assigned: "#e0fbfc",
  selected: "#ccf5ac",
};

// Main Container
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 12px;
  padding: 16px;
  background-color: ${colors.background};
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, sans-serif;
  height: 100%;
  max-height: 100vh;
  position: relative;
  overflow: hidden;
  font-size: 12px;
`;

// Shared components used across multiple sub-components
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px;
  min-width: 350px;
  //border-radius: 12px;
  //border: 1px solid ${colors.border};
  transition: all 0.2s ease;
  //background: ${colors.light};
  opacity: ${(props) => (props.disabled ? 0.8 : 1)};
  position: relative;

  /* &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.04);
  } */
`;

export const SectionTitle = styled.div`
  font-weight: 600;
  color: ${colors.primary};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 6px;

  i {
    font-size: 14px;
  }
`;

export const SectionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) =>
    props.isLeave ? "rgba(156, 39, 176, 0.1)" : "rgba(76, 175, 80, 0.1)"};
  pointer-events: none;
  border-radius: 12px;
`;

export const StatusBadge = styled.span`
  margin-left: auto;
  background: ${(props) => (props.isLeave ? colors.leave : colors.dayOff)};
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
`;

export const EmptyMessage = styled.div`
  color: ${(props) => props.color || colors.danger};
  font-weight: 500;
  text-align: center;
  padding: 10px;
  background: ${(props) => props.background || "rgba(247, 37, 133, 0.05)"};
  border-radius: 8px;
  margin: 5px 0;
`;

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 500;
  background: ${colors.info};
  color: white !important;
  margin-left: 5px;
`;

export const Divider = styled.div`
  height: 1px;
  background: ${colors.border};
  margin: 4px 0;
`;

export const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 4px;
`;

export default {
  colors,
  Container,
  SectionContainer,
  SectionTitle,
  SectionOverlay,
  StatusBadge,
  EmptyMessage,
  Badge,
  Divider,
  ScrollableContent,
};
